const segments = [
	{
		"answers": [
			{
				"answer": "Alabama"
			},
			{
				"answer": "Alaska"
			},
			{
				"answer": "Arizona"
			},
			{
				"answer": "Arkansas"
			},
			{
				"answer": "California"
			},
			{
				"answer": "Colorado"
			},
			{
				"answer": "Connecticut"
			},
			{
				"answer": "Delaware"
			},
			{
				"answer": "Florida"
			},
			{
				"answer": "Georgia"
			},
			{
				"answer": "Hawaii"
			},
			{
				"answer": "Idaho"
			},
			{
				"answer": "Illinois"
			},
			{
				"answer": "Indiana"
			},
			{
				"answer": "Iowa"
			},
			{
				"answer": "Kansas"
			},
			{
				"answer": "Kentucky"
			},
			{
				"answer": "Louisiana"
			},
			{
				"answer": "Maine"
			},
			{
				"answer": "Maryland"
			},
			{
				"answer": "Massachusetts"
			},
			{
				"answer": "Michigan"
			},
			{
				"answer": "Minnesota"
			},
			{
				"answer": "Mississippi"
			},
			{
				"answer": "Missouri"
			},
			{
				"answer": "Montana"
			},
			{
				"answer": "Nebraska"
			},
			{
				"answer": "Nevada"
			},
			{
				"answer": "New Hampshire"
			},
			{
				"answer": "New Jersey"
			},
			{
				"answer": "New Mexico"
			},
			{
				"answer": "New York"
			},
			{
				"answer": "North Carolina"
			},
			{
				"answer": "North Dakota"
			},
			{
				"answer": "Ohio"
			},
			{
				"answer": "Oklahoma"
			},
			{
				"answer": "Oregon"
			},
			{
				"answer": "Pennsylvania"
			},
			{
				"answer": "Rhode Island"
			},
			{
				"answer": "South Carolina"
			},
			{
				"answer": "South Dakota"
			},
			{
				"answer": "Tennessee"
			},
			{
				"answer": "Texas"
			},
			{
				"answer": "Utah"
			},
			{
				"answer": "Vermont"
			},
			{
				"answer": "Virginia"
			},
			{
				"answer": "Washington"
			},
			{
				"answer": "West Virginia"
			},
			{
				"answer": "Wisconsin"
			},
			{
				"answer": "Wyoming"
			},
			{
				"answer": "Washington, D.C."
			}
		],
		"excluded": false,
		"multiple": false,
		"question": "Where do you currently live in the United States? ",
		"freeNumeric": false,
		"hasCrosstab": false,
		"questionKey": "Current US State",
		"crosstabKeys": []
	},
	{
		"answers": [
			{
				"answer": "Wired cable connected to home",
				"display": ""
			},
			{
				"answer": "Antenna (digital/over-the-air)",
				"display": "- for example, from a cable company such as Comcast, Cox, or Spectrum (formerly Time Warner, Charter, or Bright House)"
			},
			{
				"answer": "Satellite dish",
				"display": "- for example, DirecTV or DISH"
			},
			{
				"answer": "Telephone company",
				"display": "- for example, AT&T U-verse or FiOS (formerly Verizon or Frontier)"
			},
			{
				"answer": "A television service via the Internet",
				"display": "which allows you to watch live or On Demand TV ― for example, Sling, AT&T NOW/DirecTV NOW, YouTube TV, or Hulu + Live TV"
			},
			{
				"answer": "A streaming service",
				"display": "such as Netflix, Amazon Prime, Disney+, and/or Hulu without Live TV"
			},
			{
				"answer": "Other way not listed No TVs in house at all"
			}
		],
		"excluded": false,
		"multiple": true,
		"question": "There are several ways to receive television programs in the home, such as from a digital antenna, wired cable connected in your home, a satellite dish, or some other way. In which ways does your home receive its television programs? Please think of all the TV sets in your home. ",
		"freeNumeric": false,
		"hasCrosstab": false,
		"questionKey": "TV Services",
		"crosstabKeys": []
	},
	{
		"matrix": true,
		"answers": [
			{
				"answer": "More than 5 hours"
			},
			{
				"answer": "2 to 5 hours"
			},
			{
				"answer": "1-2 hours"
			},
			{
				"answer": "Less than 1 hour"
			},
			{
				"answer": "Receive, but never watch"
			},
			{
				"answer": "Don't receive this network/streaming service"
			}
		],
		"excluded": false,
		"multiple": false,
		"question": "About how many hours in a typical week would you say you watch each of the following networks/streaming services?",
		"matrixKeys": [
			{
				"option": "AMC"
			},
			{
				"option": "FX"
			},
			{
				"option": "HBO"
			},
			{
				"option": "Showtime"
			},
			{
				"option": "Netflix"
			},
			{
				"option": "Amazon Prime"
			},
			{
				"option": "Hulu"
			},
			{
				"option": "HBO Max"
			},
			{
				"option": "Apple TV+"
			},
			{
				"option": "Peacock"
			},
			{
				"option": "Paramount+"
			},
			{
				"option": "Disney+"
			}
		],
		"freeNumeric": false,
		"hasCrosstab": false,
		"questionKey": "Network Viewing",
		"crosstabKeys": []
	},
	{
		"matrix": true,
		"answers": [
			{
				"answer": "5 – Enjoy very much"
			},
			{
				"answer": "4"
			},
			{
				"answer": "3"
			},
			{
				"answer": "2"
			},
			{
				"answer": "1"
			}
		],
		"excluded": false,
		"multiple": false,
		"question": "A rating of TV-MA means that a television program is for mature audiences only and contains one or more of the following: graphic physical and/or emotional violence, explicit sexual activity, and/or strong language. With that information, how much do you enjoy watching the following types of programs?",
		"matrixKeys": [
			{
				"option": "TV-MA Comedies",
				"display": "(such as Veep, Curb Your Enthusiasm, Schitt’s Creek, DAVE, It’s Always Sunny in Philadelphia, or other shows like these)"
			},
			{
				"option": "TV-MA Dramas",
				"display": "(such as Ozark, Succession, Snowfall, Mare of Easttown, The Handmaid’s Tale, Bridgerton or other shows like these)"
			},
			{
				"option": "TV-MA Sci-fi",
				"display": "(such as Westworld, Outlander, LOST, or other shows like these)"
			},
			{
				"option": "TV-MA Documentaries",
				"display": "(such as Biography, Frontline, or other shows like these)"
			},
			{
				"option": "TV-MA Animated shows",
				"display": "(such as such as South Park, Family Guy, Solar Opposites, Archer, Rick and Morty, or other shows like these)"
			}
		],
		"freeNumeric": false,
		"hasCrosstab": false,
		"questionKey": "Genre",
		"crosstabKeys": []
	},
	{
		"matrix": true,
		"answers": [
			{
				"answer": "Have watched/still watch and love it"
			},
			{
				"answer": "Have watched/still watch and like it"
			},
			{
				"answer": "Have watched/still watch and neither like nor dislike"
			},
			{
				"answer": "Have watched/still watch and don’t like"
			},
			{
				"answer": "Never heard of/Never watched"
			}
		],
		"excluded": false,
		"multiple": false,
		"question": "Which of the following TV shows have you watched or still watch? Some of these shows are no longer running new episodes on TV, but you can find them on Video on Demand or through a streaming service like Netflix, Hulu or Amazon Prime.",
		"matrixKeys": [
			{
				"option": "[REPLACE WITH LIST]"
			}
		],
		"freeNumeric": false,
		"hasCrosstab": false,
		"questionKey": "Show Viewing",
		"crosstabKeys": []
	},
	{
		"answers": [
			{
				"answer": "Emotional TV Dramas",
				"display": "(like Grey’s Anatomy, This is Us, Empire)"
			},
			{
				"answer": "TV Sitcoms",
				"display": "(like Brooklyn Nine-Nine, How I Met Your Mother, The Golden Girls)"
			},
			{
				"answer": "Older Kids Content for 9-12 year olds",
				"display": "(like Adventure Time, Teen Titans Go!, Pokemon)"
			},
			{
				"answer": "High Stakes Dramas",
				"display": "(like Killing Eve, Homeland, Ozark)"
			},
			{
				"answer": "Documentaries",
				"display": "(like RBG, The Jinx, Surviving R. Kelly)"
			},
			{
				"answer": "Edgy TV Comedies",
				"display": "(like It’s Always Sunny in Philadelphia, Workaholics, Broad City)"
			},
			{
				"answer": "Comedy Films",
				"display": "(like Booksmart, Girls Trip, Game Night)"
			},
			{
				"answer": "Adult Animation",
				"display": "(like Bob’s Burgers, Rick and Morty, Family Guy)"
			},
			{
				"answer": "Competition Shows",
				"display": "(like The Masked Singer, RuPaul’s Drag Race, Survivor)"
			},
			{
				"answer": "Younger Kids Content for 5-8 year olds",
				"display": "(like Doc McStuffins, Curious George, Sesame Street)"
			},
			{
				"answer": "Food & Home Shows",
				"display": "(like MasterChef, Fixer Upper, Chopped)"
			},
			{
				"answer": "Drama Films",
				"display": "(like A Star Is Born, Moonlight, Little Women)"
			},
			{
				"answer": "Sci-Fi TV",
				"display": "(like Lovecraft Country, The Mandalorian, Westworld)"
			},
			{
				"answer": "Young Adult/Romance",
				"display": "(like Younger, Emily in Paris, The Bold Type)"
			},
			{
				"answer": "Reality TV",
				"display": "(like Keeping Up with the Kardashians, Jersey Shore, 90 Day Fiance)"
			},
			{
				"answer": "Action Films",
				"display": "(like John Wick films, The Fast & the Furious films, Mission: Impossible films)"
			},
			{
				"answer": "TV Crime Dramas",
				"display": "(like Law & Order: Special Victims Unit, Elementary, CSI: Crime Scene Investigation)"
			},
			{
				"answer": "Sci-Fi Films",
				"display": "(Interstellar, Inception, Blade Runner 2049)"
			},
			{
				"answer": "Offbeat Unscripted Shows",
				"display": "(like American Pickers, Mythbusters, My 600-lb Life) 20. Horror"
			},
			{
				"answer": "Horror",
				"display": "(like Us, The Grudge, The Walking Dead)"
			},
			{
				"answer": "Daytime & News",
				"display": "(like ABC 20/20, General Hospital, Good Morning America)"
			},
			{
				"answer": "Thrillers",
				"display": "(like Extraction, Girl with the Dragon Tattoo, Gone Girl)"
			},
			{
				"answer": "Anime",
				"display": "(like Naruto Shippuden, My Hero Academia, One-Punch Man)"
			},
			{
				"answer": "Wrestling & MMA",
				"display": "(like WWE Monday Night Raw, Total Bellas, WWE Smackdown)"
			},
			{
				"answer": "International TV",
				"display": "(like Dark, The Crown, Money Heist)"
			},
			{
				"answer": "Specials",
				"display": "(like The Disney Family Singalong, The Oscars, The Grammy Awards)"
			},
			{
				"answer": "Spanish Language",
				"display": "(like La Reina del Sur, Rica Famosa, Latina, La Patrona)"
			},
			{
				"answer": "LGBTQ+",
				"display": "(like The L Word, Pose, Call Me by Your Name)"
			},
			{
				"answer": "Asian Dramas",
				"display": "(like The Legend of the Blue Sea, Oh My Venus, While You Were Sleeping)"
			},
			{
				"answer": "Others",
				"display": "(please specify)"
			},
			{
				"answer": "None of these"
			}
		],
		"excluded": false,
		"multiple": false,
		"question": "In general, which of the following types of shows and movies are you or anyone in your household interested in? SELECT ALL THAT APPLY",
		"freeNumeric": false,
		"hasCrosstab": false,
		"questionKey": "Segmentation",
		"crosstabKeys": []
	},
	{
		"matrix": true,
		"answers": [
			{
				"answer": "Yes"
			},
			{
				"answer": "No"
			}
		],
		"excluded": false,
		"multiple": false,
		"question": "Do any of the following things make you uncomfortable or offend you when seeing them on television? (Please select one answer per row.)",
		"matrixKeys": [
			{
				"option": "Nudity"
			},
			{
				"option": "Blood/Gore"
			},
			{
				"option": "Profanity or foul language"
			},
			{
				"option": "Violence"
			},
			{
				"option": "Drug use"
			},
			{
				"option": "Gay/Lesbian/Transgender characters"
			},
			{
				"option": "Sexual content"
			}
		],
		"freeNumeric": false,
		"hasCrosstab": false,
		"questionKey": "Offensive Content",
		"crosstabKeys": []
	},
	{
		"answers": [
			{
				"answer": "Less than 1 hour"
			},
			{
				"answer": "1 to less than 5 hours"
			},
			{
				"answer": "5 to less than 10 hours"
			},
			{
				"answer": "10 to less than 20 hours"
			},
			{
				"answer": "20 to less than 30 hours"
			},
			{
				"answer": "30 or more hours"
			}
		],
		"excluded": false,
		"multiple": false,
		"question": "On average, how many hours of television content do you watch per week? Please think about all the ways you can watch, including live TV, recorded on a DVR, streaming, VOD, etc.",
		"freeNumeric": false,
		"hasCrosstab": false,
		"questionKey": "TV Viewing",
		"crosstabKeys": []
	},
	{
		"answers": [
			{
				"answer": "ABC"
			},
			{
				"answer": "Amazon Prime Video"
			},
			{
				"answer": "Apple TV+"
			},
			{
				"answer": "AMC"
			},
			{
				"answer": "CBS"
			},
			{
				"answer": "CW"
			},
			{
				"answer": "Discovery+"
			},
			{
				"answer": "Disney+"
			},
			{
				"answer": "Fox"
			},
			{
				"answer": "Freeform"
			},
			{
				"answer": "FX"
			},
			{
				"answer": "HBO/HBO Max"
			},
			{
				"answer": "Hulu"
			},
			{
				"answer": "NBC"
			},
			{
				"answer": "Netflix"
			},
			{
				"answer": "Paramount+"
			},
			{
				"answer": "Peacock"
			},
			{
				"answer": "Showtime"
			},
			{
				"answer": "None of the these"
			}
		],
		"excluded": false,
		"multiple": true,
		"question": "Which of the following do you watch TV shows on regularly, that is, at least once per week?",
		"freeNumeric": false,
		"hasCrosstab": false,
		"questionKey": "TV Network Usage",
		"crosstabKeys": []
	},
	{
		"answers": [
			{
				"answer": "[REPLACE WITH LIST]"
			}
		],
		"excluded": false,
		"multiple": true,
		"question": "Which of the below movies have you seen and enjoyed? Which of the following TV shows have you watched and enjoyed? By “watched”, we mean you have seen most or all of?",
		"freeNumeric": false,
		"hasCrosstab": false,
		"questionKey": "Movie/TV Qual List",
		"crosstabKeys": []
	},
	{
		"answers": [
			{
				"answer": "Yes"
			},
			{
				"answer": "No"
			}
		],
		"excluded": false,
		"multiple": false,
		"question": "Are you confirming with a child/teen age 6-17?",
		"freeNumeric": false,
		"hasCrosstab": false,
		"questionKey": "Confirming with a child/teen",
		"crosstabKeys": []
	},
	{
		"answers": [
			{
				"answer": "Male"
			},
			{
				"answer": "Female"
			}
		],
		"excluded": false,
		"multiple": false,
		"question": "What is your child/teen’s gender?",
		"freeNumeric": false,
		"hasCrosstab": false,
		"questionKey": "Child/Teen Gender",
		"crosstabKeys": []
	},
	{
		"answers": [
			{
				"answer": "6-9"
			},
			{
				"answer": "10-12"
			},
			{
				"answer": "13-17"
			},
			{
				"answer": "0-5"
			},
			{
				"answer": "18-99"
			}
		],
		"excluded": false,
		"multiple": false,
		"question": "What is your child/teen’s age?",
		"freeNumeric": true,
		"hasCrosstab": false,
		"questionKey": "Child/Teen Age",
		"crosstabKeys": []
	},
	{
		"answers": [
			{
				"answer": "African-American or Black"
			},
			{
				"answer": "Asian or Pacific Islander"
			},
			{
				"answer": "Caucasian or White"
			},
			{
				"answer": "Hispanic or Latino"
			},
			{
				"answer": "Native American"
			},
			{
				"answer": "Other"
			}
		],
		"excluded": false,
		"multiple": false,
		"question": "What is your child/teen’s ethnic background?",
		"freeNumeric": false,
		"hasCrosstab": false,
		"questionKey": "Child/Teen Ethnicity",
		"crosstabKeys": []
	},
	{
		"answers": [
			{
				"answer": "My parent/guardian is here now and can also attend the event"
			},
			{
				"answer": "My parent or guardian cannot attend"
			}
		],
		"excluded": false,
		"multiple": false,
		"question": "Please note that your parent or guardian will need to complete a portion of this confirmation AND attend the event with you. Let us know if they are able to attend. If your parent or guardian is not available to complete this confirmation with you right now, please exit out of the survey and return when they are available.",
		"freeNumeric": false,
		"hasCrosstab": false,
		"questionKey": "Confirming with a parent",
		"crosstabKeys": []
	},
	{
		"answers": [
			{
				"answer": "Male"
			},
			{
				"answer": "Female"
			}
		],
		"excluded": false,
		"multiple": false,
		"question": "For the parent/guardian. Please let us know your gender?",
		"freeNumeric": false,
		"hasCrosstab": false,
		"questionKey": "Parent Gender",
		"crosstabKeys": []
	},
	{
		"answers": [
			{
				"answer": "25-99"
			},
			{
				"answer": "0-24"
			}
		],
		"excluded": false,
		"multiple": false,
		"question": "For the parent/guardian. Please let us know your age?",
		"freeNumeric": true,
		"hasCrosstab": false,
		"questionKey": "Parent Age",
		"crosstabKeys": []
	},
	{
		"answers": [
			{
				"answer": "African-American or Black"
			},
			{
				"answer": "Asian or Pacific Islander"
			},
			{
				"answer": "Caucasian or White"
			},
			{
				"answer": "Hispanic or Latino"
			},
			{
				"answer": "Native American"
			},
			{
				"answer": "Other"
			}
		],
		"excluded": false,
		"multiple": false,
		"question": "For the parent/guardian. Please let us know your ethnicity?",
		"freeNumeric": false,
		"hasCrosstab": false,
		"questionKey": "Parent Ethnicity",
		"crosstabKeys": []
	}
]

export default segments;