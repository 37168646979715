import React, { useCallback, useState, useRef, useMemo } from 'react';
import StandardModal from './standard/StandardModal';

import currenciesJSON from '../utils/iso-4217.json'

const titleFn = ({ id }) => id ? 'Edit Session Rewards Configuration' : 'Configure Session Rewards'

const tangoCatalogKeyMap = new Map([['id', 'utid'], ['name', 'rewardName'], ['currency', 'currencyCode']])

const SessionRewardsModal = React.memo((props) => {
  const { data, getTangoCustomers, getTangoCustomerAccounts, getTangoCatalogs, getTangoEmailTemplates, countryOptions, recruiterCodes } = props

  // const [tangoCustomerId, setTangoCustomerId] = useState(data && data.tangoCustomerId)
  // const [tangoAccounts, setTangoAccounts] = useState()

  const tangoCustomersRef = useRef()
  const tangoAccountsRef = useRef({})
  const tangoCatalogsRef = useRef()
  const tangoEmailTemplatesRef = useRef()

  const disabledFn = useCallback(({ submitted }) => submitted, [])

  const tangoCustomers = useCallback(() => tangoCustomersRef.current || getTangoCustomers().then(res => {
    tangoCustomersRef.current = res
    tangoAccountsRef.current = res.reduce((p, c) => (p[c.customerIdentifier] = c.accounts, p), {})
    // setTangoAccounts(res.reduce((p, c) => (p[c.customerIdentifier] = c.accounts, p), {}))
    return res
  }), [getTangoCustomers])

  // const tangoCustomerAccounts = useCallback(() => tangoAccountsRef.current[tangoCustomerId] || getTangoCustomerAccounts().then(res => (tangoAccountsRef.current[tangoCustomerId] = res, res)), [getTangoCustomerAccounts, tangoCustomerId])

  // const tangoCustomerAccounts = useCallback((params) => ((tangoAccounts) ? ((params && tangoAccounts[params.tangoCustomerId]) || []) : new Promise(() => { })), [tangoAccounts])

  const tangoCustomerAccounts = useCallback((params) => ((params?.tangoCustomerId && tangoAccountsRef.current[params.tangoCustomerId]) || []), [])

  // const onTangoCustomerUpdate = useCallback((newValue, newParams) => {
  //   setTangoCustomerId(newValue && newValue.customerIdentifier)
  // }, [])

  const tangoEmailTemplates = useCallback(() => tangoEmailTemplatesRef.current || getTangoEmailTemplates().then(res => (tangoEmailTemplatesRef.current = res, res)), [getTangoEmailTemplates])

  const tangoCatalogs = useCallback(() => tangoCatalogsRef.current || getTangoCatalogs().then(res => (tangoCatalogsRef.current = res, res)), [getTangoCatalogs])

  const countryDenominations = useMemo(() => [...countryOptions].map(c => currenciesJSON.find(x => x['ISOalpha-2'] === c)), [countryOptions])

  const recruiters = useMemo(() => [...recruiterCodes], [recruiterCodes])

  // console.log('country options', [...countryOptions])

  // const disabledPaymentTypes = useCallback((px, option) => !!(option?.countries && [...countryOptions].reduce((p,c) => (!option.countries.includes(c) && p), true)), [countryOptions])

  // const disabledPaymentTypes = useCallback((px, option) => {
  //   console.log('CHECK OPTION FOR disabled', option, [...countryOptions], option.countries)
  //   return !!(option?.countries && [...countryOptions].reduce((p,c) => (!option.countries.includes(c) && p), true))
  // }, [countryOptions])

  return (
    <StandardModal
      modalKey='sessionRewardsModal'
      open
      {...props}
      title={titleFn}
      buttonLabel='Submit'
      fields={[
        {
          key: 'tangoCustomerId',
          label: 'Tango Customer',
          placeholder: 'Select Tango Customer',
          type: 'select',
          options: tangoCustomers,
          optionLabel: 'displayName',
          optionValueKey: 'customerIdentifier',
          required: true,
          // onUpdate: onTangoCustomerUpdate,
          disabled: disabledFn,
          // defaultValue: 'G22318651'
        },
        {
          key: 'tangoAccountId',
          label: 'Tango Account',
          placeholder: 'Select Tango Account',
          type: 'select',
          // options: tangoCustomerAccounts,
          // options: tangoAccounts[tangoCustomerId] || [],
          options: tangoCustomerAccounts,
          optionLabel: 'displayName',
          optionValueKey: 'accountIdentifier',
          required: true,
          // conditions: [
          //   {
          //     condition: ({ videoPlatform }) => videoPlatform === 'Brightcove',
          //     required: true
          //   }
          // ],
          disabled: disabledFn,
        },
        {
          key: 'tangoEmailTemplateId',
          label: 'Tango Email Template',
          placeholder: 'Select Tango Email Template',
          type: 'select',
          options: tangoEmailTemplates,
          optionLabel: 'name',
          optionValueKey: 'etid',
          required: true,
          // conditions: [
          //   {
          //     condition: ({ videoPlatform }) => videoPlatform === 'Brightcove',
          //     required: true
          //   }
          // ],
          disabled: disabledFn,
        },
        // {
        //   key: 'paymentConfig',
        //   label: 'Payment Options',
        //   type: 'payment-types',
        //   placeholder: 'Select Tango Payment Option',
        //   options: tangoCatalogs,
        //   optionLabel: 'rewardName',
        //   optionValueKey: 'utid',
        //   denominations: countryDenominations,
        //   denominationLabel: 'ISOcountry',
        //   denominationValueKey: 'code',
        //   required: true,
        //   disabledOptions: disabledPaymentTypes
        // },
        {
          key: 'rewardsConfig',
          label: 'Reward Amounts',
          type: 'rewards-builder',
          placeholder: 'Select Tango Payment Option',
          options: tangoCatalogs,
          optionLabel: 'rewardName',
          optionValueKey: 'utid',
          displayComponent: (option) => () => <span>{option.currencyCode}</span>,
          // disabledOptions: disabledPaymentTypes,
          selectedValueKey: 'id',
          selectedValueMap: tangoCatalogKeyMap,
          maxValue: 100,
          denominations: countryDenominations,
          denominationLabel: 'ISOcountry',
          denominationValueKey: 'code',
          denominationDecimalKey: 'decimals',
          required: true,
          groups: [
            {
              label: 'Complete',
              key: 'state',
              value: 'Complete'
            }, 
            {
              label: 'Focus Group',
              prefix: '+',
              key: 'selectedFocusGroup',
              value: true
            }, 
            {
              label: 'Ejected',
              key: 'state',
              value: 'Ejected'
            },
            {
              label: 'Turn Away',
              key: 'state',
              value: 'Turn Away'
            }
          ],
          splitGroup: {
            key: 'recruiterCode',
            label: 'Recruiter Code',
            values: recruiters
          },
          disabled: disabledFn
        },
      ]}
    />

  );
})

export default SessionRewardsModal;
