import React, { useMemo, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, TextField, InputAdornment } from '@mui/material';
const PREFIX = 'DisplayField';

const classes = {
  root: `${PREFIX}-root`,
  subroot: `${PREFIX}-subroot`,
  label: `${PREFIX}-label`,
  textField: `${PREFIX}-textField`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.root}`]: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    width: '100%'
  },
  [`& .${classes.subroot}`]: {
    //border: 'solid 2px green',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  [`& .${classes.label}`]: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  [`& .${classes.textField}`]: {
    flexBasis: '80%',
    padding: '7px 9px',
    border: 'solid 1px #bdbdbd',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between'
    // width: '100%'
  }
});

const DisplayField = React.memo(({ value, label, displayLabel, displayComponent, endComponent }) => {


  // Check if the displayComponent is a function and call it
  const DisplayComponent = useMemo(() => {
    if (typeof displayComponent === 'function') {
      return () => displayComponent(value);
    }
    return displayComponent;
  }, [displayComponent, value]);

  const EndComponent = useMemo(() => {
    if (typeof endComponent === 'function') {
      return () => endComponent(value);
    }
    return endComponent;
  }, [endComponent, value])

  // Check if the displayLabel is a function and call it
  const displayValue = useMemo(() => {
    if (typeof displayLabel === 'function') {
      return displayLabel(value);
    }
    return value;
  }, [displayLabel, value]);

  return (
    (<Root>
      <div className={classes.root}>
        {label && <Typography className={classes.label}>{label}</Typography>}

        {!!displayComponent ? <DisplayComponent /> : 
        !!endComponent ? <Typography className={classes.textField}>{displayValue}<EndComponent/></Typography> :
         <Typography className={classes.textField}>{displayValue}</Typography>}
      </div>
    </Root>)
  );
})

export default DisplayField;
