import React, { useState, useRef, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, TextField, InputAdornment, Button, Popover } from '@mui/material';
import EmojiPicker from 'emoji-picker-react';

const PREFIX = 'EmojiInputField';

const classes = {
  root: `${PREFIX}-root`,
  subroot: `${PREFIX}-subroot`,
  label: `${PREFIX}-label`,
  sublabel: `${PREFIX}-sublabel`,
  button: `${PREFIX}-button`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.root}`]: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
  },
  [`& .${classes.subroot}`]: {
    //border: 'solid 2px green',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  [`& .${classes.label}`]: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  [`& .${classes.sublabel}`]: {
    flexBasis: '100%',
    // minWidth: '15%',
    // textAlign: 'right',
    marginLeft: ({ label }) => label ? 'calc(20% + 16px)' : '10px'
  },
  [`& .${classes.button}`]: {
    fontSize: '115%'
  }
});

const EmojiInputField = React.memo(({ value, label, disabled, placeholder, maxLength, onChange, invalid, large, endComponent, sublabel, onRemove }) => {


  const [anchorEl, setAnchorEl] = useState()

  const toggleOpen = useCallback((event) => {
    setAnchorEl(prev => prev ? null : event.currentTarget)
  }, [])

  const handleChange = useCallback((newValue) => {
    onChange(null, newValue?.emoji)
  }, [onChange]);

  return (
    (<Root>
      <div className={classes.root}>
        {label && <Typography className={classes.label}> {label}</Typography>}
        {/* <TextField className={classes.textField} inputProps={{ maxLength: maxLength || 524288 }} variant="outlined" size="small" error={invalid} disabled={disabled} value={value || ''} placeholder={placeholder || 'Enter text'} onChange={handleChange} multiline={large} rows={large ? 3 : 1 } InputProps={{
          endAdornment: (
            endComponent && <InputAdornment position="end">
              {typeof endComponent === 'function' ? endComponent() : endComponent}
            </InputAdornment>
          ),
        }}/> */}
        <Button disabled={disabled} className={classes.button} variant="outlined" onClick={toggleOpen}>{value || "\u2003"}</Button>
        {/* <Typography className={classes.textField} onClick={toggleOpen}>{value}</Typography> */}
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={toggleOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <EmojiPicker
            skinTonesDisabled={true}
            previewConfig={{
              showPreview: false
            }}
            emojiVersion="12.0"
            onEmojiClick={handleChange}
          />
        </Popover>
      </div>
    </Root>)
  );
})

// TextInputField.defaultProps = {
//   label: 'Text'
// }

export default EmojiInputField;
