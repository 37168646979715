import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import videojs from 'video.js';
import 'videojs-contrib-eme';
import 'videojs-overlay';
import UAParser from 'ua-parser-js'
import { useEventListener } from '../hooks/hooks'
import { IconButton } from '@mui/material';
import VolumeOffIcon from '@mui/icons-material/VolumeOffOutlined';
import VolumeUpIcon from '@mui/icons-material/VolumeUpOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
// eslint-disable-next-line import/no-webpack-loader-syntax
import '!style-loader!css-loader!video.js/dist/video-js.css'

const PREFIX = 'TicktBoxVideoPlayer';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  continue: `${PREFIX}-continue`,
  continueBack: `${PREFIX}-continueBack`,
  margin: `${PREFIX}-margin`,
  playButton: `${PREFIX}-playButton`,
  muteButton: `${PREFIX}-muteButton`,
  customOverlay: `${PREFIX}-customOverlay`,
  resume: `${PREFIX}-resume`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  [`& .${classes.card}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 2rem 2rem'
  },
  [`& .${classes.continue}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 1rem 2rem',
    zIndex: 1002,
    width: '35%',
    minWidth: '160px',
    maxWidth: '260px'
  },
  [`& .${classes.continueBack}`]: {
    zIndex: 1001,
    position: 'absolute',
    height: '100%',
    width: '100%',
    backdropFilter: 'blur(12px)'
  },
  [`& .${classes.margin}`]: {
    margin: '1rem'
  },
  [`& .${classes.playButton}`]: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    color: 'white',
    zIndex: 1004
  },
  [`& .${classes.muteButton}`]: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    color: 'white',
    zIndex: 1004
  },
  [`& .${classes.customOverlay}`]: {
    // color: 'white !important',
    backgroundColor: 'transparent !important',
    height: `100% !important`,
    width: `100% !important`,
    marginTop: 'auto !important',
    marginBottom: 'auto !important',
    padding: '0 !important',
    top: '0 !important',
    left: '0 !important',
    marginLeft: '0 !important',
    // display: 'flex !important',
    display: 'block !important',
    position: 'absolute !important',
    // alignItems: 'center !important',
    // justifyContent: 'center !important',
    opacity: props => (props.wmOpacity && `${props.wmOpacity / 100} !important`) || '0.15 !important',
    // fontSize: 'calc(9px + (140 - 9) * ((100vw - 150px) / (2400 - 150))) !important',
    fontSize: '1em',
    userSelect: 'none !important',
    MozUserSelect: 'none !important',
    WebkitUserSelect: 'none !important',
    msUserSelect: 'none !important',
    // transform: 'translateY(-50%) rotate(-20.5deg) !important',
    // pointerEvents: 'none !important'
  },
  [`& .${classes.resume}`]: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    // backgroundColor: '#151515'
  }
});

// document.pictureInPictureEnabled = false

const parser = new UAParser();

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

const base64DecodeUint8Array = (input) => {
  const raw = window.atob(input);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++)
    array[i] = raw.charCodeAt(i);

  return array;
}

const base64EncodeUint8Array = (input) => {
  const keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
  let output = "";
  let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
  let i = 0;

  while (i < input.length) {
    chr1 = input[i++];
    chr2 = i < input.length ? input[i++] : Number.NaN;
    chr3 = i < input.length ? input[i++] : Number.NaN;

    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;

    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }
    output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
      keyStr.charAt(enc3) + keyStr.charAt(enc4);
  }
  return output;
}

const isBrowser = (u) => {
  for (var i = 0; i < navigator.plugins.length; i++) {
    return (navigator.plugins[i].name != null && navigator.plugins[i].name.substr(0, u.length) === u)
  }
}

const browserCheck = async (uaName) => {
  const isBrave = (navigator.brave && (await navigator.brave.isBrave()) || false)
  const browsers = {
    Brave: isBrave,
    Firefox: !!window.InstallTrigger,
    Safari: !!window.ApplePaySession,
    Opera: window.opr && !!window.opr.addons,
    Chromium: isBrowser("Chromium"),
    Edge: isBrowser("Microsoft Edg"),
    Chrome: !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime),
  }
  const detectedBrowser = Object.keys(browsers).find(k => !!browsers[k])
  return detectedBrowser || uaName
}

const keySystems = {
  // PlayReady: 'com.microsoft.playready',
  Widevine: 'com.widevine.alpha',
  PlayReady: 'com.microsoft.playready',
  // clearkey: ['webkit-org.w3.clearkey', 'org.w3.clearkey'],
  // primetime: ['com.adobe.primetime', 'com.adobe.access'],
  FairPlay: 'com.apple.fps.1_0'
};

// EME Check
const supportedEncryptedMediaExtensions = async (testVideoElement) => {
  if (window.navigator.requestMediaKeySystemAccess) {
    if (typeof window.navigator.requestMediaKeySystemAccess === 'function') {
      console.log('found default EME');
      const isKeySystemSupported = async (keySystem) => {
        const config = [{
          initDataTypes: ['cenc'],
          audioCapabilities: [{
            contentType: "audio/mp4;codecs=\"mp4a.40.2\""
          }],
          videoCapabilities: [{
            contentType: "video/mp4;codecs=\"avc1.42E01E\""
          }]
        }];
        try {
          console.log('CHECKING FOR', keySystems[keySystem])
          await window.navigator.requestMediaKeySystemAccess(keySystems[keySystem], config)
          return keySystem
        } catch (ex) {
          return null
        }
      }
      const supportedSystems = await Promise.all(Object.keys(keySystems).map(isKeySystemSupported))
      return supportedSystems.filter(x => !!x)
    }
  } else if (window.MSMediaKeys) {
    if (typeof window.MSMediaKeys === 'function') {
      console.log('found MS-EME');
      const supportedSystems = Object.keys(keySystems).filter(keysys => window.MSMediaKeys.isTypeSupported(keySystems[keysys]))
      return supportedSystems
    }
  } else if (testVideoElement.webkitGenerateKeyRequest) {
    if (typeof testVideoElement.webkitGenerateKeyRequest === 'function') {
      console.log('found WebKit EME');
      const supportedSystems = Object.keys(keySystems).filter(keysys => testVideoElement.canPlayType('video/mp4', keySystems[keysys]))
      return supportedSystems
    }
  } else {
    console.log('no supported EME implementation found');
    return []
  }
}

const overlay = (content, height, width, seed) => {
  return `<svg style="object-fit: contain" height='100%' width='100%' viewBox='0 0 ${width || 200} ${height || 100}'>
    <svg height='100%' width='100%' viewBox='0 0 200 100'>    
      <filter id='filter' x='0%' y='0%' width='100%' height='100%'>
              <feTurbulence in="SourceGraphic" baseFrequency="10" octaves="10" type="fractalNoise" seed="${seed}" result="noise" />
              <feDisplacementMap in="SourceGraphic" in2="noise" scale="0.7" xChannelSelector="R" yChannelSelector="R" result="map" />
              <feGaussianBlur in="map" stdDeviation="${Math.floor(Math.random()) / 1000 + 0.15}" />
      </filter>
      <text x="50%" y="50%" transform="rotate(-${isNaN(height / width) ? 20.5 : Math.atan(height / width) * 180 / Math.PI}, 100, 50)" filter="url(#filter)" textLength="200" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
    </svg>
  </svg>`
}

const tiledOverlay = (content, height, width, seed) => {
  return `<svg style="object-fit: contain" height='100%' width='100%' viewBox='0 0 ${width} ${height}'>
    <svg height='100%' width='100%'>
      <defs>
        <filter id='filter' x='0%' y='0%' width='100%' height='100%'>
          <feTurbulence in="SourceGraphic" baseFrequency="10" octaves="10" type="fractalNoise" seed="${seed}" result="noise" />
          <feDisplacementMap in="SourceGraphic" in2="noise" scale="0.7" xChannelSelector="R" yChannelSelector="R" result="map" />
          <feGaussianBlur in="map" stdDeviation="${height / 2000} ${width / 2000}" />
        </filter>
        <pattern id="p1" height='25%' width='50%' style="font-size: ${height / 22}px" patternUnits="userSpaceOnUse" patternTransform="rotate(-18.43494882292201)">    
          <text x="25%" y="12.5%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="0" y="25%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="50%" y="25%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="0" y="0" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="50%" y="0" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
        </pattern>
      </defs>
      <rect x="0" y="0" width="100%" height="100%" fill="url(#p1)" filter="url(#filter)"></rect>
    </svg>
  </svg>`
}

const TicktBoxVideoPlayer = React.memo(({
  videoRef,
  style,
  fluid,
  fill,
  fullscreen,
  startVideo,
  wm,
  wmOpacity,
  watermarkStyle,
  videoStreamData,
  alreadyStarted,
  sessionComplete,
  sessionStartTime,
  connectionOffset,
  onTimeUpdate,
  videoDuration,
  videoStopTime,
  onStarted,
  onPlay,
  onBuffer,
  onUnload,
  onMetadataLoaded,
  onVideoLoaded,
  onVisiblityChange,
  onFinished,
  onVideoError
}) => {


  const [muted, setMuted] = useState(true)
  const [playing, setPlaying] = useState(false)
  const [playerLoaded, setPlayerLoaded] = useState(false)

  const visibilityChangeFn = () => { }

  const videoNodeRef = useRef()
  const playerRef = useRef()
  const startedRef = useRef()
  startedRef.current = alreadyStarted
  const drmTypeRef = useRef()
  const allowPause = useRef(false)

  const overlayFn = useMemo(() => {
    return watermarkStyle === 'Tiled' ? tiledOverlay : overlay
  }, [watermarkStyle])

  useEffect(() => {
    playerRef.current = videojs(videoNodeRef.current, {
      controls: false, fluid, fill
    }, () => {
      supportedEncryptedMediaExtensions(videoNodeRef.current).then(eme => {
        if (!eme.length) {
          console.log("No EMEs Found...using browser defaults")
          parser.setUA(navigator.userAgent);
          const broswerInfo = parser.getResult()
          return browserCheck(broswerInfo.browser.name).then((browser) => {
            console.log('detected browser: ', browser)
            return (browser === 'Safari' ? 'FairPlay' : 'Widevine')
          })
        } else {
          console.log('ALL EMES', eme)
          return eme[0]
        }
      }).then((drm) => {
        drmTypeRef.current = drm
        console.log('USING DRM TYPE: ', drmTypeRef.current)
        playerRef.current.eme()

        //CONFIGURE PLAYER EVENTS ONCE
        playerRef.current.tech_.el_.disablePictureInPicture = true
        playerRef.current.tech_.el_.controls = false
        playerRef.current.tech_.el_.blur();
        playerRef.current.tech_.el_.addEventListener('focus', (e) => {
          e.currentTarget.blur()
          console.log('video focus')
        })

        playerRef.current.el_.blur();
        playerRef.current.el_.addEventListener('focus', (e) => {
          e.currentTarget.blur()
          console.log('videojs focus')
        })

        playerRef.current.on('play', (event) => {
          console.log('play event')
          if (onStarted) onStarted()
        })

        playerRef.current.on('pause', () => {
          if (!(playerRef.current.ended() || allowPause.current)) {
            playerRef.current.play()
          } else {
            allowPause.current = false
            playerRef.current.currentTime(0)
          }
          console.log('pause event')
        })

        playerRef.current.on('ended', () => {
          console.log('end event')
          if (onFinished) onFinished()
        })

        playerRef.current.on('timeupdate', (e) => {
          playerRef.current.overlay({
            content: '',
            class: classes.customOverlay,
            overlays: [{
              align: "center",
              content: overlayFn(wm, playerRef.current.tech_.el_.videoHeight, playerRef.current.tech_.el_.videoWidth, Math.floor(Math.random() * 10000000)),
              start: 'play',
              end: 'ended'
            }]
          });
          if (onTimeUpdate) onTimeUpdate(e)
          // if (videoStopTime && e.target.player.currentTime() >= videoStopTime && onFinished) onFinished()
        })

        playerRef.current.on('seeked', () => {
          console.log('seeked event')
          // if (videoTime && !loadedRef.current) {
          //   console.log('FAST forwarding')
          //   console.log(videoTime, unloadTime, videoTime + (Date.now() - unloadTime) / 1000)
          //   playerRef.current.currentTime(videoTime + (Date.now() - unloadTime) / 1000)
          //   loadedRef.current = true
          // }
        })

        playerRef.current.on('error', (e) => {
          console.log('error event', e)
          if (onVideoError) onVideoError()
        })

        playerRef.current.on('seeking', () => {
          console.log('seeking event')
        })

        playerRef.current.on('loadedmetadata', () => {
          console.log('loadedmetadata event')
          playerRef.current.overlay({
            content: '',
            class: classes.customOverlay,
            overlays: [{
              align: "center",
              content: overlayFn(wm, playerRef.current.tech_.el_.videoHeight, playerRef.current.tech_.el_.videoWidth, Math.floor(Math.random() * 10000000)),
              start: 'play',
              end: 'ended'
            }]
          });
          if (onMetadataLoaded) onMetadataLoaded(playerRef.current.tech_.el_.videoHeight / playerRef.current.tech_.el_.videoWidth)
          console.log('aspect ratio: ', playerRef.current.tech_.el_.videoHeight / playerRef.current.tech_.el_.videoWidth)
        })

        playerRef.current.on('loadeddata', () => {
          console.log('loadeddata event')
          if (onVideoLoaded) onVideoLoaded()
          // setVideoLoaded(true)
        })

        playerRef.current.on('playing', (event) => {
          console.log('playing event')
          if (onPlay) onPlay()
        })

        playerRef.current.on('durationchange', (e) => {
          console.log('durationchange event', e)
        })

        playerRef.current.on('waiting', () => {
          console.log('waiting event')
          if (onBuffer) onBuffer()
        })

        setPlayerLoaded(true)
        // videoStreamData(drmType).then(({ url, drmToken }) => {
        //   afterLoaded(url, drmType, drmToken)
        // }).catch((err) => {
        //   console.log('ERROR FETCHING STREAM DATA: ', err)
        // })
      }).catch((er) => {
        console.log('ERROR GETTING EMEs: ', er)
      })
    })
  }, [fill, fluid, onFinished, onStarted, onPlay, onBuffer, onMetadataLoaded, onTimeUpdate, onVideoError, onVideoLoaded, classes.customOverlay, sessionStartTime, wm, overlayFn])

  useEffect(() => {
    if (playerRef.current) {
      if (startVideo) {
        playerRef.current.play()
      } else if (!playerRef.current.paused()) {
        playerRef.current.currentTime(playerRef.current.duration())
        setPlaying(false)
      }
    }
  }, [startVideo])

  useEffect(() => {
    if ((document[hidden] || !document.hasFocus()) && onVisiblityChange) onVisiblityChange()
  }, [onVisiblityChange])

  useEventListener('blur', onVisiblityChange || visibilityChangeFn, true);
  useEventListener('focus', onVisiblityChange || visibilityChangeFn, true);
  useEventListener(visibilityChange, onVisiblityChange || visibilityChangeFn, true);

  useEffect(() => {
    const now = Date.now()
    if (sessionComplete || (sessionStartTime && (now - sessionStartTime) >= ((videoStopTime * 1000) || videoDuration))) {
      if (onFinished) {
        onFinished()
      } else if (playerRef.current) {
        playerRef.current.currentTime(playerRef.current.duration())
      }
    }
  }, [sessionComplete, videoDuration, videoStopTime, sessionStartTime, onFinished])


  // useEffect(() => {
  //   const now = Date.now()
  //   if ((sessionStartTime && onFinished) && (now - sessionStartTime) >= ((videoStopTime * 1000) || videoDuration)) {
  //     onFinished()
  //   }
  // }, [onFinished, sessionStartTime, videoStopTime, videoDuration])

  const afterLoaded = useCallback((videoUrl, drmType, drmToken) => {
    // if (typeof playerRef.current.eme === 'function') playerRef.current.eme()
    const keySystemsParams = {
      [keySystems[drmType]]: drmType !== 'FairPlay' ? {
        url: 'https://license.pallycon.com/ri/licenseManager.do',
        licenseHeaders: {
          'pallycon-customdata-v2': drmToken
        }
      } : {
        getCertificate: (emeOptions, callback) => {
          console.log('GETTING FAIRPLAY CERT')
          videojs.xhr({
            url: 'https://license.pallycon.com/ri/fpsKeyManager.do?siteId=K4O6',
            method: 'GET',
          }, (err, response, responseBody) => {
            if (err) {
              callback(err)
              return
            }
            callback(null, base64DecodeUint8Array(responseBody));
          })
        },
        getContentId: (emeOptions, initData) => {
          console.log('GETTING FAIRPLAY CONTENT ID')
          var uint16array = new Uint16Array(initData.buffer);
          const contentId = String.fromCharCode.apply(null, uint16array);
          return contentId.substring(contentId.indexOf('skd://') + 6);
        },
        getLicense: (emeOptions, contentId, keyMessage, callback) => {
          console.log('GETTING FAIRPLAY LICENSE')
          videojs.xhr({
            url: 'https://license.pallycon.com/ri/licenseManager.do',
            method: 'POST',
            responseType: 'text',
            body: 'spc=' + base64EncodeUint8Array(keyMessage),
            headers: {
              'Content-type': 'application/x-www-form-urlencoded',
              'pallycon-customdata-v2': drmToken
            }
          }, (err, response, responseBody) => {
            if (err) {
              callback(err)
              return
            }
            callback(null, base64DecodeUint8Array(responseBody))
          })
        }
      }
    }
    playerRef.current.src({
      src: videoUrl,
      type: drmType === 'FairPlay' ? 'application/x-mpegurl' : 'application/dash+xml',
      withCredentials: true,
      keySystems: keySystemsParams
    })
    // playerRef.current.load()
    if (videoRef) videoRef.current = playerRef.current //to get time data

    playerRef.current.controls(false);
    if (fullscreen) playerRef.current.enterFullWindow();
    playerRef.current.autoplay(false)
    playerRef.current.muted(true)

    //INITAL BLANK OVERLAY
    playerRef.current.overlay({
      content: '',
      class: classes.customOverlay,
      overlays: [{
        align: "center",
        content: "",
        start: 'play',
        end: 'ended'
      }]
    });

    // GUEST PLAYER
    // if (!alreadyStarted) playerRef.current.play()

    // ADMIN VIEWER
    if (alreadyStarted && !(sessionComplete || ((sessionStartTime && (Date.now() - sessionStartTime) / 1000) >= videoDuration))) {
      playerRef.current.currentTime((Date.now() - sessionStartTime) / 1000)
      playerRef.current.play()
    }

  }, [alreadyStarted, sessionStartTime, sessionComplete, videoDuration, fullscreen, classes.customOverlay, videoRef])

  useEffect(() => {
    console.log('player loaded?', playerLoaded)
    if (playerLoaded && videoStreamData) {
      videoStreamData(drmTypeRef.current).then(({ url, drmToken }) => {
        afterLoaded(url, drmTypeRef.current, drmToken)
      }).catch((err) => {
        console.log('ERROR FETCHING STREAM DATA: ', err)
      })
    }
  }, [afterLoaded, videoStreamData, playerLoaded])

  const toggleMute = useCallback((event) => {
    event.currentTarget.blur()
    if (playerRef.current) {
      playerRef.current.muted(!muted)
      setMuted(prev => !prev)
    }
  }, [muted, setMuted])

  const togglePlay = useCallback((event) => {
    event.currentTarget.blur()
    if (playerRef.current) {
      if (playing) {
        allowPause.current = true
        // playerRef.current.currentTime(playerRef.current.duration())
        playerRef.current.pause()
      } else {
        playerRef.current.play()
      }
      // playing ? playerRef.current.currentTime(playerRef.current.duration()) : playerRef.current.play()
      setPlaying(prev => !prev)
    }
  }, [playing])

  const toggleFullScreen = useCallback((event) => {
    event.currentTarget.blur()
    if (playerRef.current) {
      playerRef.current.requestFullscreen();
    }
  }, [])

  return (
    (<Root>
      {/* {resuming && <div className={classes.resume}>
        <div className={classes.continueBack}></div>
        <Card className={classes.continue} elevation={12}>
          {videoLoaded ? <>
            Please click below to {videoTime && unloadTime ? 'resume' : 'join'} session
            <Button className={classes.margin} disabled={!videoLoaded} color='primary' variant='contained' onClick={handleResume}>{videoTime && unloadTime ? 'Resume' : 'Join'}</Button>
          </> : <>
              Please wait...
            <CircularProgress className={classes.margin} />
            </>}
        </Card>
      </div>} */}
      <div style={{ position: 'relative' }}>
        <div data-vjs-player style={style}>
          <video ref={videoNodeRef} className='video-js'></video>
          {(!startVideo && !alreadyStarted) ?
            <IconButton className={classes.playButton} color="inherit" onClick={togglePlay}>
              {playing ? <StopIcon /> : <PlayArrowIcon />}
            </IconButton> : <IconButton className={classes.playButton} color="inherit" onClick={toggleFullScreen}>
              {/* {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />} */}
              <FullscreenIcon />
            </IconButton>
          }
          <IconButton className={classes.muteButton} color="inherit" onClick={toggleMute}>
            {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
          </IconButton>
        </div>
      </div>
      {/* <ReactPlayerLoader attrs={{ style }} options={{ fluid, fill }} accountId='6121265898001' playerId={playerId} videoId={videoId} onSuccess={afterLoaded} /> */}
    </Root>)
  );
})

export default TicktBoxVideoPlayer;
