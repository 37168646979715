import { useEffect, useCallback } from 'react'
import { getEmailTemplatesPromise } from '../services/emailTemplateService'
import { useSetRecoilState } from 'recoil';
import { emailTemplates } from './atoms'
import { useOktaAuth } from '@okta/okta-react'
import * as workerTimers from 'worker-timers';

export default function EmailTemplatesSubscription({ refresh, onLoaded }) {
    const setEmailTemplates = useSetRecoilState(emailTemplates)
    const { oktaAuth } = useOktaAuth();
    const loaded = useCallback(onLoaded ? onLoaded : () => { }, [onLoaded])

    useEffect(() => {

        let syncInterval;
        const sync = () => getEmailTemplatesPromise(oktaAuth.getAccessToken()).then((data) => (
            data ? (data.map(x => (x.content = { ...(x.content || {}), template: true }, x))) : data
        )).then(setEmailTemplates).then(loaded).catch((error) => {
            if (error.message === 'Unauthorized') {
                oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
            } else {
                throw error
            }
        })

        sync()

        // sync().then(() => {
        //     syncInterval = workerTimers.setInterval(sync, 300000)
        // })

        // const syncInterval = workerTimers.setInterval(() => {
        //     getSessionsPromise(oktaAuth.getAccessToken()).then(setEmailTemplates)
        // }, 5000)

        return function cleanup() {
            if (syncInterval) workerTimers.clearInterval(syncInterval)
        };
    }, [oktaAuth, refresh, loaded, setEmailTemplates]);

    return null;
}