import React, { useCallback, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PublishIcon from '@mui/icons-material/Publish';
import StandardField from './StandardField';
import Files from 'react-butterfiles'
import * as XLSX from 'xlsx';

const PREFIX = 'MultiTimeMarkerField';

const classes = {
  root: `${PREFIX}-root`,
  label: `${PREFIX}-label`,
  previewContainer: `${PREFIX}-previewContainer`,
  previewTable: `${PREFIX}-previewTable`,
  csvInput: `${PREFIX}-csvInput`,
  actionBtnsContainer: `${PREFIX}-actionBtnsContainer`,
  uploadVideoMakersBtn: `${PREFIX}-uploadVideoMakersBtn`,
  btnIcon: `${PREFIX}-btnIcon`
};

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  [`& .${classes.label}`]: {
    margin: '4px'
  },
  [`& .${classes.previewContainer}`]: {
    flexBasis: '80%'
  },
  [`& .${classes.previewTable}`]: {
    flexBasis: '80%'
  },
  [`& .${classes.csvInput}`]: {
    padding: '10px',
    display: 'block',
    margin: '15px auto',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  [`& .${classes.actionBtnsContainer}`]: {
    margin: '10px'
  },
  [`& .${classes.uploadVideoMakersBtn}`]: {
    marginLeft: '10px',
  },
  [`& .${classes.btnIcon}`]: {
    padding: '0 15px'
  }
});

const cloneDeep = require('lodash/cloneDeep');

const timeSort = (a, b) => {
  const timeA = 360 * a.markerHour + 60 * a.markerMinute + a.markerSecond
  const timeB = 360 * b.markerHour + 60 * b.markerMinute + b.markerSecond
  if (isNaN(timeA) || isNaN(timeB)) {
    return isNaN(timeA) && isNaN(timeB) ? 0 : isNaN(timeB) ? -1 : 1
  } else {
    return timeA === timeB ? 0 : timeA < timeB ? -1 : 1
  }
}

const MultiTimeMarkerField = React.memo(({
  value,
  label,
  disabled,
  placeholder,
  fieldKey,
  onChange,
  invalidate
}) => {


  const valueRef = useRef();
  valueRef.current = value || [];

  const handleAddMarker = useCallback(() => {
    const newMarker = {
      markerName: null,
      markerHour: null,
      markerMinute: null,
      markerSecond: null
    }
    const sorted = valueRef.current.concat().sort(timeSort)
    const newValue = [...sorted, newMarker]
    onChange(null, newValue)
  }, [onChange])

  const handleMarkerChange = useCallback((newValue, index) => {
    const newMarkers = cloneDeep(valueRef.current)
    newMarkers[index] = newValue
    onChange(null, newMarkers)
  }, [onChange])

  const handleRemoveMarker = useCallback((index) => {
    const newMarkers = cloneDeep(valueRef.current)
    newMarkers.splice(index, 1)
    newMarkers.sort(timeSort)
    invalidate && invalidate(`${fieldKey}-marker${index}`, false)
    onChange(null, newMarkers)
  }, [onChange, invalidate, fieldKey]);

  const handleAddCsvRowMarkers = useCallback((markers) => {
    const markersToUpload = [];
    markers.forEach(row => {
      const [markerHour, markerMinute, markerSecond] = row?.['Time']?.split(':');
      const uploadedMaker = {
        markerName: row?.['Scene/Cue'],
        markerHour: parseInt(markerHour, 10),
        markerMinute: parseInt(markerMinute, 10),
        markerSecond: parseInt(markerSecond, 10)
      };
      markersToUpload.push(uploadedMaker);
    });

    const sorted = valueRef.current.concat().sort(timeSort);
    const newValue = [...sorted, ...markersToUpload];
    onChange(null, newValue);
  }, [onChange])

  const handleProcessingData = useCallback((dataString) => {

    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"')
              d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"')
              d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
        // remove the blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj);
        }
      }
    }
    handleAddCsvRowMarkers(list);
  }, [handleAddCsvRowMarkers]);

  const handleFileUpload = useCallback(([file]) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      handleProcessingData(data);
    };
    reader.readAsBinaryString(file.src.file);
  }, [handleProcessingData]);

  return (
    <Root>
      {label && <Typography className={classes.label}>{label}</Typography>}
      {value && value.map((marker, i) =>
        <StandardField
          type="marker"
          fieldKey={`${fieldKey}-marker${i}`}
          key={`${fieldKey}-marker${i}`}
          value={marker}
          required={true}
          disabled={disabled}
          placeholder={placeholder}
          onRemove={(e) => handleRemoveMarker(i)}
          onChange={(e, v) => handleMarkerChange(v, i)}
          invalidate={invalidate}
        />)}
      <Files
        multiple={false} maxSize="2mb" multipleMaxSize="10mb" accept={['.csv', '.xls', '.xlsx', 'text/csv', 'text/x-csv', 'application/csv', 'application/x-csv', 'application/vnd.ms-excel', '']}
        onSuccess={handleFileUpload}
      // onError={onError}
      >
        {({ browseFiles }) => (
          <div className={classes.actionBtnsContainer}>
            <Button
              variant="outlined"
              disabled={disabled}
              onClick={handleAddMarker}>
              <AddIcon /> Add Video Marker
            </Button>
            <Button
              className={classes.uploadVideoMakersBtn}
              variant="outlined"
              onClick={browseFiles}>
              <PublishIcon /> Import Video Markers
            </Button>
          </div>
        )}
      </Files>
    </Root>
  );
});

export default MultiTimeMarkerField;
