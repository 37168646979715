import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { currentUser, projectState, sessionState } from '../recoil/atoms'
// import { pastSessions, currentSessions, upcomingSessions } from '../recoil/selectors'
import { useSnackbar } from 'notistack';
import { useLocalStorage } from '../hooks/hooks'
import { IconButton } from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CloseIcon from '@mui/icons-material/Close';
import VideoCallIcon from '@mui/icons-material/VideoCall';
// import MaterialTable from "material-table"
import MaterialTable from "@material-table/core"
import AddFab from '../components/AddFab';
import ProjectModal from '../components/ProjectModal';
import VideoImportModal from '../components/VideoImportModal';
import SessionsSubscription from '../recoil/SessionsSubscription';
import ProjectsSubscription from '../recoil/ProjectsSubscription';
import { withSuspense } from '../hooks/suspense'
import { deleteProjectPromise, insertProjectPromise, updateProjectPromise } from '../services/projectsService'
import { getSurveysPromise } from '../services/surveyService';
import { getPFMScreeningsPromise } from '../services/recruitService';
import { getVideosPromise, uploadBrightcoveVideoPromise, uploadTicktboxVideoPromise, getYoutubeVideoPromise } from '../services/videoService'
import { useOktaAuth } from '@okta/okta-react';
import config from '../config.json'

const PREFIX = 'ProjectsPage';

const classes = {
  projects: `${PREFIX}-projects`,
  page: `${PREFIX}-page`,
  footer: `${PREFIX}-footer`,
  names: `${PREFIX}-names`
};

const Root = styled('div')({
  padding: '1em',
  [`& .${classes.names}`]: {
    display: 'flex',
  flexDirection: 'column',
  width: '100%',
  },
  [`& .${classes.projects}`]: {
    marginBottom: '1rem',
    width: '100%'
  },
  [`& .${classes.footer}`]: {
    // position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 'calc(2rem + 56px)',
  },
});

const isEqual = require('lodash/isEqual');
const cloneDeep = require('lodash/cloneDeep');

const difference = (obj1, obj2, excluded = []) => {
  const keys = [...new Set([...Object.keys(obj1), ...Object.keys(obj2)])]
  const diff = keys.filter(k => !excluded.includes(k)).reduce((result, key) => {
    if (obj1.hasOwnProperty(key) && !obj2.hasOwnProperty(key)) {
      result[key] = cloneDeep(obj1[key])
    } else if (!obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      result[key] = cloneDeep(obj2[key])
    } else if (!isEqual(obj1[key], obj2[key])) {
      result[key] = cloneDeep(obj2[key])
    }
    return result;
  }, {});

  return diff;
}

const DismissBtn = (id) => {
  const { closeSnackbar } = useSnackbar();
  return <>
    <IconButton
      aria-label="close"
      color="inherit"
      // className={classes.close} //NO CLASS DEFINED
      onClick={() => closeSnackbar(id)}
    >
      <CloseIcon />
    </IconButton>
  </>
}

const ProjectsPage = React.memo((props) => {
  const projects = useRecoilValue(projectState);
  const sessions = useRecoilValue(sessionState);
  const user = useRecoilValue(currentUser);
  const [tableProjects, setTableProjects] = useState(undefined)

  const [projectSelected, setProjectSelected] = useLocalStorage('projectSelected', null)//useState();
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState({});
  const [projectDialogOpen, setProjectDialogOpen] = useLocalStorage('projectDialogState', false) //useState(false);
  // const [error, setError] = useState();
  const { enqueueSnackbar } = useSnackbar()
  // const [errorOpen, setErrorOpen] = useState(false)
  const [videoImportOpen, setVideoImportOpen] = useLocalStorage('videoImportDialogState', false) //useState(false);
  const [importStarted, setImportStarted] = useState(false)
  const [importComplete, setImportComplete] = useState(false)
  const [importPercent, setImportPercent] = useState()
  const [importProgress, setImportProgress] = useState()
  // const [tableFilters, setTableFilters] = useLocalStorage(`projectstablefilter`, {})
  const [tableSort, setTableSort] = useLocalStorage(`projectstablesort`, {})
  const [tableOrder, setTableOrder] = useLocalStorage(`projectstableorder`, { startTime: 'desc' })
  const { oktaAuth } = useOktaAuth();

  const tableRef = useRef()

  useEffect(() => {
    setTableProjects(projects ? projects.map(o => {
      const p = { ...o }
      try {
        p.sessions = JSON.parse(o.sessions || null) || [] //NEEDED FOR DATA API
      } catch (e) {
        console.log(e)
      }
      return p
    }).sort((a, b) => Math.max(...b.sessions.map(x => new Date(x.startTime))) - Math.max(...a.sessions.map(x => new Date(x.startTime)))) : undefined)
  }, [projects])

  const handleCloseProjectDialog = useCallback(() => {
    // clearLocalStorage();
    setProjectDialogOpen(false);
    setProjectSelected(null)
  }, [setProjectSelected, setProjectDialogOpen]);

  const submitProject = useCallback((event, submitData) => {
    setLoading(true)
    if (submitData.audienceSegments) submitData.audienceSegments = submitData.audienceSegments.map(x => {
      delete x.collapsed
      x.questionKey = x.questionKey.replace(/\s*$/, '')
      x.answers.forEach(y => {
        if (y.display) y.display = y.display.replace(/\s*$/, '')
        y.answer = y.answer.replace(/\s*$/, '')
      })
      if (x.matrix && x.matrixKeys) {
        x.matrixKeys.forEach(y => {
          if (y.display) y.display = y.display.replace(/\s*$/, '')
          y.option = y.option.replace(/\s*$/, '')
        })
      } else {
        x.matrixKeys = undefined
      }
      return x
    })
    if (submitData.virtualSegments) submitData.virtualSegments = submitData.virtualSegments.map(x => {
      delete x.collapsed
      return x
    })

    const data = (projectSelected && projectSelected.id) ? difference(projectSelected, submitData) : cloneDeep(submitData)
    console.log('SUBMIT CHANGES:', data)
    if (Object.keys(data).length) {
      const vidId = data.hasOwnProperty('videoId') ? data.videoId : projectSelected?.videoId
      const multiSection = data.hasOwnProperty('multiSection') ? data.multiSection : projectSelected?.multiSection
      const vidLength = data.hasOwnProperty('videoLength') ? data.videoLength : projectSelected?.videoLength
      const contentLength = data.hasOwnProperty('contentLength') ? data.contentLength : projectSelected?.contentLength
      const autStop = data.hasOwnProperty('autoStop') ? data.autoStop : projectSelected?.autoStop
      const vidPlatform = data.hasOwnProperty('videoPlatform') ? data.videoPlatform : projectSelected?.videoPlatform
      const isDialProject = data.hasOwnProperty('isDialProject') ? data.isDialProject : projectSelected?.isDialProject
      const dialMode = data.hasOwnProperty('dialMode') ? data.dialMode : projectSelected?.dialMode
      const externalRecruit = data.hasOwnProperty('externalRecruit') ? data.externalRecruit : projectSelected?.externalRecruit
      const recruitPlatform = data.hasOwnProperty('recruitPlatform') ? data.recruitPlatform : projectSelected?.recruitPlatform
      const externalRecruitId = data.hasOwnProperty('externalRecruitId') ? data.externalRecruitId : projectSelected?.externalRecruitId
      const externalRecruitIds = data.hasOwnProperty('externalRecruitIds') ? data.externalRecruitIds : projectSelected?.externalRecruitIds
      const chartConfig = data.hasOwnProperty('chartConfig') ? data.chartConfig : projectSelected?.chartConfig
      const segments = JSON.stringify(data.hasOwnProperty('audienceSegments') ? data.audienceSegments : projectSelected?.audienceSegments)
      const type = data.hasOwnProperty('type') ? data.type : projectSelected?.type
      const hasPasscode = data.hasOwnProperty('requirePasscode') ? data.requirePasscode : projectSelected?.requirePasscode
      const hasMagicLink = data.hasOwnProperty('clientMagicLink') ? data.clientMagicLink : projectSelected?.clientMagicLink


      if (projectSelected) {
        data.id = projectSelected.id
      }
      for (let i in data) {
        if (typeof data[i] !== 'boolean' && !data[i]) delete data[i]
      }
      if (data.tableData) delete data.tableData
      if (data.audienceSegments) {
        if (data.id) data.segmentsChanged = true
        const dialChartSegments = projectSelected && cloneDeep(projectSelected.dialChartSegments) || []

        data.audienceSegments.filter(x => !x.freeNumeric).forEach((segment) => {
          if (!isEqual(segment.answers.map(x => x.answer), dialChartSegments.find(x => x.questionKey === segment.questionKey)?.answers.map(x => x.answer))) {
            const index = dialChartSegments.findIndex(x => x.questionKey === segment.questionKey)
            if (index !== -1 || (segment.questionKey === 'Gender' || segment.answers.reduce((p, c) => p || ['Male', 'Female'].includes(c.answer), false))) {
              const newChartAns = segment.answers.map(ans => {
                const newAns = cloneDeep(ans)
                delete newAns.goal
                const shouldShow = dialChartSegments[index]?.answers.find(x => x.answer === newAns.answer)?.showOnChart
                newAns.showOnChart = typeof shouldShow === 'boolean' ? shouldShow : (['Male', 'Female'].includes(newAns.answer) || false)
                return newAns
              })
              if (index === -1) {
                const newSeg = cloneDeep(segment)
                delete newSeg.multiple
                delete newSeg.question
                delete newSeg.collapsed
                newSeg.hasCrosstab = false
                newSeg.crosstabKeys = []
                newSeg.answers = newChartAns
                dialChartSegments.unshift(newSeg)
              } else {
                dialChartSegments[index].answers = newChartAns
              }
            }
          }
        })
        // FOR MYSQL DATA API
        data.dialChartSegments = JSON.stringify(dialChartSegments)
        data.audienceSegments = JSON.stringify(data.audienceSegments)
        if (externalRecruit && (externalRecruitId || externalRecruitIds)) {
          data.externalRecruitId = externalRecruitId
          data.externalRecruitIds = externalRecruitIds
        }
      }
      if (data.virtualSegments) {
        data.virtualSegments.forEach((segment) => {
          segment.answers.forEach(answer => delete answer.goal)
        })
        data.virtualSegments = JSON.stringify(data.virtualSegments)
      }
      if (!externalRecruit) {
        data.recruitPlatform = null
        data.externalRecruitId = null
      } else if (data.externalRecruitId || data.externalRecruitIds) {
        data.externalRecruit = true
        data.audienceSegments = data.audienceSegments || segments
        data.recruitPlatform = data.recruitPlatform || recruitPlatform
      }
      if (data.sessions) {
        const oldSessions = projectSelected?.sessions || []
        const deletes = oldSessions.filter(x => !data.sessions.find(y => x.id === y.id)).map(x => x.id)
        const updates = data.sessions.filter(x => !oldSessions.find(y => x.id === y.id)).map(x => x.id)
        data.sessionChanges = { deletes, updates }
      }
      delete data.sessions
      if (type === 'Focus Group') {
        data.videoId = null
        data.videoPlatform = null
        data.videoLength = null
        data.contentLength = null
        data.autoStopTime = null
        data.multiSection = false
        data.internalVideoName = null
        data.videoMarkers = null
      } else {
        if (!vidLength || !contentLength?.length) {
          data.videoLength = null
          data.contentLength = null
        }
        data.videoId = vidId
        data.videoPlatform = vidPlatform
        if (vidPlatform === 'Brightcove' && vidId === 'NEW') data.multiSection = multiSection
        if (data.contentLength) data.contentLength = JSON.stringify(data.contentLength)
        if (!autStop) data.autoStopTime = null
        if (data.internalVideoName) data.internalVideoName = JSON.stringify(data.internalVideoName)
        if (data.videoMarkers) data.videoMarkers = JSON.stringify(data.videoMarkers)
      }

      if (!isDialProject || type === 'Focus Group') {
        data.scoreBase = null
        data.symbolOptions = null
      } else {
        if (dialMode === 'symbol') {
          if (data.symbolOptions) {
            chartConfig.defaultEmoji = data.symbolOptions[0].emoji;
            data.chartConfig = chartConfig;
            data.symbolOptions = JSON.stringify(data.symbolOptions)
          }
          if (data.chartConfig) data.chartConfig = JSON.stringify(data.chartConfig);
        } else {
          data.symbolOptions = null
          data.chartConfig = null
        }
      }

      if (!hasMagicLink) {
        data.magicLinkUrl = null
        data.requirePasscode = false
        data.magicLinkPasscode = null
        data.requireEmailValidation = false
        data.requireApproval = false
      } else if (!hasPasscode) {
        data.magicLinkPasscode = null
      }

      let submitPromise
      if (!data.id) {
        data.createdBy = user.email
        data.lastUpdatedBy = user.email
        submitPromise = insertProjectPromise
      } else {
        data.lastUpdatedBy = user.email
        submitPromise = updateProjectPromise
      }
      const token = oktaAuth.getAccessToken()
      submitPromise(data, token).then((res) => {
        setUpdate({})
      }).catch(error => {
        if (error.message === 'Unauthorized') {
          oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
        } else {
          // setError('Error Saving Project')
          enqueueSnackbar('Error Saving Project', { preventDuplicate: true, action: DismissBtn });
          setUpdate({})
          setLoading(false)
        }
      })

    } else {
      setUpdate({})
      setLoading(false)
    }
    setProjectDialogOpen(false);
    // clearLocalStorage();
    setProjectSelected(null)
  }, [projectSelected, oktaAuth, setProjectSelected, setProjectDialogOpen, enqueueSnackbar, user.email]);

  const addProject = useCallback((event, data) => {
    setProjectSelected(null);
    setProjectDialogOpen(true);
  }, [setProjectSelected, setProjectDialogOpen])

  const editProject = useCallback((event, data) => {
    // const rowData = cloneDeep(data)
    // delete rowData.tableData // REMOVE TABLE DATA PROP
    setProjectSelected(data);
    setProjectDialogOpen(true);
  }, [setProjectSelected, setProjectDialogOpen])

  const deleteProject = useCallback((event, { id, tableData }) => {
    // setLoadingSessions(true)
    // setLoadingProjects(true)
    setLoading(true)
    const token = oktaAuth.getAccessToken()
    deleteProjectPromise(id, token).then((res) => {
      setUpdate({})
      // setLoadingSessions(false)
      // setLoadingProjects(false)
      setLoading(false)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        // setError('Error Deleting Project')
        enqueueSnackbar('Error Deleting Project', { preventDuplicate: true, action: DismissBtn });
        setUpdate({})
        // setLoadingSessions(false)
        // setLoadingProjects(false)
        setLoading(false)
      }
    })
  }, [oktaAuth, enqueueSnackbar])

  const getYoutubeVideo = useCallback(async (vid) => {
    const token = oktaAuth.getAccessToken()
    return getYoutubeVideoPromise(vid, token)
  }, [oktaAuth])

  const getVideos = useCallback(async (platform, teamFilter, playlists = false) => {
    const token = oktaAuth.getAccessToken()
    return getVideosPromise(platform, teamFilter, playlists, token).then((res) => {
      if (teamFilter !== 'TC') res.push({
        [platform.toLowerCase() === 'brightcove' ? 'name' : 'videoTitle']: `Add New ${playlists ? 'Playlist' : 'Video'}`,
        [platform.toLowerCase() === 'brightcove' ? 'id' : 'videoId']: 'NEW',
        [platform.toLowerCase() === 'brightcove' ? 'duration' : 'videoLength']: null,
        techCheck: false
      })
      return Promise.resolve(res)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('videos error', error)
        return []
      }
    })
  }, [oktaAuth])

  const getSurveys = useCallback(async (surveyType, forRegistration,) => {
    const token = oktaAuth.getAccessToken()
    return getSurveysPromise(surveyType.toLowerCase(), { forRegistration, forProject: true }, token).then((res) => {
      if (forRegistration) {
        res.unshift({
          name: 'New Registration Survey',
          id: 'NEW',
        })
      }
      return Promise.resolve(res)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('surveys error', error)
      }
    })
  }, [oktaAuth])

  const getPFMScreenings = useCallback(async (linked) => {
    const token = oktaAuth.getAccessToken()
    return getPFMScreeningsPromise(linked, token).then((res) => {
      // res.unshift({
      //   title: 'New PFM Online Screening',
      //   id: 'NEW',
      // })
      return Promise.resolve(res)
    }).catch(error => {
      if (error.message === 'Unauthorized') {
        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
      } else {
        console.log('screenings error', error)
      }
    })
  }, [oktaAuth])

  // useEffect(() => {
  //   if (error) setErrorOpen(true)
  // }, [error])

  // const handleErrorClose = useCallback(() => {
  //   setErrorOpen(false)
  // }, [])

  // const handleErrorClear = useCallback(() => {
  //   setError(undefined)
  // }, [])

  const handleVideoUpload = useCallback((videoId, platform, [file], isForensic) => {
    // console.log('upload!')
    // console.log(file)

    new Promise((resolve, reject) => {
      try {
        const video = document.createElement('video')
        video.preload = 'metadata'

        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);
          video.duration ? resolve(video.duration * 1000) : reject("Cannot determine video duration.")
        }

        video.onerror = () => {
          reject("Invalid video. Please select a video file.")
        }

        video.src = window.URL.createObjectURL(file)
      } catch (e) {
        reject(e)
      }
    }).then((videoLength) => {

      const onStarted = (e) => {
        setImportProgress('Uploading Video...')
        setImportStarted(true)
      }
      const onInitiated = (e) => console.log(e)
      const onProgress = (p) => setImportPercent(p * 100)
      const onComplete = () => setImportProgress('Ingesting Video...')
      const onError = (e) => setImportProgress('Error Uploading Video')

      setImportProgress('Requesting Upload...')

      const uploadVideoPromise = platform === 'Brightcove' ? uploadBrightcoveVideoPromise : uploadTicktboxVideoPromise

      const token = oktaAuth.getAccessToken()
      return uploadVideoPromise({ videoId, videoLength, isForensic }, file, token, onStarted, onInitiated, onProgress, onComplete, onError).then((res) => {
        console.log('ok', res)
        setImportComplete(true)
        setImportProgress('Upload Complete')
        setUpdate({})
      })

    }).catch(error => {
      console.log('err', error)
    })

  }, [oktaAuth])

  // const handleFileError = useCallback((err) => {
  //   console.log('error!')
  //   console.log(err)
  // }, [])

  const handleOpenVideoImportDialog = useCallback((event, data) => {
    setImportStarted(false)
    setImportComplete(false)
    setImportPercent(null)
    setImportProgress(null)
    setProjectSelected(data)
    setVideoImportOpen(true);
  }, [setProjectSelected, setVideoImportOpen]);

  const handleCloseVideoImportDialog = useCallback(() => {
    setProjectSelected(null)
    setVideoImportOpen(false);
    setUpdate({})
  }, [setProjectSelected, setVideoImportOpen]);

  const tableColumns = useCallback(() => ([
    { title: "Project Name", field: "projectName" },
    {
      title: "Associated Sessions", field: "sessions",
      customSort: (a, b) => Math.max(...a.sessions.map(x => new Date(x.startTime))) - Math.max(...b.sessions.map(x => new Date(x.startTime))),
      render: row => <div className={classes.names}>{row.sessions.map(session => <span key={`${session.id}sessionnamerow`}>{session.sessionName}</span>)}</div>,
    },
    { title: "Client", field: "client" },
    { title: "Video Platform", field: "videoPlatform" },
    {
      title: "Dial Project", field: "isDialProject",
      lookup: {
        'false': 'No',
        'true': 'Yes',
      },
    }
  ]).map((x, i) => {
    x.columnIndex = tableOrder[x.field] !== undefined ? tableOrder[x.field] : i
    x.defaultSort = tableSort[x.field]
    // if (x.filtering !== false) x.defaultFilter = tableFilters[x.field]
    return x
  }).sort((a, b) => {
    return a.columnIndex - b.columnIndex;
  }), [tableSort, tableOrder, classes])

  const projectsLoaded = useCallback(() => {
    setLoading(false)
  }, [])

  const showProjectGraph = useCallback((event, row) => {
    const win = window.open('https://' + config.dialUrl + '/project/' + row.id, '_blank');
    win.focus();
  }, [])

  const handleOrderChange = useCallback((i, order) => setTableSort(() => {
    // console.log('ORDER CHANGE!', order)
    const field = tableColumns()[i]?.field
    return field ? { [field]: order } : {}
  }), [tableColumns, setTableSort])

  const handleColumnDrag = useCallback((i, newIndex) => {
    const cols = [...tableColumns()]

    const x = cols[i]
    cols.splice(i, 1)
    cols.splice(newIndex, 0, x)

    setTableOrder(cols.reduce((p, c, ix) => {
      p[c.field] = ix
      return p
    }, {}))
  }, [tableColumns, setTableOrder])

  return (
    <Root>
      <SessionsSubscription refresh={update} key={`sessionssubscription`} />
      <ProjectsSubscription refresh={update} onLoaded={projectsLoaded} key={`projectssubscription`} />
      {(sessions && projectDialogOpen) && <ProjectModal team={user.team} allowFullEdit={user.accessLevels.includes('session')} allowPostEdit={!!user.fullAdmin} allowVideoEdit={user.accessLevels.includes('video')} data={projectSelected} getVideos={getVideos} getYoutubeVideo={getYoutubeVideo} getSurveys={getSurveys} getPFMScreenings={getPFMScreenings} sessions={sessions} onSubmit={submitProject} open={projectDialogOpen} handleClose={handleCloseProjectDialog} />}
      {(!loading && videoImportOpen) && <VideoImportModal session={projectSelected} onSubmit={handleVideoUpload} open={videoImportOpen} percent={importPercent} progress={importProgress} started={importStarted} complete={importComplete} handleClose={handleCloseVideoImportDialog} />}
      <div className={classes.projects}>
        <MaterialTable
          key={`projects-table`}
          tableRef={tableRef}
          onOrderCollectionChange={handleOrderChange}
          onColumnDragged={handleColumnDrag}
          options={{
            sorting: true,
            emptyRowsWhenPaging: false,
            actionsColumnIndex: -1,
            detailPanelType: 'single',
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 75, 100],
            actionsCellStyle: {
              // display: 'flex',
              // justifyContent: 'flex-end',
              marginRight: '5px'
            },
          }}
          columns={tableColumns() || []}
          isLoading={!tableProjects || loading}
          data={tableProjects || []} //MATERIAL TABLE MUTATES PROPS :(
          title="Project Groups"
          actions={[
            // ...tableActions,
            (rowData) => {
              return {
                icon: VideoCallIcon,
                tooltip: `Upload Video to ${rowData.videoPlatform}`,
                onClick: handleOpenVideoImportDialog,
                disabled: rowData.videoUploaded,
                hidden: rowData.type === 'Focus Group' || (['Brightcove', 'ticktBox'].includes(rowData.videoPlatform) ? !!rowData.videoLength : true) || !user.accessLevels.includes('video'),
                // isLoading: emailLoading.has(rowData.id)
              }
            },
            (rowData) => {
              return {
                icon: 'edit',
                tooltip: 'Edit',
                onClick: editProject,
                hidden: !(user.accessLevels.includes('session') || user.accessLevels.includes('video')),
                disabled: !sessions,
                // isLoading: emailLoading.has(rowData.id)
              }
            },
            (rowData) => {
              return {
                icon: 'delete',
                tooltip: 'Delete',
                onClick: deleteProject,
                hidden: !user.accessLevels.includes('session'),
                disabled: (!!rowData.checkInStarted)
              }
            },
            (rowData) => {
              return {
                icon: ShowChartIcon,
                tooltip: 'View Graph',
                hidden: (!rowData.isDialProject),
                onClick: showProjectGraph,
                disabled: (!rowData.started)
              }
            },
          ]}
        />
      </div>
      {/* <Snackbar
        // key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        TransitionProps={{
          onExited: handleErrorClear
        }}
        // onExited={handleErrorClear}
        message={error}
        action={
          <React.Fragment>
            {/* <Button color="secondary" size="small" onClick={handleClose}>
              UNDO
            </Button> * /}
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={handleErrorClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      /> */}
      {user.accessLevels.includes('session') && <AddFab show={true} loading={!sessions} onClick={addProject} />}
      <div className={classes.footer} />
    </Root>
  );
})

export default withSuspense(ProjectsPage)