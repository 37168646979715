import React, { useCallback } from 'react';
import ImportModal from './standard/ImportModal';

const VideoImportModal = React.memo(({ session: { videoId, videoPlatform }, open, onSubmit, ...props }) => {
  const modalProps = {
    title: `Upload Video to ${videoPlatform}`,
    placeholder: 'Drag & drop a video or click here to select',
    acceptedFileTypes: ['video/*'],
    maxFileSize: 10737418240,

  }

  const handleSubmit = useCallback((files, { isForensic }) => {
    console.log(videoId, videoPlatform, files, isForensic)
    onSubmit(videoId, videoPlatform, files, isForensic)
  }, [videoId, videoPlatform, onSubmit])

  return (
    <ImportModal
      modalKey='videoModal'
      fields={videoPlatform === 'ticktBox' ? [
        {
          key: 'isForensic',
          label: 'Add Forensic Watermarking',
          type: 'boolean',
          defaultValue: false
        }
      ] : []}
      open
      onSubmit={handleSubmit}
      {...props}
      {...modalProps}
    />
  );
})

export default VideoImportModal;
