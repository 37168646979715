import { useEffect, useCallback, useRef } from 'react'
import { getRewardsPromise, getSessionGuestRewards } from '../services/rewardsService'
import { useSetRecoilState } from 'recoil';
import { sessionRewards, sessionGuestRewards, exchangeRates } from './atoms'
import { useOktaAuth } from '@okta/okta-react'
import * as workerTimers from 'worker-timers';
import { getExchangeRatesPromise } from '../services/tangoService';

// const isEqual = require('lodash/isEqual');
// const differenceWith = require('lodash/differenceWith');

export default function SessionRewardsSubscription({ sessionId, refresh, onLoaded }) {
    const { oktaAuth } = useOktaAuth();
    const loaded = useCallback(onLoaded ? onLoaded : () => { }, [onLoaded])
    const setSessionRewards = useSetRecoilState(sessionRewards(sessionId))
    const setSessionGuestRewards = useSetRecoilState(sessionGuestRewards(sessionId))
    const setExchangeRates = useSetRecoilState(exchangeRates)
    // const [sessions, setSessions] = useRecoilState(sessionState);
    // const sessionsRef = useRef(sessions);
    // const [error, setError] = useState(null)

    const rewardsId = useRef()
    const syncInterval = useRef()

    useEffect(() => {
        // const subscription = getSessions(oktaAuth.getAccessToken()).subscribe(setSessions, (err) => {
        //     console.log("ERROR", err)
        //     setError(err)
        // })

        // let syncInterval;
        // const sync = () => getSessionGuestRewards(false, oktaAuth.getAccessToken()).then(setSessions).then(loaded).catch((error) => {
        //     if (error.message === 'Unauthorized') {
        //         oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
        //       } else {
        //         throw error
        //       }
        // })
        const sync = (guestsOnly) => (guestsOnly ? Promise.resolve() : getRewardsPromise({ sessionId }, oktaAuth.getAccessToken()).then((rewards) => {
            // console.log('GOT REWARDS', rewards)
            rewardsId.current = rewards.id
            setSessionRewards(rewards)
        })).then(() => {
            if (rewardsId.current) return getSessionGuestRewards(rewardsId.current, oktaAuth.getAccessToken()).then(setSessionGuestRewards)
        }).then(loaded).catch((error) => {
            if (error.message === 'Unauthorized') {
                oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
            } else {
                throw error
            }
        })

        // console.log('LOADING REWARDS DATA', refresh)

        sync(refresh.guestsOnly)

        // sync().then(() => {
        //     syncInterval = workerTimers.setInterval(sync, 300000)
        // })

        // const syncInterval = workerTimers.setInterval(() => {
        //     getSessionsPromise(oktaAuth.getAccessToken()).then(setSessions)
        // }, 5000)

        return function cleanup() {
            if (syncInterval.current) workerTimers.clearInterval(syncInterval.current)
            // subscription.unsubscribe()
        };
    }, [oktaAuth, sessionId, refresh, loaded, setSessionRewards, setSessionGuestRewards]);

    useEffect(() => {
        getExchangeRatesPromise(oktaAuth.getAccessToken()).then(setExchangeRates)
    }, [oktaAuth, setExchangeRates])

    // useEffect(() => {
    //     if (error) throw error
    // }, [error])

    // useEffect(() => {
    //     let subscription;
    //     let error;
    //     if (sessions && sessionsRef.current && !isEqual(sessions, sessionsRef.current)) {
    //         if (sessions.length < sessionsRef.current.length) {
    //             const [{ sessionId }] = differenceWith(sessionsRef.current, sessions, isEqual)
    //             subscription = deleteSession(sessionId).subscribe(null, (err) => {
    //                 error = err
    //             })
    //         } else {
    //             const [ session ] = differenceWith(sessions, sessionsRef.current, isEqual)
    //             subscription = upsertSession(session).subscribe(null, (err) => {
    //                 error = err
    //             })
    //         }
    //         // subscription = getSessions.subscribe(setSessions)

    //     }
    //     sessionsRef.current = sessions

    //     return function cleanup() {
    //         subscription && subscription.unsubscribe()
    //         if (error) throw error
    //     };
    // }, [sessions]);

    return null;
}