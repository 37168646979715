import React, { useState, useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import clsx from 'clsx';

const PREFIX = 'StandardNavBar';

const classes = {
    root: `${PREFIX}-root`,
    rootShift: `${PREFIX}-rootShift`,
    toolbar: `${PREFIX}-toolbar`,
    title: `${PREFIX}-title`,
    image: `${PREFIX}-image`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        width: 'auto',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: 0,
        zIndex: 1
    },

    [`& .${classes.rootShift}`]: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: ({rightMargin}) => rightMargin,
    },

    [`& .${classes.toolbar}`]: {
        width: 'auto',
        height: 'calc(5rem + 5px)',
        backgroundColor: theme.palette.toolbar || theme.palette.primary.main
    },

    [`& .${classes.title}`]: {
        // display: 'flex',
        // alignItems: 'center',
        // flexGrow: '1',
        padding: '0 1em 0 1em'
    },

    [`& .${classes.image}`]: {
        height: 'auto',
        width: 'auto',
        marginLeft: 'auto',
        marginRight: '25px',
        maxWidth: '200px'
    }
}));

const StandardNavBar = React.memo(({ title, image, navDrawerComponent: NavDrawerComponent, drawerProps, auxActions = [], rightMargin = 0 }) => {
    // console.log('render nav bar')
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();


    const handleDrawerOpen = useCallback((e) => {
        e.currentTarget.blur()
        setDrawerOpen(true);
    }, []);

    const handleDrawerClose = useCallback(() => {
        setDrawerOpen(false);
    }, []);

    const closeOpenDrawer = useCallback((event) => {
        if (drawerOpen) {
            if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
            setDrawerOpen(false)
        }
    }, [drawerOpen])

    return (
        <Root className={clsx(classes.root, { [classes.rootShift]: !!rightMargin })} theme={theme}>
            <AppBar position="static">
                <Toolbar className={classes.toolbar}>
                    {NavDrawerComponent && <IconButton color="inherit" onClick={handleDrawerOpen}>
                        <MenuIcon />
                    </IconButton>}
                    {(title && image) && <Typography variant="h5" noWrap className={classes.title}>
                        {title}
                    </Typography>}
                    {image && <img src={image} alt={title} className={classes.image} />}
                    {(title && !image) && <Typography variant="h5" noWrap className={classes.title}>
                        {title}
                    </Typography>}
                    {auxActions.filter(x => !x.hidden).map(({ iconComponent: IconComponent, onClick }) => (
                        <IconButton color="inherit" onClick={onClick}>
                            <IconComponent />
                        </IconButton>
                    ))}
                </Toolbar>
            </AppBar>
            {NavDrawerComponent && <div className={classes.root}
                onClick={closeOpenDrawer}
                onKeyDown={closeOpenDrawer}
            >
                <NavDrawerComponent open={drawerOpen} onClose={handleDrawerClose} onOpen={handleDrawerOpen} {...drawerProps} />
            </div>}
        </Root>
    );

})

export default (StandardNavBar);
