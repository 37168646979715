import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Backdrop, Modal, Fade, Paper, Button, Typography, Badge, CircularProgress, List, ListItem, ListItemText, ListItemIcon, Tooltip } from '@mui/material';
import { DropzoneArea } from 'mui-file-dropzone';
import { Clear, Description } from '@mui/icons-material';

const PREFIX = 'UploadSessionFileModal';

const classes = {
    root: `${PREFIX}-root`,
    modal: `${PREFIX}-modal`,
    paper: `${PREFIX}-paper`,
    header: `${PREFIX}-header`,
    body: `${PREFIX}-body`,
    headerText: `${PREFIX}-headerText`,
    footer: `${PREFIX}-footer`,
    button: `${PREFIX}-button`,
    deleteIconButton: `${PREFIX}-deleteIconButton`,
    customBadge: `${PREFIX}-customBadge`,
    dropzone: `${PREFIX}-dropzone`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Modal)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '30px',
    paddingBottom: '30px',
    width: '100vw',
    justifyContent: 'center',
    [`& .${classes.root}`]: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0.5rem'
    },
    [`& .${classes.paper}`]: {
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
        width: '40%',
        minWidth: '300px',
        padding: '15px 0 0 0',
        maxHeight: '98%'
    },
    [`& .${classes.header}`]: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0 15px 0 15px',
        marginBottom: '6px'
    },
    [`& .${classes.body}`]: {
        padding: '0 15px 0 15px',
        overflowY: 'auto',
        marginTop: '1rem',
    },
    [`& .${classes.headerText}`]: {
        margin: 'auto 0 auto 0',
        whiteSpace: "nowrap"
    },
    [`& .${classes.footer}`]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '0 0 15px 0',
        marginTop: '5rem'
    },
    [`& .${classes.button}`]: {
        margin: '3px'
    },
    [`& .${classes.deleteIconButton}`]: {
        padding: '0px',
        fontSize: '0px',
        minWidth: '0px',
        color: 'grey',
        "&:hover": { color: "red", backgroundColor: 'transparent' }
    },
    [`& .${classes.customBadge}`]: {
        backgroundColor: "#4BB543",
        color: "white"
    },
    [`& .${classes.dropzone}`]: {
        minHeight: 140
    }
});


const UploadSessionFileModal = ({ openAddFileModal, checkFileList, uploadSessionFile, setOpenAddFileModal }) => {

    const [loadingFiles, setLoadingFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileStatus, setFileStatus] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);
    // const [filesData, setFilesData] = useState(filesToUpload.map(i => {}));

    const handleFileChange = (files) => {
        setLoadingFiles([]);
        setUploadedFiles([]);
        setFilesToUpload(files.filter(i => !uploadedFiles.some(j => i.name === j)));
        // setFilesToUpload(e.target.files[0]);
    }

    const removeFile = (name) => {
        let files = filesToUpload.filter(i => i.name !== name);
        setFilesToUpload(files);
    }

    const onClickUpload = async () => {
        setLoadingFiles(filesToUpload.map(i => i.name));
        for (const file of filesToUpload) {
            await uploadSessionFile(file);
            let uplFiles = uploadedFiles;
            uplFiles.push(file.name);
            setUploadedFiles(uplFiles);
            setLoadingFiles(loadingFiles.filter(i => i.name !== file.name));
        }
    }

    const closeModal = () => {
        setFilesToUpload([]);
        setLoadingFiles([]);
        setUploadedFiles([]);
        checkFileList();
        setOpenAddFileModal(false)
    }

    return (
        <Root
            id="add-file-modal"
            className={classes.modal}
            open={openAddFileModal}
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 250,
                    sx: { zIndex: -1 }
                }
            }}
        >

            <Fade in={openAddFileModal}>
                <Paper className={classes.paper}>
                    <div className={classes.header}>
                        <Typography variant="h5" className={classes.headerText}>Add File</Typography>
                    </div>
                    <div className={classes.body}>
                        <form style={{ textAlignLast: 'center' }} autoCapitalize="off">

                            <DropzoneArea dropzoneClass={classes.dropzone} showAlerts={false} onChange={handleFileChange} showPreviewsInDropzone={false} filesLimit={10} />
                            {filesToUpload.length > 0 ? <>
                                <div>
                                    <List style={{ overflowY: 'scroll', height: 150 }}>
                                        {filesToUpload.map((file, i) => {
                                            return <ListItem key={i}>
                                                <ListItemIcon><Badge classes={uploadedFiles?.includes(file.name) ? { badge: classes.customBadge } : {}} variant="dot"><Description /></Badge></ListItemIcon>
                                                {loadingFiles?.includes(file.name) ? <CircularProgress color="primary" size={20} /> : <></>}
                                                <ListItemText primary={file.name} />
                                                <ListItemIcon >{!loadingFiles?.includes(file.name) && !uploadedFiles.includes(file.name) && <Tooltip title="Remove File"><Button className={classes.deleteIconButton} onClick={() => removeFile(file.name)}><Clear /></Button></Tooltip>}</ListItemIcon>
                                            </ListItem>
                                        })}
                                    </List>
                                </div></> : <></>}
                            <div className={classes.footer}>
                                <Button className={classes.button} disabled={filesToUpload.length === uploadedFiles.length} variant="contained" onClick={onClickUpload} color="primary">Upload</Button>
                                <Button className={classes.button} variant="contained" onClick={closeModal}>Close</Button>
                            </div>
                        </form>
                    </div>
                </Paper>
            </Fade>
        </Root>
    );
}

export default (UploadSessionFileModal)