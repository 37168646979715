import React from 'react';
import { useRecoilValue } from 'recoil';
import { currentUser, showSessionChat } from '../recoil/atoms'
import { useMatch } from "react-router-dom";
import StandardNavBar from './standard/StandardNavBar';
import seasiLogo from '../resources/logo.png'
import AppDrawer from './AppDrawer';

const AppNavBar = React.memo((props) => {
    const user = useRecoilValue(currentUser);
    const match = useMatch('/session/:sessionId');
    
    const showChat = useRecoilValue(showSessionChat(match?.params?.sessionId));

    const chat = (match && !!showChat)
    const margin = (chat && !!(showChat && showChat.open))

    const barProps = {
        title: 'Session Management Console',
        image: seasiLogo,
        navDrawerComponent: AppDrawer,
        drawerProps: { allowAddSession: user.accessLevels.includes('session'), allowBan: user.fullAdmin, allowEmails: user.accessLevels.includes('session'), userName: user.name , userEmail: user.email },
        // auxActions: [
        //     {
        //         iconComponent: ChatIcon,
        //         onClick: () => {
        //             setShowChat(prev => {
        //                 const newMap = new Map(prev)
        //                 const isOpen = newMap.get(match.params.sessionId)
        //                 newMap.set(match.params.sessionId, !isOpen)
        //                 return newMap
        //             })
        //         },
        //         hidden: !chat
        //     }
        // ],
        rightMargin: margin ? 'calc(200px + (650 - 200) * ((100vw - 100px) / (2400 - 100)))' : 0
    }

    return <StandardNavBar {...barProps} {...props} />;

})

export default AppNavBar;
