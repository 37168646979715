import React, { useMemo } from 'react';
import StandardModal from './standard/StandardModal';

const ChimeChatUsersModal = React.memo((props) => {
  const { channelGuests, channelData, allowedRoles, guests, connections } = props

  const availableUsers = useMemo(() => {
    if (allowedRoles && connections && guests) {
      return guests.filter(g => allowedRoles.includes(g.role) && !!connections[g.id]).map((g) => {
        const userConnection = connections[g.id]
        return {
          ...g,
          ...userConnection
        }
      })
    } else {
      return []
    }
  }, [allowedRoles, connections, guests])

  const data = useMemo(() =>{
    const userIds = availableUsers.map(x => x.chimeUserId)
    const userList = channelGuests.filter(x => userIds.includes(x))
    return {
      chimeChannelUsers: userList,
      originalChannelUsers: userList
    }
  }, [channelGuests, availableUsers])

  return (
    <StandardModal
      modalKey='chimeChatUsersModal'
      open
      data={data}
      {...props}
      title={`Manage Channel Users for ${channelData.Name}`}
      buttonLabel='Submit'
      fields={[
        {
          key: 'chimeChannelUsers',
          sublabel: 'Users',
          type: 'transfer-list',
          fullList: availableUsers,
          listKey: 'chimeUserId'
          // disabled: disabledFn
        }
      ]}
    />
  );
})

export default ChimeChatUsersModal;
