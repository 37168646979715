import Evaporate from 'evaporate'
import crypto from 'crypto'
import md5 from 'js-md5'
import config from '../config.json'

export const getVideosPromise = (platform, teamFilter, playlists, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/videos?platform=${platform}&playlists=${!!playlists}${teamFilter ? `&teamFilter=${teamFilter}` : ''}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const uploadBrightcoveVideoPromise = ({ videoId }, file, token, onStarted, onInitiated, onProgress, onComplete, onError) =>
    fetch(`https://${config.rest.sessionManagementAPI}/bc-video?filename=${file.name}&videoId=${videoId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(signedResponseData => {

        console.log(signedResponseData)

        const uploadConfig = {
            signerUrl: `https://${config.rest.sessionManagementAPI}/bc-video/${videoId}`,
            region: 'us-east-1',
            aws_key: signedResponseData.access_key_id,
            awsRegion: 'us-east-1',
            bucket: signedResponseData.bucket,
            awsSignatureVersion: '4',
            computeContentMd5: true,
            sendCanonicalRequestToSignerUrl: true,
            // logging: this.logging,
            cryptoMd5Method: (data) => md5.base64(data),
            cryptoHexEncodedHash256: (data) => crypto.createHash('sha256').update(data).digest('hex'),
            signHeaders: {
                'Authorization': 'Bearer ' + token
            }
        }

        return Evaporate.create(uploadConfig).then((evap) => {
            return evap.add({
                name: signedResponseData.object_key,
                file,
                error: onError,
                started: onStarted,
                complete: onComplete,
                uploadInitiated: onInitiated,
                progress: onProgress,
                xAmzHeadersAtInitiate: {
                    'X-Amz-Security-Token': signedResponseData.session_token,
                },
                xAmzHeadersCommon: {
                    'X-Amz-Security-Token': signedResponseData.session_token,
                },
            })

        }).then(() => {
            return fetch(`https://${config.rest.sessionManagementAPI}/bc-video`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                    ingestUrl: signedResponseData.api_request_url,
                    videoId
                })
            })
        })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    })

export const getYoutubeVideoPromise = (videoId, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/youtube?videoId=${videoId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const uploadTicktboxVideoPromise = ({ videoId, videoLength, isForensic }, file, token, onStarted, onInitiated, onProgress, onComplete, onError) =>
    fetch(`https://${config.rest.sessionManagementAPI}/tb-video?filename=${file.name}&videoId=${videoId}&forensic=${!!isForensic}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(signedResponseData => {

        console.log(signedResponseData)

        const uploadConfig = {
            signerUrl: `https://${config.rest.sessionManagementAPI}/tb-video/sign`,
            region: signedResponseData.region,
            aws_key: signedResponseData.access_key_id,
            awsRegion: signedResponseData.region,
            bucket: signedResponseData.bucket,
            awsSignatureVersion: '4',
            computeContentMd5: true,
            sendCanonicalRequestToSignerUrl: true,
            // logging: this.logging,
            cryptoMd5Method: (data) => md5.base64(data),
            cryptoHexEncodedHash256: (data) => crypto.createHash('sha256').update(data).digest('hex'),
            signHeaders: {
                'Authorization': 'Bearer ' + token
            }
        }

        return Evaporate.create(uploadConfig).then((evap) => {
            return evap.add({
                name: signedResponseData.object_key,
                file,
                error: onError,
                started: onStarted,
                complete: onComplete,
                uploadInitiated: onInitiated,
                progress: onProgress,
                // xAmzHeadersAtInitiate: signedResponseData.headers.params,
                // xAmzHeadersCommon: signedResponseData.headers.params,
            })

        }).then(() => {
            return fetch(`https://${config.rest.sessionManagementAPI}/tb-video`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                    videoId,
                    videoLength,
                    isForensic
                })
            })
        })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    })

export const getTicktBoxStream = (videoId, drmType, token) =>
    fetch(`https://${config.rest.ticktboxVideoAPI}/stream?videoId=${videoId}&drmType=${drmType}`, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

