import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, TextField, Fade } from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment-timezone'

const PREFIX = 'DateTimeField';

const classes = {
  root: `${PREFIX}-root`,
  label: `${PREFIX}-label`,
  tz: `${PREFIX}-tz`,
  datePicker: `${PREFIX}-datePicker`,
  selectField: `${PREFIX}-selectField`
};

const Root = styled('div')({
  //border: 'solid 2px green',
  display: 'flex',
  padding: '5px',
  alignItems: 'center',
  [`& .${classes.label}`]: {
    flexBasis: '20%',
    // minWidth: '25%',
    textAlign: 'right',
    marginRight: '10px'
  },
  [`& .${classes.tz}`]: {
    // flexBasis: '20%',
    textAlign: 'left',
    marginLeft: '10px'
  },
  [`& .${classes.datePicker}`]: {
    // flexBasis: '35%',
    minWidth: '12em',
    //width: '100%'
    '& input': {
      padding: '10.5px 14px',
      paddingRight: 0
    }
  },
  [`& .${classes.selectField}`]: {
    flexBasis: '20%',
    margin: '0 18px 0 18px'
  }
});

const zone = moment.tz.guess()
const timezone = moment.tz(zone).format("z")

const DateTimeField = React.memo(({ value, label, disabled, placeholder, fieldKey, required, conditional, conditionMet, onChange, invalid, invalidate, allowPast, showTimezone }) => {


  const handleChange = useCallback(newValue => {
    onChange(null, newValue)
  }, [onChange]);

  return (
    <Root>
      <Typography className={classes.label}>{label}</Typography>
      <MobileDateTimePicker
        className={classes.datePicker}
        renderInput={props => <TextField {...props} helperText={null} variant="outlined" size="small" error={invalid} inputProps={{ ...props.inputProps, placeholder: placeholder || 'Enter Date/Time' }} />}
        // autoOk
        disablePast={!allowPast}
        // format="MM/DD/YY HH:mm:ss A"
        // labels={true}
        // showToolbar={true}
        disabled={disabled}
        value={value ? moment(value) : value}
        onChange={handleChange}
        // slots={{}
        //   // { mobileTransition: <Fade disableStrictModeCompat /> }
        // }
      />
      {!!showTimezone && <Typography className={classes.tz}>{timezone}</Typography>}
      {/* <Select
        // value={chatStatus}
        // onChange={handleChatStatus}
        displayEmpty
        className={classes.selectField}
      >
        <MenuItem value='ALL'>ALL</MenuItem>
        <MenuItem value='Not Ready'>Not Ready</MenuItem>
        <MenuItem value='Waiting'>Waiting</MenuItem>
        <MenuItem value='Watching'>Watching</MenuItem>
      </Select> */}
    </Root>
  );
})

DateTimeField.defaultProps = {
  label: 'Date/Time'
}

export default DateTimeField;
