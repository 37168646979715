import { selector, selectorFamily } from 'recoil';
import { sessionState, guestConnections, guestsForSession } from './atoms';
// import { getSessionPromise } from '../services/sessionsService'
// import { getGuestsPromise } from '../services/guestsService'
import moment from 'moment'

export const pastSessions = selectorFamily({
    key: 'filteredSessionsPast',
    get: ([completed, test, canceled, deleted, dial, project]) => ({ get }) => {
        const currentSessions = get(sessionState)
        return currentSessions ? currentSessions.filter((item) =>
                // If completed is true
                (completed && !!item.complete && !(!!item.test)) ||
                // If canceled is true
                (canceled && !!item.canceled && !(!!item.test)) ||
                // If deleted is true
                (deleted && !!item.deleted && !(!!item.test)) ||
                // If test is true we need to check if complete or canceled or deleted
                (test && !!item.test && (item.complete || item.canceled || item.deleted)) ||
                // If test is true we need to check if complete or canceled or deleted
                (dial && !!item.isDialSession && (item.complete || item.canceled || item.deleted) && !(!!item.test)) ||
                // If test is true we need to check if complete or canceled or deleted
                (project && !!item.hasProject && (item.complete || item.canceled || item.delested) && !(!!item.test))
        ) : null
    }
})

export const upcomingSessions = selector({
    key: 'filteredSessionsUpcoming',
    get: ({ get }) => {
        const currentSessions = get(sessionState)
        return currentSessions ? currentSessions.filter((item) => !item.complete && !item.canceled && !item.deleted) : null
        // return currentSessions ? currentSessions.filter((item) => !item.complete && !moment(item.scheduledStartTime).isBetween(moment(), moment().add(12, 'hours'))) : null
    }
})

export const currentSessions = selector({
    key: 'filteredSessionsCurrent',
    get: ({ get }) => {
        // console.log(authClient)
        const currentSessions = get(sessionState)
        return currentSessions ? currentSessions.filter((item) => !item.complete && !item.canceled && !item.deleted && moment(item.scheduledStartTime).isBetween(moment(), moment().add(1, 'days'))) : null
    }
})

export const clientsForSession = selectorFamily({
    key: 'sessionClients',
    get: sessionId => async ({ get }) => {
        const guests = await get(guestsForSession(sessionId))
        return guests ? guests.filter(g => g.role === 'Client') : null
    },
})

export const guestsForSessionRewards = selectorFamily({
    key: 'sessionRewardsGuests',
    get: sessionId => async ({ get }) => {
        const guests = await get(guestsForSession(sessionId))
        return guests ? guests.filter(g => g.role === 'Viewer' && ['Complete', 'Turn Away', 'Ejected'].includes(g.state)) : null
    },
})

export const videoConnections = selectorFamily({
    key: 'videoConnections',
    get: sessionId => async ({ get }) => {
        const currentConnections = await get(guestConnections(sessionId))
        return currentConnections ? Object.fromEntries(Object.entries(currentConnections).filter(([k, v]) => !v.techCheck)) : null
    },
})

export const techCheckConnections = selectorFamily({
    key: 'techCheckConnections',
    get: sessionId => async ({ get }) => {
        const currentConnections = await get(guestConnections(sessionId))
        return currentConnections ? Object.fromEntries(Object.entries(currentConnections).filter(([k, v]) => v.techCheck)) : null
    },
});

export const clientConnections = selectorFamily({
    key: 'clientConnections',
    get: sessionId => async ({ get }) => {
        const currentConnections = await get(guestConnections(sessionId))
        return currentConnections ? Object.fromEntries(Object.entries(currentConnections).filter(([k, v]) => v.userRole === 'Client')) : null
    },
})

// export const getSessionsQuery = selector({
//     key: 'sessionsQuery',
//     get: async ({get}) => {
//       const response = await fetch('https://3ewiecfpl6.execute-api.us-west-1.amazonaws.com/test/sessions')
//       const items = await response.json()
//       console.log('here we go...', items)
//       return items.Items;
//     },
//   });

// export const getSessionGuests = selectorFamily({
//     key: 'sessionsQuery',
//     get: sessionId => async ({ get }) => {
//         const response = await getGuestsPromise(sessionId)
//         const items = await response.json()
//         return items.Items;
//     },
// });
