import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { emailTemplates } from '../recoil/atoms'
import StandardModal from './standard/StandardModal';
import EmailTemplatesSubscription from '../recoil/EmailTemplatesSubscription';

const EmailModal = React.memo(({opts = {}, ...props}) => {
  const templates = useRecoilValue(emailTemplates);
  const disabledEmailsFn = useCallback((px, option) => !!opts[option.code], [opts])

  const templateOptions = useCallback(() => {
    return templates ? Promise.resolve(templates) : new Promise(() => {})
  }, [templates])

  const onTemplateChange = useCallback((newValue, newParams) => {
    if (newValue) {
      newParams.subject = newValue.subject
      newParams.content = newValue.content
      newParams.includeLogo = newValue.includeLogo
      newParams.includeTitle = newValue.includeTitle
      newParams.includeTime = newValue.includeTime
      newParams.emailTitle = newValue.emailTitle
    }
    return newParams
  }, [])

  return ([
    <StandardModal
      modalKey='emailModal'
      open
      {...props}
      title='Send Batch Email'
      buttonLabel='Submit'
      fields={[
        {
          key: 'type',
          label: 'Email Type',
          type: 'select',
          placeholder: 'Select Email Type',
          options: [
            // { code: 'SEA', label: "Screen Engine" },
            { code: 'schedule', label: "Registration Reminder Link" },
            { code: 'verify', label: "Self-Guided Tech Check Link" },
            { code: 'event', label: "Event Link (Re-Send)" },
            { code: 'custom', label: "Custom Email" }
          ],
          optionLabel: 'label',
          optionValueKey: 'code',
          multiple: false,
          required: true,
          disabledOptions: disabledEmailsFn
        },
        {
          key: 'template',
          label: 'Custom Email Template',
          type: 'select',
          placeholder: 'Select Email Template',
          options: templateOptions,
          optionLabel: 'name',
          optionValueKey: 'id',
          multiple: false,
          conditions: [
            {
              condition: ({ type }) => type === 'custom'
            }
          ],
          onUpdate: onTemplateChange
        },
        {
          key: 'subject',
          label: 'Email Subject',
          placeholder: 'Enter Email Subject Line',
          type: 'text',
          conditions: [
            {
              condition: ({ type }) => type === 'custom',
              required: true
            }
          ],
        },
        {
          key: 'emailTitle',
          label: 'Email Title',
          placeholder: 'Enter Email Title',
          type: 'text',
          conditions: [
            {
              condition: ({ type }) => type === 'custom',
              required: true
            }
          ],
        },
        {
          key: 'content',
          label: 'Email Content',
          placeholder: 'Enter Email Content',
          type: 'rich-text',
          html: true,
          conditions: [
            {
              condition: ({ type }) => type === 'custom',
              required: true
            }
          ],
        },
        {
          key: 'includeLogo',
          label: 'Include Logo Image',
          type: 'boolean',
          defaultValue: true,
          conditions: [
            {
              condition: ({ type }) => type === 'custom',
            }
          ],
        },
        {
          key: 'includeTitle',
          label: 'Include Event Title',
          type: 'boolean',
          defaultValue: true,
          conditions: [
            {
              condition: ({ type }) => type === 'custom',
            }
          ],
        },
        {
          key: 'includeTime',
          label: 'Include Event Date/Time',
          type: 'boolean',
          defaultValue: true,
          conditions: [
            {
              condition: ({ type }) => type === 'custom',
            }
          ],
        },
      ]}
    />,
    <EmailTemplatesSubscription key={`emailtemplatessubscription`} />
  ]);
})

export default EmailModal;
