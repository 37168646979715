import React, { useCallback, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SelectInputField from './SelectInputField';

const PREFIX = 'MultiSelectInputField';

const classes = {
  root: `${PREFIX}-root`,
  label: `${PREFIX}-label`,
  builder: `${PREFIX}-builder`,
  addnew: `${PREFIX}-addnew`
};

const Root = styled('div')({
  //border: 'solid 2px green',
  display: 'flex',
  padding: '5px',
  alignItems: 'center',
  width: '100%',
  [`& .${classes.label}`]: {
    flexBasis: '20%',
    // minWidth: '25%',
    textAlign: 'right',
    marginRight: '10px'
  },
  [`& .${classes.builder}`]: {
    flexBasis: '80%'
  },
  [`& .${classes.addnew}`]: {
    margin: '8px 0 8px 0'
  },
});

const cloneDeep = require('lodash/cloneDeep');

const MultiSelectInputField = React.memo(({ value = [], label, buttonLabel, fieldKey, required, conditional, conditionMet, onChange, invalid, invalidate, optionValueKey, fullObjects, ...restProps }) => {

  const valueRef = useRef();

  valueRef.current = value || []

  const handleAdd = useCallback(() => {
    const newValue = [...valueRef.current, null]
    onChange(null, newValue)
  }, [onChange])

  const handleInputChange = useCallback((changedValue, index) => {
    const newValue = cloneDeep(valueRef.current)
    newValue[index] = (changedValue && (!fullObjects && optionValueKey)) ? Array.isArray(changedValue) ? changedValue.map(x => x[optionValueKey]) : changedValue[optionValueKey] : changedValue
    console.log('NEW SELECT BUILDER VALUE', newValue)
    onChange(null, newValue)
  }, [onChange, optionValueKey, fullObjects]);

  const handleRemove = useCallback((index) => {
    const newValue = cloneDeep(valueRef.current)
    newValue.splice(index, 1)
    // invalidate && invalidate(`${fieldKey}-button${index}`, false)
    onChange(null, newValue)
  }, [onChange]);

  return (
    <Root>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <div className={classes.builder}>
        {value && value.map((val, i) =>
          <SelectInputField
            // type='select'
            fieldKey={`${fieldKey}-${i}`}
            key={`${fieldKey}-${i}`}
            // index={i}
            value={val}
            required={true}
            // required={required || (conditional && conditionMet)}
            optionValueKey={optionValueKey}
            fullObjects={fullObjects}
            onRemove={() => handleRemove(i)}
            onChange={(e, v) => handleInputChange(v, i)}
            invalidate={invalidate}
            {...restProps}
          />
        )}
        <div className={classes.addnew}>
          <Button variant="outlined" onClick={handleAdd}>
            <AddIcon /> {buttonLabel || 'Add'}
          </Button>
        </div>
      </div>
    </Root>
  );
})

// MultiSelectInputField.defaultProps = {

// }

export default MultiSelectInputField;
