import { useEffect, useCallback } from 'react'
import { getSessionPromise } from '../services/sessionsService'
import { useSetRecoilState } from 'recoil';
import { selectedSession } from './atoms'
import { useOktaAuth } from '@okta/okta-react'
import * as workerTimers from 'worker-timers';

// const isEqual = require('lodash/isEqual');
// const differenceWith = require('lodash/differenceWith');

const parseForDataAPI = async (s) => {
    if (s) {
        const segmentGoals = s.segmentGoals || {} //NEEDED FOR DATA API
        s.audienceSegments = s.audienceSegments.map(segment => {
            if (segmentGoals[segment.questionKey]) {
                Object.entries(segmentGoals[segment.questionKey]).forEach(([answerKey, goal]) => {
                    const answerIndex = segment.answers.findIndex(x => x.answer === answerKey)
                    if (answerIndex >= 0) segment.answers[answerIndex].goal = ((!!goal && typeof goal === 'object') ? { ...goal } : goal)
                })
            }
            return segment
        })
        s.virtualSegments = s.virtualSegments?.map(segment => {
            if (segmentGoals[segment.questionKey]) {
                Object.entries(segmentGoals[segment.questionKey]).forEach(([answerKey, goal]) => {
                    const answerIndex = segment.answers.findIndex(x => x.answer === answerKey)
                    if (answerIndex >= 0) segment.answers[answerIndex].goal = ((!!goal && typeof goal === 'object') ? { ...goal } : goal)
                })
            }
            return segment
        })
        return s
    } else {
        return {}
    }
}

export default function SessionSubscription({ sessionId, refresh, onLoaded }) {
    const setSelectedSession = useSetRecoilState(selectedSession)
    const { oktaAuth } = useOktaAuth();
    const loaded = useCallback(onLoaded ? onLoaded : () => { }, [onLoaded])
    // const [sessions, setSessions] = useRecoilState(sessionState);
    // const sessionsRef = useRef(sessions);
    // const [error, setError] = useState(null)

    useEffect(() => {
        // const subscription = getSessions(oktaAuth.getAccessToken()).subscribe(setSessions, (err) => {
        //     console.log("ERROR", err)
        //     setError(err)
        // })

        let syncInterval;
        const sync = () => getSessionPromise(sessionId, oktaAuth.getAccessToken()).then(parseForDataAPI).then(setSelectedSession).then(loaded).catch((error) => {
            if (error.message === 'Unauthorized') {
                oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
            } else {
                throw error
            }
        })

        sync()

        // sync().then(() => {
        //     syncInterval = workerTimers.setInterval(sync, 300000)
        // })

        // const syncInterval = workerTimers.setInterval(() => {
        //     getSessionsPromise(oktaAuth.getAccessToken()).then(setSessions)
        // }, 5000)

        return function cleanup() {
            if (syncInterval) workerTimers.clearInterval(syncInterval)
            // subscription.unsubscribe()
        };
    }, [oktaAuth, sessionId, refresh, loaded, setSelectedSession]);

    // useEffect(() => {
    //     if (error) throw error
    // }, [error])

    // useEffect(() => {
    //     let subscription;
    //     let error;
    //     if (sessions && sessionsRef.current && !isEqual(sessions, sessionsRef.current)) {
    //         if (sessions.length < sessionsRef.current.length) {
    //             const [{ sessionId }] = differenceWith(sessionsRef.current, sessions, isEqual)
    //             subscription = deleteSession(sessionId).subscribe(null, (err) => {
    //                 error = err
    //             })
    //         } else {
    //             const [ session ] = differenceWith(sessions, sessionsRef.current, isEqual)
    //             subscription = upsertSession(session).subscribe(null, (err) => {
    //                 error = err
    //             })
    //         }
    //         // subscription = getSessions.subscribe(setSessions)

    //     }
    //     sessionsRef.current = sessions

    //     return function cleanup() {
    //         subscription && subscription.unsubscribe()
    //         if (error) throw error
    //     };
    // }, [sessions]);

    return null;
}