import React from 'react';
import StandardModal from './standard/StandardModal';

const BannedModal = React.memo((props) => {

  return (
    <StandardModal
      modalKey='bannedModal'
      open
      {...props}
      title={({ id }) => id ? 'Edit Banned Email' : 'Add New Banned Email'}
      buttonLabel='Submit'
      fields={[
        {
          key: 'email',
          label: 'Email',
          placeholder: 'Enter Email Address',
          type: 'text',
          regexp: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i,
          required: true
        },
        {
          key: 'banReason',
          label: 'Ban Reason',
          placeholder: 'Enter Ban Reason',
          type: 'text',
          large: true,
        },
      ]}
    />
  );
})

export default BannedModal;
