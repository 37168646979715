import { useEffect, useCallback } from 'react'
import { getProjectsPromise } from '../services/projectsService'
import { useSetRecoilState } from 'recoil';
import { projectState } from './atoms'
import { useOktaAuth } from '@okta/okta-react'
import * as workerTimers from 'worker-timers';

// const isEqual = require('lodash/isEqual');
// const differenceWith = require('lodash/differenceWith');

export default function ProjectsSubscription({ refresh, onLoaded }) {
    const setProjects = useSetRecoilState(projectState)
    const { oktaAuth } = useOktaAuth();
    const loaded = useCallback(onLoaded ? onLoaded : () => { }, [onLoaded])
    // const [sessions, setProjects] = useRecoilState(sessionState);
    // const sessionsRef = useRef(sessions);
    // const [error, setError] = useState(null)

    useEffect(() => {
        // const subscription = getProjects(oktaAuth.getAccessToken()).subscribe(setProjects, (err) => {
        //     console.log("ERROR", err)
        //     setError(err)
        // })

        let syncInterval;
        const sync = () => getProjectsPromise(oktaAuth.getAccessToken()).then(setProjects).then(loaded).catch((error) => {
            if (error.message === 'Unauthorized') {
                oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
            } else {
                throw error
            }
        })

        sync()

        // sync().then(() => {
        //     syncInterval = workerTimers.setInterval(sync, 300000)
        // })

        // const syncInterval = workerTimers.setInterval(() => {
        //     getProjectsPromise(oktaAuth.getAccessToken()).then(setProjects)
        // }, 5000)

        return function cleanup() {
            if (syncInterval) workerTimers.clearInterval(syncInterval)
            // subscription.unsubscribe()
        };
    }, [oktaAuth, refresh, loaded, setProjects]);

    return null;
}