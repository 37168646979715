import React, { useCallback } from 'react';
import StandardModal from './standard/StandardModal';

const EmailTemplateModal = React.memo(({ opts = {}, ...props }) => {

  const disabledEmailsFn = useCallback((px, option) => !!opts[option.code], [opts])

  return (
    <StandardModal
      modalKey='emailTemplateModal'
      open
      {...props}
      title={(x) => x?.id ? 'Edit Email Template' : 'Add Email Template'}
      buttonLabel='Submit'
      fields={[
        [
          {
            key: 'name',
            label: 'Template Name',
            placeholder: 'Enter Template Name',
            type: 'text',
            required: true
          }
        ],
        [
          {
            key: 'subject',
            label: 'Email Subject',
            placeholder: 'Enter Email Subject Line',
            type: 'text',
            required: true
          },
          {
            key: 'emailTitle',
            label: 'Email Title',
            placeholder: 'Enter Email Title',
            type: 'text',
            required: true
          },
          {
            key: 'content',
            label: 'Email Content',
            placeholder: 'Enter Email Content',
            type: 'rich-text'
          },
          {
            key: 'includeLogo',
            label: 'Include Logo Image',
            type: 'boolean',
            defaultValue: true
          },
          {
            key: 'includeTitle',
            label: 'Include Event Title',
            type: 'boolean',
            defaultValue: true
          },
          {
            key: 'includeTime',
            label: 'Include Event Date/Time',
            type: 'boolean',
            defaultValue: true
          },
        ]
      ]}
    />
  );
})

export default EmailTemplateModal;
