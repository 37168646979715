import { useEffect, useCallback, useRef } from 'react'
import { getAllGuests } from '../services/guestsService'
import { useSetRecoilState } from 'recoil';
import { guestsForSession } from './atoms'
import { useOktaAuth } from '@okta/okta-react'
import * as workerTimers from 'worker-timers';

// const isEqual = require('lodash/isEqual');
// const differenceWith = require('lodash/differenceWith');

export default function GuestsSubscription({ sessionId, refresh, onLoaded }) {
    const setSessionGuests = useSetRecoilState(guestsForSession(sessionId))
    const { oktaAuth } = useOktaAuth();
    const loaded = useCallback(onLoaded ? onLoaded : () => { }, [onLoaded])

    const running = useRef(false)
    const repeat = useRef(false)

    useEffect(() => {

        let syncInterval;
        const sync = () => {
            if (!running.current) {
                running.current = true
                return getAllGuests({ sessionId, includeDeleted: false }, oktaAuth.getAccessToken()).then(setSessionGuests).then(() => {
                    running.current = false
                    if (repeat.current) {
                        repeat.current = false
                        sync()
                    } else {
                        loaded()
                    }
                }).catch((error) => {
                    running.current = false
                    if (error.message === 'Unauthorized') {
                        oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
                    } else {
                        throw error
                    }
                })
            } else {
                repeat.current = true
            }
        }

        sync()

        // sync().then(() => {
        //     syncInterval = workerTimers.setInterval(sync, 5000)
        // })

        return function cleanup() {
            if (syncInterval) workerTimers.clearInterval(syncInterval)
            // subscription.unsubscribe()
        };
    }, [oktaAuth, sessionId, refresh, loaded, setSessionGuests]);

    return null;
}