import React, { useRef, useEffect } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/css/okta-sign-in.min.css';

import seasiLogo from '../../resources/seasi-logo.jpg'

const OktaSignInWidget = React.memo(({ baseUrl, onError, onSuccess }) => {

  const el = useRef()

  useEffect(() => {
    const widget = new OktaSignIn({
      baseUrl,
      authParams: {
        pkce: false,
      },
      logo: seasiLogo,
      i18n: {
        en: {
          'primaryauth.title': 'Please Login to Continue',
        }
      }
    });
    widget.renderEl({ el: el.current }, onSuccess, onError);
    return () => {
      widget.remove();
    }
  }, [baseUrl, onSuccess, onError])

  return <div ref={el} />

})

export default OktaSignInWidget