import React, { useState, useCallback, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles';
import { Drawer } from '@mui/material';
import ChimeChat from './ChimeChat';

const PREFIX = 'ChatDrawer';

const classes = {
    drawer: `${PREFIX}-drawer`,
    drawerPaper: `${PREFIX}-drawerPaper`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
    [`& .${classes.drawer}`]: {
        width: 'calc(200px + (650 - 200) * ((100vw - 100px) / (2400 - 100)))',
        flexShrink: 0,
    },
    [`& .${classes.drawerPaper}`]: {
        width: 'calc(200px + (650 - 200) * ((100vw - 100px) / (2400 - 100)))',
    },
    // toastBackgroundColor: {
    //     backgroundColor: '#000000'
    // },
    // toastTextColor: {
    //     color: '#000000'
    // },
    // toastIconColor: {
    //     color: '#000000 !important'
    // }
});

const ChatDrawer = React.memo(({ open = false, ...props }) => {


    const [alertOpen, setAlertOpen] = useState(false)
    const alertOpenRef = useRef(false)

    useEffect(() => {
        alertOpenRef.current = alertOpen
    }, [alertOpen])

    return (
        (<Root>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <ChimeChat visible={open} {...props} />
            </Drawer>
        </Root>)
    );

})

export default (ChatDrawer)