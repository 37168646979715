export const techCheckMode = ({ state, bypassTechCheck, ...guest }, auto, bypass) => {
  if (state !== "Qualified") {
    if (state === "Registered") {
      return (bypass || bypassTechCheck) ? 'none' : auto && !guest.acuityAppointmentId ? 'self' : 'guided'
    } if (state === "Tech-Check") {
      return guest.techCheckBy === "VSR (AUTO)" ? 'self' : 'guided'
    } else {
      return guest.completedTC ? guest.completedSG ? 'self' : 'guided' : 'none'
    }
  } else {
    return 'none'
  }
}