import React from 'react';
import { SwipeableDrawer, Divider, List, ListSubheader, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useLocation, Link } from 'react-router-dom';

const StandardDrawer = React.memo(({ headerComponent: HeaderComponent, links, linksLabel, linkGroups, divideLinkGroups, ...restProps }) => {
    // console.log('drawer render', props)
    const location = useLocation()

    return (
        <SwipeableDrawer anchor='left' {...restProps}>
            <div>
                {HeaderComponent &&
                    <>
                        <HeaderComponent />
                        <Divider />
                    </>}
                {
                    linkGroups && linkGroups.map((group, i) =>
                        <div key={`linkgroup${i}`}>
                            <List
                                subheader={group.label &&
                                    <ListSubheader component="div">
                                        {group.label}
                                    </ListSubheader>
                                }
                            >
                                {group.links && group.links.map((groupLink, j) => {
                                    const IconComponent = groupLink.icon
                                    return (
                                        <div key={`linkgroup${i}item${j}`}>
                                            <ListItem button component={!groupLink.action ? Link : undefined} onClick={groupLink.action} to={groupLink.to} selected={(groupLink.to && !groupLink.disableHighlight) && (location.pathname === (groupLink.to.pathname || groupLink.to) || (groupLink.defaultPath && location.pathname === '/'))} key={groupLink.label}>
                                                {groupLink.icon && <ListItemIcon><IconComponent /></ListItemIcon>}
                                                <ListItemText primary={groupLink.label} />
                                            </ListItem>
                                            {groupLink.includeDivider && <Divider />}
                                        </div>
                                    )
                                })}
                            </List>
                            {divideLinkGroups && <Divider />}
                        </div>
                    )
                }
                {links && <List key='linkslist'
                    subheader={linksLabel &&
                        <ListSubheader component="div">
                            {linksLabel}
                        </ListSubheader>
                    }
                >
                    {links.map((link, i) => {
                        const IconComponent = link.icon
                        return (
                            <>
                                <ListItem key={`linkitem${i}`} button component={!link.action ? Link : undefined} onClick={link.action} to={link.to} selected={(link.to && !link.disableHighlight) && (location.pathname === (link.to.pathname || link.to) || (link.defaultPath && location.pathname === '/'))}>
                                    {link.icon && <ListItemIcon><IconComponent /></ListItemIcon>}
                                    <ListItemText primary={link.label} />
                                </ListItem>
                                {link.includeDivider && <Divider />}
                            </>
                        )
                    })}
                </List>}
            </div>
        </SwipeableDrawer >
    );

})

export default (StandardDrawer);
