import config from '../config.json'
// import worker from 'workerize-loader?inline!../utils/fetchWorker' // eslint-disable-line import/no-webpack-loader-syntax
import { wrap } from '../promise-worker.ts'

const initFetchWorker = async () => {
    return await wrap(new Worker(new URL('../utils/fetchWorker', import.meta.url)))
}

export const getBannedPromise = ({ includeDeleted, offset }, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/banned?offset=${offset || 0}&includeDeleted=${!!includeDeleted}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data //data.records || data.Items
    })

export const getAllBanned = async ({ includeDeleted = false, offset = 0 }, token) => {
    const fetchWorker = await initFetchWorker()
    const data =  await fetchWorker.threadedFetch(`https://${config.rest.sessionManagementAPI}/banned`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        params: { includeDeleted, offset }
    }, true)
    fetchWorker.terminate()
    return data
}


export const deleteBannedPromise = (id, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/banned`, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ id })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data;
    })


export const insertBannedPromise = (ban, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/banned`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(ban)

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const updateBannedPromise = (ban, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/banned`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(ban)

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

// export const uploadBannedCSVPromse = (file, sessionId, token) =>
//     fetch(`https://${config.rest.sessionManagementAPI}/guest-csv`, {
//         method: 'POST',
//         mode: 'cors',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//             'Authorization': 'Bearer ' + token
//         },
//         body: JSON.stringify({
//             filename: file.name,
//             filetype: file.type,
//             sessionId
//         })

//     }).then(response => {
//         if (response.status >= 200 && response.status <= 299) {
//             return response.json();
//         } else {
//             return response.json().then(res => {
//                 throw res
//             })
//         }
//     }).then(signedResponseData => {
//         const formData = new FormData();
//         Object.entries(signedResponseData.fields).forEach(([key, value]) => {
//             formData.append(key, value);
//         });
//         console.log(file)
//         formData.append("file", file.src.file);
//         return fetch(signedResponseData.url, {
//             method: 'POST',
//             mode: 'cors',
//             body: formData

//         })
//     }).then(response => {
//         if (response.status >= 200 && response.status <= 299) {
//             return response
//         } else {
//             throw response
//         }
//     })