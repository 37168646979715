import { useEffect, useCallback } from 'react'
import { getAllBanned } from '../services/bannedService'
import { useSetRecoilState } from 'recoil';
import { bannedList } from './atoms'
import { useOktaAuth } from '@okta/okta-react'
import * as workerTimers from 'worker-timers';

export default function BannedSubscription({ refresh, onLoaded }) {
    const setBanned = useSetRecoilState(bannedList)
    const { oktaAuth } = useOktaAuth();
    const loaded = useCallback(onLoaded ? onLoaded : () => { }, [onLoaded])

    useEffect(() => {

        let syncInterval;
        const sync = () => getAllBanned({ includeDeleted: false }, oktaAuth.getAccessToken()).then(setBanned).then(loaded).catch((error) => {
            if (error.message === 'Unauthorized') {
                oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
            } else {
                throw error
            }
        })

        sync()

        // sync().then(() => {
        //     syncInterval = workerTimers.setInterval(sync, 300000)
        // })

        // const syncInterval = workerTimers.setInterval(() => {
        //     getSessionsPromise(oktaAuth.getAccessToken()).then(setBanned)
        // }, 5000)

        return function cleanup() {
            if (syncInterval) workerTimers.clearInterval(syncInterval)
        };
    }, [oktaAuth, refresh, loaded, setBanned]);

    return null;
}