import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, RadioGroup, Radio, FormControlLabel } from '@mui/material';
const PREFIX = 'RadioInputField';

const classes = {
  root: `${PREFIX}-root`,
  label: `${PREFIX}-label`,
  radio: `${PREFIX}-radio`
};

const Root = styled('div')({
  //border: 'solid 2px green',
  display: 'flex',
  padding: '5px',
  alignItems: 'center',
  [`& .${classes.label}`]: {
    flexBasis: '20%',
    // minWidth: '25%',
    textAlign: 'right',
    marginRight: '10px'
  },
  [`& .${classes.radio}`]: {
    flexDirection: 'row'
  }
});

const RadioInputField = React.memo(({ value, label, onChange, options }) => {


  const handleChange = useCallback(e => {
    onChange(null, e.target.value)
  }, [onChange]);

  return (
    <Root>
      <Typography className={classes.label}>{label}</Typography>
      <RadioGroup value={value} onChange={handleChange} className={classes.radio} >
        {options.map(radio => <FormControlLabel value={radio.code} control={<Radio color="primary" />} label={radio.label} />)}
      </RadioGroup>
    </Root>
  );
})

export default RadioInputField;
