import config from '../config.json'
const { XMLBuilder } = require('fast-xml-parser');

const options = {
    attributeNamePrefix: "@",
    // commentPropName: "note",
    ignoreAttributes: false,
    htmlEntities: false,
    processEntities: false,
    suppressEmptyNode: true,
    format: true,
    preserveOrder: true,
};

const builder = new XMLBuilder(options);

export const exportDecipherScript = (segments = [], placement, playlistLength = 1) => {

    const completeUrl = config.vsrUrl + (placement === 'pre' ? "/session/${encryptedsessionid}/${prerequestid}?id=${jwt}&amp;state=${uuid}" : "/complete?sessionid="+ (placement === 'post' ? "${sessionid}" : "${postSessionId.val}") + "&amp;id=${id}&amp;requestid=" + (placement === 'post' ? "${postrequestid}" : "${postRequestId.val}"))

    const scriptReturnLink = [
        {
            "note": [
                {
                    "#text": "****** :: VSR - Final Return Link Section :: ******"
                }
            ]
        },
        {
            "samplesource": [
                {
                    "title": [
                        {
                            "#text": "Virtual Screening Room - Integration Link"
                        }
                    ]
                },
                {
                    "invalid": [
                        {
                            "#text": "You are missing information in the URL. Please verify the URL with the original invite."
                        }
                    ]
                },
                {
                    "completed": [
                        {
                            "#text": "It seems you have already entered this survey."
                        }
                    ]
                },
                {
                    "var": [],
                    ":@": {
                        "@name": "encryptedsessionid"
                    }
                },
                {
                    "var": [],
                    ":@": {
                        "@name": "jwt"
                    }
                },
                {
                    "var": [],
                    ":@": {
                        "@name": "id",
                        "@unique": "1"
                    }
                },
                ...(placement === "post" ? [{
                    "var": [],
                    ":@": {
                        "@name": "sessionid"
                    }
                },
                {
                    "var": [],
                    ":@": {
                        "@name": "postrequestid",
                        "@unique": "1"
                    }
                }] : [{
                    "var": [],
                    ":@": {
                        "@name": "prerequestid",
                        "@unique": "1"
                    }
                }]),
                // {
                //     "var": [],
                //     ":@": {
                //         "@name": "sub",
                //         "@unique": "1"
                //     }
                // },
                {
                    "exit": [],
                    ":@": {
                        "@cond": "terminated",
                        "@url": completeUrl
                    }
                },
                {
                    "exit": [],
                    ":@": {
                        "@cond": "overquota",
                        "@url": completeUrl
                    }
                },
                {
                    "exit": [],
                    ":@": {
                        "@cond": "qualified",
                        "@url": completeUrl
                    }
                }
            ],
            ":@": {
                "@list": "1"
            }
        }
    ]

    const externalVars = !['pre', 'post'].includes(placement) ? [
        // {
        //     "html": [
        //         {
        //             "#text": "Welcome back."
        //         }
        //     ],
        //     ":@": {
        //         "@label": "cm2",
        //         "@where": "none"
        //     }
        // },
        // {
        //     "note": [
        //         {
        //             "#text": "****** :: VSR - Stores VSR Return Auth Data :: ******"
        //         }
        //     ]
        // },
        // {
        //     "exec": [
        //         {
        //             "#text": "postSessionId1.val = gv.request.get(\"sessionid\")\npostRequestId1.val = gv.request.get(\"postrequestid\")"
        //         }
        //     ],
        //     // ":@": {
        //     //     "@when": "returning"
        //     // }
        // },
        // {
        //     "text": [
        //         {
        //             "title": [
        //                 {
        //                     "#text": "Stores Post Content Session ID"
        //                 }
        //             ]
        //         },
        //         {
        //             "comment": [
        //                 {
        //                     "#text": "Be specific"
        //                 }
        //             ]
        //         }
        //     ],
        //     ":@": {
        //         "@label": "postSessionId1",
        //         "@optional": "0",
        //         "@size": "25",
        //         "@where": "execute,survey,report"
        //     }
        // },
        // {
        //     "text": [
        //         {
        //             "title": [
        //                 {
        //                     "#text": "Stores Post Content Request ID"
        //                 }
        //             ]
        //         },
        //         {
        //             "comment": [
        //                 {
        //                     "#text": "Be specific"
        //                 }
        //             ]
        //         }
        //     ],
        //     ":@": {
        //         "@label": "postRequestId1",
        //         "@optional": "0",
        //         "@size": "25",
        //         "@where": "execute,survey,report"
        //     }
        // },
        // {
        //     "exec": [
        //         {
        //             "#text": "postSessionId.val=postSessionId1.val\npostRequestId.val=postRequestId1.val"
        //         }
        //     ]
        // },
        {
            "text": [
                {
                    "title": [
                        {
                            "#text": "Stores Post Content Session ID"
                        }
                    ]
                },
                {
                    "comment": [
                        {
                            "#text": "Be specific"
                        }
                    ]
                }
            ],
            ":@": {
                "@label": "postSessionId",
                "@optional": "0",
                "@size": "25",
                "@where": "execute,survey,report"
            }
        },
        {
            "text": [
                {
                    "title": [
                        {
                            "#text": "Stores Post Content Request ID"
                        }
                    ]
                },
                {
                    "comment": [
                        {
                            "#text": "Be specific"
                        }
                    ]
                }
            ],
            ":@": {
                "@label": "postRequestId",
                "@optional": "0",
                "@size": "25",
                "@where": "execute,survey,report"
            }
        },
        {
            "exec": [
                {
                    "#text": "postSessionId.val = gv.request.get(\"sessionid\")\npostRequestId.val = gv.request.get(\"postrequestid\")"
                }
            ],
            ":@": {
                "@when": "started"
            }   
        },
        {
            "exec": [
                {
                    "#text": "if gv.request.get(\"sessionid\") is not None:\n\tpostSessionId.val = gv.request.get(\"sessionid\")\nif gv.request.get(\"postrequestid\") is not None:\n\tpostRequestId.val = gv.request.get(\"postrequestid\")"
                }
            ],
            ":@": {
                "@when": "returning"
            }   
        },
        // {
        //     "suspend": []
        // }
    ] : []

    const scriptAPICall = [
        {
            "note": [
                {
                    "#text": "****** :: VSR - API Call to Fetch Segment Data :: ******"
                }
            ]
        },
        {
            "exec": [
                {
                    "#text": "vsr_respondent_id = dict({\"guestId\": id, \"sessionId\" : encryptedsessionid})\nvsr_jwt = dict({\"Authorization\": \"Bearer \" + jwt})\nvsr_segment_data = dict()"
                }
            ]
        },
        {
            "logic": [
                {
                    "title": [
                        {
                            "#text": "API Integration"
                        }
                    ]
                }
            ],
            ":@": {
                "@label": "apiVSRSegment",
                "@api:data": "vsr_segment_data",
                "@api:headers": "vsr_jwt",
                "@api:params": "vsr_respondent_id",
                "@api:url": "https://" + config.rest.sessionGuestAPI + "/segments",
                "@uses": "api.1"
            }
        },
        {
            "suspend": []
        }
    ]

    const segmentMap = {}

    const scriptSegments = [
        {
            "note": [
                {
                    "#text": "****** :: VSR - Segment Hidden Question Setup Section :: ******"
                }
            ]
        },
        ...segments.map((segment, i) => {
            segmentMap[segment.questionKey] = `VSR${i}`
            const questionField = {
                [segment.freeNumeric ? "number" : (segment.multiple || segment.dataKey === 'segments') ? "checkbox" : "radio"]: [
                    {
                        "title": [
                            {
                                "#text": segment.dataKey === 'segments' ? `${segment.questionKey} (Virtual Segment)` : segment.question
                            }
                        ]
                    },
                    {
                        "comment": [
                            {
                                "#text": segment.freeNumeric ? "Enter a number" : (segment.multiple || segment.dataKey === 'segments') ? "Select all that apply" : segment.matrix ? "Select one answer per option" : "Select one"
                            }
                        ]
                    },
                    ...(segment.matrix ? segment.answers.map((x, i) => ({
                        "col": [
                            {
                                "#text": x.answer
                            }
                        ],
                        ":@": {
                            "@label": `c${i}`
                        }
                    })) : []),
                    ...(segment.freeNumeric ? [] :
                        segment.matrix ?
                            segment.matrixKeys.map((x, i) => ({
                                "row": [
                                    {
                                        "#text": x.option + (x.display ? ` ${x.display}` : '')
                                    }
                                ],
                                ":@": {
                                    "@label": `r${i}`
                                }
                            })) : segment.answers.map((x, i) => ({
                                "row": [
                                    {
                                        "#text": x.answer + (x.display ? ` ${x.display}` : '')
                                    }
                                ],
                                ":@": {
                                    "@label": `r${i}`
                                }
                            }))
                    )
                ],
                ":@": {
                    // "@label": `${segment.questionKey}`,
                    "@label": segmentMap[segment.questionKey],
                    // "@optional": "1",
                    // "@size": "10",
                    // "@shuffle": "rows",
                    [segment.freeNumeric ? "@size" : "@shuffle"]: segment.freeNumeric ? "10" : "rows",
                    "@where": "execute,survey,report"
                }
            }

            return [
                questionField,
                {
                    "suspend": []
                }
            ]
        }).flat()
    ]

    const scriptMapping = {
        "exec": [
            {
                "#text": 'segmentMap = ' + JSON.stringify(segmentMap) + '\n\nif apiVSRSegment.status == 200:\n\tprint "API Succeeded"\n\tsegments = apiVSRSegment.r["segments"]\n\tfor key in segments:\n\t\tsegmentKey = segmentMap.get(key)\n\t\tif segments.get(key) != None and segmentKey != None:\n\t\t\tif callable(getattr(segments.get(key), "append", None)):\n\t\t\t\tfor ans in allQuestions[segmentKey].rows:\n\t\t\t\t\tfor sval in segments.get(key):\n\t\t\t\t\t\tif re.search("^" + re.escape(sval), ans.text.decode(\'utf-8\')):\n\t\t\t\t\t\t\tans.val = 1\n\t\t\telif callable(getattr(segments.get(key), "keys", None)):\n\t\t\t\tsegObj = segments.get(key)\n\t\t\t\tfor opt in allQuestions[segmentKey].rows:\n\t\t\t\t\tfor idx, ans in enumerate(allQuestions[segmentKey].cols):\n\t\t\t\t\t\tmatch = next((x for x in segObj.keys() if re.search("^" + re.escape(x), opt.text.decode(\'utf-8\'))), None)\n\t\t\t\t\t\tif match:\n\t\t\t\t\t\t\tif re.search("^" + re.escape(segObj.get(match)), ans.text.decode(\'utf-8\')):\n\t\t\t\t\t\t\t\topt.val = idx\n\t\t\telse:\n\t\t\t\tif len(allQuestions[segmentKey].rows) == 1 and str(segments.get(key)).isdigit():\n\t\t\t\t\tallQuestions[segmentKey].val = segments.get(key)\n\t\t\t\telse:\n\t\t\t\t\tfor ans in allQuestions[segmentKey].rows:\n\t\t\t\t\t\tif re.search("^" + re.escape(segments.get(key)), ans.text.decode(\'utf-8\')):\n\t\t\t\t\t\t\tallQuestions[segmentKey].val = ans.index\nelse:\n\tprint "API Failed"'
            }
        ]
    }

    const preContentReturn = ['post', 'both', 'all'].includes(placement) ? [
        {
            "note": [
                {
                    "#text": `****** :: VSR - ${placement === 'post' ? "Content" : "Pre-Content"} Survey Return Section :: ******`
                }
            ]
        },
        {
            "goto": [],
            ":@": {
                "@cond": "vsrskip1=='1'",
                "@target": "Q1"
            }
        },
        ...(placement !== 'post' ? [{
            "suspend": []
        },
        {
            "exec": [
                {
                    "#text": "suspendExternal(\"https://" + config.vsrUrl + "/session/\" + encryptedsessionid + \"/\" + prerequestid + \"?id=\" + jwt + \"&amp;state=[state]\")"
                }
            ],
            ":@": {
                "@sst": "0"
            }
        }] : [])
    ] : []

    const surveyLength = ['inter', 'all'].includes(placement) ? playlistLength : 1

    const postContent = placement !== 'pre' ? [...Array(surveyLength)].map((x, i) => ([
        {
            "note": [
                {
                    "#text": `****** :: VSR - RETURNING for ${surveyLength > i + 1 ? `Intermission Survey - Playlist Item ${i + 1}` : "Post-Content Survey"} :: ******`
                }
            ]
        },
        ...(surveyLength > i + 1 ? [{
            "html": [
                {
                    "#text": `After viewing Playlist Item ${i + 1}`
                }
            ],
            ":@": {
                "@label": `return_ep${i + 1}`,
                "@where": "none"
            }
        }] : []),
        // ...(placement !== 'post' ? [{
        //     "exec": [
        //         {
        //             "#text": "postSessionId.val = gv.request.get(\"sessionid\")\npostRequestId.val = gv.request.get(\"postrequestid\")"
        //         }
        //     ],
        //     ":@": {
        //         "@when": placement === 'inter' && i === 0 ? "started" : "returning"
        //     }   
        // }] : []),
        // {
        //     "exec": [
        //         {
        //             "#text": "postSessionId.val=postSessionId1.val\npostRequestId.val=postRequestId1.val"
        //         }
        //     ]
        // },
        {
            "suspend": []
        },
        {
            "note": [
                {
                    "#text": `****** :: VSR - START ${surveyLength > i + 1 ? `Intermission Questions ${i + 1}` : "Post-Content Questions"} :: ******`
                }
            ]
        },
        {
            "note": [
                {
                    "#text": `****** :: VSR - END ${surveyLength > i + 1 ? `Intermission Questions ${i + 1}` : "Post-Content Questions"} :: ******`
                }
            ]
        },
        {
            "suspend": []
        },
        ...(surveyLength > i + 1 ? [{
            "note": [
                {
                    "#text": `****** :: VSR - RETURNING to Next Playlist Item ${i + 2} :: ******`
                }
            ]
        },
        {
            "exec": [
                {
                    "#text": "suspendExternal(\"https://" + config.vsrUrl + "/session/\" + encryptedsessionid + \"/\" + postRequestId.val + \"?id=\" + jwt + \"&amp;state=[state]\")"
                }
            ],
            ":@": {
                "@sst": "0"
            }
        }] : [])
    ])).flat() : []

    const surveyBlocks = [
        ...scriptReturnLink,
        ...externalVars,
        ...scriptAPICall,
        scriptMapping,
        ...scriptSegments,
        ...preContentReturn,
        ...postContent
    ]

    return {
        part: builder.build(surveyBlocks),
        full: builder.build([
            {
                "?xml": [
                    {
                        "#text": ""
                    }
                ],
                ":@": {
                    "@version": "1.0",
                    "@encoding": "UTF-8"
                }
            },
            {
                "survey": surveyBlocks,
                ":@": {
                    "@alt": "",
                    "@autosave": "0",
                    "@autosaveKey": "rid",
                    "@builder:cname": "survey.everyonesez.com",
                    "@builder:wizardCompleted": "1",
                    "@builderCompatible": "1",
                    "@compat": "144",
                    "@delphi": "1",
                    "@extraVariables": "source,record,decLang,list,userAgent,vsrskip1",
                    "@fir": "on",
                    "@html:showNumber": "0",
                    "@mobile": "compat",
                    "@mobileDevices": "smartphone,tablet,desktop",
                    "@name": "Survey",
                    "@secure": "1",
                    "@setup": "term,decLang,quota,time",
                    "@ss:disableBackButton": "1",
                    "@ss:enableNavigation": "1",
                    "@ss:hideProgressBar": "0",
                    "@state": "testing"
                }
            }
        ])
    }
}