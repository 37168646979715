import React from 'react'
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import vwxLogo from '../resources/vwx-logo.svg'

const PREFIX = 'DrawerLogo';

const classes = {
    logo: `${PREFIX}-logo`,
    logoSvg: `${PREFIX}-logoSvg`,
    logoFont: `${PREFIX}-logoFont`,
    logoFontBold: `${PREFIX}-logoFontBold`,
    sub: `${PREFIX}-sub`
};

const Root = styled('div')({
    // style for <Root> component
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // padding: '1rem',
    textAlign: 'center',
    [`& .${classes.logoSvg}`]: {
        height: '65px',
        width: '260px',
        transform: 'scale(1)'
    },
    [`& .${classes.logoFont}`]: {
        fontFamily: `'Yanone Kaffeesatz', sans-serif`,
        // fontFamily: `'BenchNine', sans-serif`,
        // fontWeight: 'light',
        fontWeight: 300,
        letterSpacing: '0.07em',
        lineHeight: 1
    },
    [`& .${classes.logoFontBold}`]: {
        fontFamily: `'Yanone Kaffeesatz', sans-serif`,
        // fontFamily: `'BenchNine', sans-serif`,
        // fontWeight: 'light',
        fontWeight: 700,
        letterSpacing: '0.07em',
        lineHeight: 1
    },
    [`& .${classes.sub}`]: {
        lineHeight: 1,
        padding: '0.75em'
    }
});

const DrawerLogo = React.memo(() => {


    return (
        <Root>
            {/* <Typography variant="h2" className={classes.logoFont}><strong className={classes.logoFontBold}>Live</strong>Trac</Typography> */}
            {/* <Typography variant="h2" className={classes.logoFont}><strong className={classes.logoFontBold}>Virtu</strong>Works</Typography> */}
            <img src={vwxLogo} className={classes.logoSvg} />
            <Typography variant="button" className={classes.sub}>Session Management Console</Typography>
        </Root>
    );

})

export default (DrawerLogo)