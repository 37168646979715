import React, { useRef, useCallback, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Collapse, IconButton, Checkbox, Typography, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { useDrag } from 'react-dnd'
import SelectInputField from './SelectInputField'
import NumberInputField from './NumberInputField';
import StandardField from './StandardField';

const PREFIX = 'ChartSegmentField';

const classes = {
  root: `${PREFIX}-root`,
  box: `${PREFIX}-box`,
  addbutton: `${PREFIX}-addbutton`,
  answers: `${PREFIX}-answers`,
  checkbox: `${PREFIX}-checkbox`,
  checkLabel: `${PREFIX}-checkLabel`,
  label: `${PREFIX}-label`,
  questionLabel: `${PREFIX}-questionLabel`,
  questionField: `${PREFIX}-questionField`,
  answerLabel: `${PREFIX}-answerLabel`,
  answerRow: `${PREFIX}-answerRow`,
  textField: `${PREFIX}-textField`,
  timeField: `${PREFIX}-timeField`,
  colon: `${PREFIX}-colon`,
  plus: `${PREFIX}-plus`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: '6px',
    margin: '0 8px 0 8px',
    border: '2px solid #eee',
    borderRadius: '8px',
    transform: 'translate3d(0, 0, 0)'
  },
  [`& .${classes.box}`]: {
    display: 'flex',
    padding: '4px',
    alignItems: 'center',
  },
  [`& .${classes.addbutton}`]: {
    display: 'flex',
    padding: '6px',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  [`& .${classes.answers}`]: {
    padding: '4px',
    width: '100%',
    borderLeft: '2px solid #eee'
  },
  [`& .${classes.checkbox}`]: {
    flexBasis: '20%',
    textAlign: 'right',
    marginRight: '10px'
  },
  [`& .${classes.checkLabel}`]: {
    width: '100%'
  },
  [`& .${classes.label}`]: {
    textAlign: 'right',
    marginRight: '10px !important'
  },
  [`& .${classes.questionLabel}`]: {
    flexBasis: '30%',
    textAlign: 'right',
    marginRight: '10px !important',
  },
  [`& .${classes.questionField}`]: {
    flexBasis: '70%'
  },
  [`& .${classes.answerLabel}`]: {
    flexBasis: '10%',
    textAlign: 'right',
    marginRight: '10px !important',
    padding: '5px',
  },
  [`& .${classes.answerRow}`]: {
    display: 'flex',
    alignItems: 'center'
  },
  [`& .${classes.textField}`]: {
    width: '80%',
    padding: '4px'
  },
  [`& .${classes.timeField}`]: {
    // width: '3.5em',
    width: '20%',
    padding: '4px'
  },
  [`& .${classes.colon}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px'
  },
  [`& .${classes.plus}`]: {
    margin: '2px'
  }
});

const cloneDeep = require('lodash/cloneDeep');
// const xor = require('lodash/xor');
const xorBy = require('lodash/xorBy');

const ChartSegmentField = React.memo(({ value, label, disabled, placeholder, fieldKey, onChange, forChart, virtual, predefined, showGoals, invalid, invalidate, questionPlaceholder, answerPlaceholder, segmentKeys, segmentOptions, onRemove, draggable, index }) => {

  const valueRef = useRef();
  valueRef.current = value

  const [{ isDragging }, drag] = useDrag({
    type: 'question',
    item: { index },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  const invalidAnswers = useMemo(() => {
    return new Set(value.answers.map(x => x.answer).filter((s => v => s.has(v) || !s.add(v))(new Set())))
  }, [value])

  const handleCollapse = useCallback((event) => {
    const newQuestion = { ...valueRef.current }
    newQuestion.collapsed = !(!!valueRef.current.collapsed)
    onChange(event, newQuestion)
  }, [onChange]);

  const handleKeyChange = useCallback((event, newValue, isVirtual) => {
    const newQuestion = { ...valueRef.current }
    newQuestion.questionKey = newValue || (event?.target?.value || "").replace(/[:\.]/g, '').replace(/\s{2,}/g, ' ').replace(/^\s*/, '')
    if (isVirtual) {
      newQuestion.virtual = isVirtual
      newQuestion.dataKey = 'segments'
      // newQuestion.answers = [{ conditions: [{}] }] // *removed to avoid answers getting reset whenever questionKey was changed
    }
    onChange(event, newQuestion)
  }, [onChange]);

  const handleSubKeyChange = useCallback((event, newValue, fieldData) => {
    const newQuestion = { ...valueRef.current }
    newQuestion.dataSubKey = newValue || event.target.value.replace(/[:\.]/g, '').replace(/\s{2,}/g, ' ').replace(/^\s*/, '')
    onChange(event, newQuestion)
  }, [onChange]);

  const handleCrosstabChange = useCallback((event) => {
    event.currentTarget.blur()
    const newQuestion = { ...valueRef.current }
    newQuestion.hasCrosstab = event.target.checked
    onChange(event, newQuestion)
  }, [onChange]);

  const handleCrosstabKeyChange = useCallback((event, newValue) => {
    const newQuestion = { ...valueRef.current }
    newQuestion.crosstabKeys = newValue
    onChange(event, newQuestion)
  }, [onChange]);

  const handleAddAnswer = useCallback((event) => {
    const newQuestion = { ...valueRef.current }
    newQuestion.answers = cloneDeep(valueRef.current.answers)
    newQuestion.answers.push({ conditions: [{}], showOnChart: true })
    onChange(event, newQuestion)
  }, [onChange])

  const handleRemoveAnswer = useCallback((event, i) => {
    const newQuestion = { ...valueRef.current }
    newQuestion.answers = cloneDeep(valueRef.current.answers)
    newQuestion.answers.splice(i, 1)
    onChange(event, newQuestion)
  }, [onChange])

  const handleAnswerChange = useCallback((event, i) => {
    const newQuestion = { ...valueRef.current }
    newQuestion.answers = cloneDeep(valueRef.current.answers)
    newQuestion.answers[i].answer = event.target.value
    // newQuestion.answers[i].conditions = [{}]
    onChange(event, newQuestion)
  }, [onChange])

  const handleCheckAnswer = useCallback((event, i) => {
    const newQuestion = { ...valueRef.current }
    newQuestion.answers = cloneDeep(valueRef.current.answers)
    newQuestion.answers[i].showOnChart = event.target.checked
    onChange(event, newQuestion)
  }, [onChange])

  const handleAnswerBlur = useCallback((event, i, displayText) => {
    const newAnswers = event.target.value.replace(/\s{2,}/g, ' ').replace(/^\s*/, '').replace(/\r\n/g, /\n/).replace(/\r/g, /\n/).split(/\n/).map(ans => {
      const [answer, display] = ans.split('|')
      return { answer, display }
    })
    if (newAnswers.length > 1) {
      const newQuestion = { ...valueRef.current }
      newQuestion.answers = cloneDeep(valueRef.current.answers)
      newQuestion.answers.splice(i, 1, ...newAnswers)
      onChange(event, newQuestion, index)
    }
  }, [onChange, index])

  const handleGoalChange = useCallback((event, i) => {
    const newValue = event.target.value
    if (/[0-9]+/.test(newValue) || !newValue) {
      const newQuestion = { ...valueRef.current }
      newQuestion.answers = cloneDeep(valueRef.current.answers)
      newQuestion.answers[i].goal = newValue
      onChange(event, newQuestion, index)
    }
  }, [onChange, index]);

  const handleAddCondition = useCallback((event, i) => {
    const newQuestion = { ...valueRef.current }
    newQuestion.answers = cloneDeep(valueRef.current.answers)
    const conditionCount = newQuestion.answers[i].conditions?.length;
    const newCondition = cloneDeep(conditionCount ? {
      ...newQuestion.answers[i].conditions[conditionCount - 1],
      answerKey: "",
    } : {});
    newQuestion.answers[i].conditions.push(newCondition);
    onChange(event, newQuestion)
  }, [onChange])

  const handleLogicChange = useCallback((event, newValue, i) => {
    const newQuestion = { ...valueRef.current }
    newQuestion.answers = cloneDeep(valueRef.current.answers)
    newQuestion.answers[i].customLogic = newValue
    onChange(event, newQuestion)
  }, [onChange])

  const handleGroupChange = useCallback((event, newValue, i) => {
    const newQuestion = { ...valueRef.current }
    newQuestion.answers = cloneDeep(valueRef.current.answers)
    newQuestion.answers[i].group = newValue
    onChange(event, newQuestion)
  }, [onChange])

  const handleConditionChange = useCallback((event, newValue, i, j, key) => {
    const newQuestion = { ...valueRef.current }
    newQuestion.answers = cloneDeep(valueRef.current.answers)
    newQuestion.answers[i].conditions[j][key] = newValue
    if (key === "dataSubSey") newQuestion.answers[i].conditions.answerKey = null;
    onChange(event, newQuestion)
  }, [onChange])

  const handleRemoveCondition = useCallback((event, i, j) => {
    const newQuestion = { ...valueRef.current }
    newQuestion.answers = cloneDeep(valueRef.current.answers)
    newQuestion.answers[i].conditions.splice(j, 1)
    onChange(event, newQuestion)
  }, [onChange])

  return (
    <Root>
      <div className={classes.root} ref={drag} style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: draggable ? 'move' : 'auto',
      }}>
        {!!forChart ?
          <SegmentKeyInput key={`${fieldKey}segmentkey`} fieldKey={fieldKey} label={label} placeholder={placeholder} disabled={disabled} virtual={virtual} predefined={predefined} options={segmentKeys} disabledOptions={segmentOptions.filter(x => !!x.key)} questionKey={value.questionKey} handleKeyChange={handleKeyChange} collapsed={value.collapsed} handleCollapse={handleCollapse} onRemove={onRemove} />
          :
          <QuestionKeyInput key={`${fieldKey}questionkey`} fieldKey={fieldKey} label={label} placeholder={placeholder} disabled={disabled} questionKey={value.questionKey} handleKeyChange={handleKeyChange} collapsed={value.collapsed} handleCollapse={handleCollapse} onRemove={onRemove} />
        }
        <Collapse key={`${fieldKey}collapse`} in={!(!!value.collapsed)}>
          {!virtual ?
            <Root>
              <SegmentInput fieldkey={`${fieldKey}segment`} segmentKeys={segmentKeys.filter(x => x.key !== value.questionKey)} placeholder={questionPlaceholder} disabled={disabled} allowCrosstab={!!value.questionKey && !value.virtual} hasCrosstab={value.hasCrosstab} crosstabKeys={value.crosstabKeys} handleCrosstabChange={handleCrosstabChange} handleCrosstabKeyChange={handleCrosstabKeyChange} />
              <div className={classes.box}>
                {!!value.answers.length && <Typography className={classes.answerLabel}>{value.freeNumeric ? 'Segment Groups' : 'Segments'}</Typography>}
                <div className={classes.answers}>
                  {value.answers.map((answer, i) => (
                    <SegmentGroupInput key={`${fieldKey}segmentgroup${i}`} forGroup={!!value.freeNumeric} showOnChart={!!answer.showOnChart} fieldKey={fieldKey} disabled={disabled} answerPlaceholder={answerPlaceholder} answer={answer.answer} i={i} handleAnswerChange={handleAnswerChange} handleCheckAnswer={handleCheckAnswer} handleRemoveAnswer={handleRemoveAnswer} />
                  ))}
                </div>
              </div>
              {!!value.freeNumeric && <div className={classes.addbutton}>
                <Button disabled={disabled} onClick={handleAddAnswer}>
                  <AddIcon className={classes.plus} /> Add Segment Group
                </Button>
              </div>}
            </Root>
            :
            <>
              <div className={classes.box}>
                <Typography className={classes.answerLabel}>{value.freeNumeric ? 'Answer Groups' : 'Answers'}</Typography>
                <div className={classes.answers}>
                  {value.answers.map((answer, i) => (
                    <SegmentAnswerInput key={`${fieldKey}answer${i}`} fieldKey={fieldKey} disabled={disabled || predefined} invalidate={invalidate} answerPlaceholder={answerPlaceholder} answer={answer.answer} group={answer.group} customLogic={answer.customLogic} conditions={answer.conditions} i={i} invalid={invalidAnswers.has(answer.answer)} handleAnswerChange={handleAnswerChange} handleAnswerBlur={handleAnswerBlur} handleAddCondition={handleAddCondition} handleLogicChange={handleLogicChange} handleGroupChange={handleGroupChange} handleConditionChange={handleConditionChange} handleRemoveCondition={handleRemoveCondition} goal={answer.goal} handleGoalChange={handleGoalChange} showGoals={showGoals} handleRemoveAnswer={handleRemoveAnswer} segmentKeys={segmentKeys} />
                  ))}
                </div>
              </div>
              <div className={classes.addbutton}>
                <Button disabled={disabled || predefined} onClick={handleAddAnswer}>
                  <AddIcon className={classes.plus} /> Add Answer
                </Button>
              </div>
            </>
          }
        </Collapse>
      </div>
    </Root>
  );
})

const SegmentKeyInput = React.memo(({ fieldKey, disabled, questionKey, virtual, predefined, dataKey, dataValue, handleKeyChange, collapsed, handleCollapse, label, placeholder, options, disabledOptions, onRemove }) => {
  // console.log('render question key input', questionKey, virtual, predefined)

  const segmentOptions = useMemo(() => {
    const virtualOpts = (virtual && !predefined) ? { key: questionKey, label: 'Virtual Chart Segment' } : { key: "NEW", label: "New Virtual Segment" }
    return [...options.filter(x => x.virtual ? x.predefined || x.dataKey !== 'segments' : true).map(x => (x.label = `${x.key}${(x.virtual && x.predefined) ? " (Virtual Segment)" : ""}`, x)), virtualOpts]
  }, [options, questionKey, virtual, predefined])
  const onKeySelect = useCallback((e, v) => {
    let val = v.key === "NEW" ? "" : v.key
    return handleKeyChange(e, val, v.key === "NEW" || (v.virtual && v.predefined))
  }, [handleKeyChange])
  const onKeyChange = useCallback((e, v) => {
    return handleKeyChange(e, v, true)
  }, [handleKeyChange])
  const disabledOptionsFn = useCallback((option) => disabledOptions.map(x => x.key).includes(option.key), [disabledOptions])
  return (
    <Root>
      <div className={classes.box}>
        <IconButton onClick={handleCollapse}>
          {collapsed ? <ArrowRightIcon /> : <ArrowDropDownIcon />}
        </IconButton>
        <Typography className={classes.label}>{label}</Typography>
        {/* <TextField className={classes.textField} variant="outlined" size="small" placeholder={placeholder || 'Enter Segment'} error={!questionKey} disabled={disabled} value={questionKey || ''} onChange={handleKeyChange} /> */}
        <SelectInputField options={segmentOptions} disabledOptions={disabledOptionsFn} optionLabel="label" optionValueKey="key" placeholder={placeholder || 'Select Chart Segment'} disabled={disabled} multiple={false} invalid={!questionKey} value={questionKey} onChange={onKeySelect} />
        <IconButton disabled={disabled} onClick={onRemove}>
          <DeleteIcon />
        </IconButton>
      </div>
      {(virtual && !predefined) && <div className={classes.box}>
        <Typography className={classes.label}>{label}</Typography>
        <TextField className={classes.textField} variant="outlined" size="small" inputProps={{ maxLength: 40 }} placeholder={placeholder || 'Enter Segment'} error={!questionKey} disabled={disabled} value={questionKey || ''} onChange={onKeyChange} />
      </div>}
    </Root>
  )
}, (prev, next) => prev.questionKey === next.questionKey && prev.collapsed === next.collapsed && prev.virtual === next.virtual && prev.predefined === next.predefined && (Array.isArray(prev.options) && Array.isArray(next.options) && prev.options.length === next.options.length && prev.options.every((val, index) => val === next.options[index])) && (Array.isArray(prev.disabledOptions) && Array.isArray(next.disabledOptions) && prev.disabledOptions.length === next.disabledOptions.length && prev.disabledOptions.every((val, index) => val === next.disabledOptions[index])))

const SegmentInput = React.memo(({ fieldkey, disabled, hasCrosstab, allowCrosstab, crosstabKeys, handleCrosstabChange, handleCrosstabKeyChange, placeholder, segmentKeys }) => {
  // console.log('render segment input', segmentKeys)

  return (
    <Root>
      <Collapse in={allowCrosstab}>
        <div className={classes.box}>
          <div className={classes.checkbox}>
            <Checkbox key={`${fieldkey}crosstabcheck`} size="small" color="primary" checked={hasCrosstab} disabled={disabled} onChange={handleCrosstabChange} />
          </div>
          <Typography className={classes.checkLabel}>Has Crosstab for Chart?</Typography>
        </div>
        <Collapse in={hasCrosstab}>
          <div className={classes.box}>
            <SelectInputField options={segmentKeys.filter(x => !x.virtual)} optionLabel='key' optionValueKey='key' fullObjects={true} label="Crosstab Segments" placeholder={placeholder || 'Select Crosstab Segments'} disabled={disabled} multiple={true} filterSelected={true} value={crosstabKeys} onChange={handleCrosstabKeyChange} />
          </div>
        </Collapse>
      </Collapse>
    </Root>
  )
}, (prev, next) => prev.question === next.question && prev.multiple === next.multiple && prev.hasCrosstab === next.hasCrosstab && prev.allowCrosstab === next.allowCrosstab && (Array.isArray(prev.crosstabKeys) && Array.isArray(next.crosstabKeys) && prev.crosstabKeys.length === next.crosstabKeys.length && prev.crosstabKeys.every((val, index) => val === next.crosstabKeys[index])) && (Array.isArray(prev.segmentKeys) && Array.isArray(next.segmentKeys) && prev.segmentKeys.length === next.segmentKeys.length && prev.segmentKeys.every((val, index) => val === next.segmentKeys[index])))

const SegmentGroupInput = React.memo(({ disabled, forGroup, showOnChart, answerPlaceholder, answer, i, handleAnswerChange, handleRemoveAnswer, handleCheckAnswer }) => {
  // console.log('render answer', answer, showOnChart)

  return (
    <Root>
      <div className={classes.answerRow}>
        {forGroup ? <>
          <TextField className={classes.textField} variant="outlined" size="small" placeholder={answerPlaceholder || 'Enter Segment Group'} error={!answer} disabled={disabled} value={answer || ''} onChange={(e) => handleAnswerChange(e, i)} />
          <IconButton style={{ visibility: i ? 'initial' : 'hidden' }} disabled={disabled} onClick={(e) => handleRemoveAnswer(e, i)}>
            <DeleteIcon />
          </IconButton>
        </> : <>
          <div className={classes.checkbox}>
            <Checkbox size="small" color="primary" checked={showOnChart} disabled={disabled} onChange={(e) => handleCheckAnswer(e, i)} />
          </div>
          <Typography className={classes.checkLabel}>{answer}</Typography>
        </>}
      </div>
    </Root>
  )
}, (prev, next) => prev.answer === next.answer && prev.goal === next.goal && prev.forGroup === next.forGroup && prev.showOnChart === next.showOnChart && prev.i === next.i)

const QuestionKeyInput = React.memo(({ disabled, questionKey, handleKeyChange, collapsed, handleCollapse, label, placeholder, onRemove, draggable, drag }) => {
  // console.log('render question key input', questionKey)

  const onKeyChange = useCallback((e, v) => {
    return handleKeyChange(e, v, true)
  }, [handleKeyChange])
  return (
    <Root>
      <div className={classes.box}>
        {draggable && <div ref={drag} className={classes.move}>
          <OpenWithIcon className={classes.moveIcon} />
        </div>}
        <IconButton onClick={handleCollapse}>
          {collapsed ? <ArrowRightIcon /> : <ArrowDropDownIcon />}
        </IconButton>
        <Typography className={classes.label}>{label}</Typography>
        <TextField className={classes.textField} variant="outlined" size="small" inputProps={{ maxLength: 40 }} placeholder={placeholder || 'Enter Segment'} error={!questionKey} disabled={disabled} value={questionKey || ''} onChange={onKeyChange} />
        <IconButton disabled={disabled} onClick={onRemove}>
          <DeleteIcon />
        </IconButton>
      </div>
    </Root>
  )
}, (prev, next) => prev.questionKey === next.questionKey && prev.collapsed === next.collapsed)

const groupOptions = [
  'ANY',
  'ALL',
  'CUSTOM'
]

const SegmentAnswerInput = React.memo(({ fieldKey, disabled, invalid, invalidate, forGroup, answerPlaceholder, goalPlaceholder, answer, group, conditions, customLogic, i, handleAnswerChange, handleAnswerBlur, handleGroupChange, handleLogicChange, handleConditionChange, handleRemoveCondition, segmentKeys, goal, handleGoalChange, showGoals, handleRemoveAnswer, handleAddCondition }) => {
  // console.log('render segment answer', answer, conditions, invalid)

  const [logicValid, setLogicValid] = useState(false)

  const conditionKeyChange = useCallback((event, newValue, j) => {
    return handleConditionChange(event, newValue, i, j, 'dataSubKey')
  }, [i, handleConditionChange])

  const conditionHandlerChange = useCallback((event, newValue, j) => {
    return handleConditionChange(event, newValue, i, j, 'condition')
  }, [i, handleConditionChange])

  const conditionOptionChange = useCallback((event, newValue, j) => {
    return handleConditionChange(event, newValue, i, j, 'optionKey')
  }, [i, handleConditionChange])

  const conditionValueChange = useCallback((event, newValue, j) => {
    return handleConditionChange(event, newValue, i, j, 'answerKey')
  }, [i, handleConditionChange])

  const conditionAddHandler = useCallback((e) => {
    return handleAddCondition(e, i)
  }, [i, handleAddCondition])

  const conditionRemoveHandler = useCallback((event, j) => {
    return handleRemoveCondition(event, i, j)
  }, [i, handleRemoveCondition])

  const answerRemoveHandler = useCallback((event) => {
    return handleRemoveAnswer(event, i)
  }, [i, handleRemoveAnswer])

  const logicChangeHandler = useCallback((e, v) => {
    return handleLogicChange(e, v, i)
  }, [i, handleLogicChange])

  const customLogicValidator = useCallback((inputValue) => {
    let value = inputValue && inputValue.replace(/[^ \$\|&\d\(\)]/g, '')
    let valid = false;
    if (!!value) {
      let pattern = /^(\(|!)*\s*\!?\(?\s*(\$\d+)\)?(\s*(&&|\|\|)\s*\!?\(?\s*(\$\d+)\)?\)?)*\)*$/.test(value)
      let leftMatch = value.match(/\(/g) || []
      let rightMatch = value.match(/\)/g) || []
      valid = pattern && (leftMatch.length === rightMatch.length)
    }
    setLogicValid(valid)
    return valid
  }, [])

  return (<Root>
    <div className={classes.answerRow}>
      <TextField className={classes.textField} variant="outlined" size="small" placeholder={answerPlaceholder || `Enter Answer ${forGroup ? 'Group' : 'Option'}`} error={!answer || invalid} disabled={disabled} value={answer || ''} onChange={(e) => handleAnswerChange(e, i)} onBlur={(e) => handleAnswerBlur(e, i)} />
      <SelectInputField options={groupOptions} placeholder={'Grouping'} disabled={disabled} multiple={false} invalid={!group} value={group || ''} onChange={(e, v) => handleGroupChange(e, v, i)} />
      {showGoals && <>
        <TextField className={classes.timeField} inputProps={{ maxLength: 3, inputMode: "numeric" }} variant="outlined" size="small" placeholder={goalPlaceholder || 'Goal'} disabled={false} value={goal || ''} onChange={(e) => handleGoalChange(e, i)} />
        <Typography className={classes.colon}>%</Typography>
      </>}
      <IconButton style={{ visibility: i ? 'initial' : 'hidden' }} disabled={disabled} onClick={answerRemoveHandler}>
        <DeleteIcon />
      </IconButton>
    </div>
    <Collapse in={group === 'CUSTOM'}>
      <div className={classes.answerRow}>
        {/* <Typography className={classes.questionLabel}>Custom Logic String</Typography> */}
        <StandardField
          fieldKey={fieldKey}
          subKey={`customLogic-${i}`}
          type='text'
          // className={classes.questionField}
          // variant="outlined"
          // size="small"
          label={'Custom Logic String'}
          placeholder={'Enter Custom Logic String'}
          // error={!customLogic || invalid}
          invalid={!logicValid}
          invalidate={invalidate}
          conditional={true}
          conditionMet={group === 'CUSTOM'}
          disabled={disabled}
          value={customLogic || ''}
          onChange={logicChangeHandler}
          customValidator={customLogicValidator}
        />
      </div>
    </Collapse>
    {conditions && conditions.map((condition, j) => (
      <SegmentConditionInput key={`${fieldKey}segmentcondition${j}`} fieldKey={fieldKey} disabled={disabled} i={j} showIndex={group === 'CUSTOM'} dataSubKey={condition.dataSubKey} condition={condition.condition} optionKey={condition.optionKey} answerKey={condition.answerKey} segmentKeys={segmentKeys} handleKeyChange={(e, v) => conditionKeyChange(e, v, j)} handleConditionChange={(e, v) => conditionHandlerChange(e, v, j)} handleRemoveCondition={conditionRemoveHandler} handleOptionChange={(e, v) => conditionOptionChange(e, v, j)} handleValueChange={(e, v) => conditionValueChange(e, v, j)} />
    ))}
    <Button disabled={disabled} onClick={conditionAddHandler}>
      <AddIcon className={classes.plus} /> Add Condition
    </Button>
  </Root>)
})
// , (prev, next) => prev.answer === next.answer && prev.display === next.display && prev.goal === next.goal && prev.forGroup === next.forGroup && prev.i === next.i && prev.invalid === next.invalid && prev.showGoals === next.showGoals && prev.showTerminate === next.showTerminate && prev.terminate === next.terminate && prev.terminateOptions === next.terminateOptions && prev.matrix === next.matrix && prev.matrixKeys === next.matrixKeys)

const conditionOptions = {
  none: [],
  single: [
    'IS',
    'IS NOT'
  ],
  multiple: [
    'INCLUDES',
    'EXCLUDES',
    'COUNT >=',
    'COUNT <='
  ],
  numeric: [
    'IN',
    'NOT IN',
    'GREATER OR =',
    'LESS OR ='
  ],
  matrix: [
    'IS',
    'IS NOT'
  ]
}

const SegmentConditionInput = React.memo(({ fieldKey, disabled, i, showIndex, dataSubKey, condition, optionKey, answerKey, segmentKeys, matrix, handleKeyChange, handleConditionChange, handleRemoveCondition, handleOptionChange, handleValueChange }) => {


  const segmentType = useMemo(() => {
    const segment = segmentKeys.find(x => x.key === dataSubKey)
    if (!segment) {
      return 'none'
    } else if (segment.matrix) {
      return 'matrix'
    } else if (segment.multiple) {
      return 'multiple'
    } else if (segment.freeNumeric) {
      return 'numeric'
    } else {
      return 'single'
    }
  }, [segmentKeys, dataSubKey])

  const segmentAnswers = useMemo(() => {
    const segment = segmentKeys.find(x => x.key === dataSubKey)
    return segment?.answers || []
  }, [segmentKeys, dataSubKey])

  const segmentOptions = useMemo(() => {
    const segment = segmentKeys.find(x => x.key === dataSubKey)
    return segment?.matrix ? segment.options || [] : null
  }, [segmentKeys, dataSubKey])

  return (<Root>
    <div className={classes.answerRow}>
      {showIndex && <Typography>${(i + 1)}</Typography>}
      <SelectInputField options={segmentKeys.map(x => x.key)} placeholder={'Select Segment'} disabled={disabled} multiple={false} invalid={!dataSubKey} value={dataSubKey || ''} onChange={handleKeyChange} />
      <SelectInputField options={conditionOptions[segmentType]} placeholder={'Condition'} disabled={disabled} multiple={false} invalid={!condition} value={condition || ''} onChange={handleConditionChange} />
      {segmentOptions &&
        <SelectInputField options={segmentOptions} placeholder={'Select Option'} disabled={disabled} multiple={false} invalid={!optionKey} value={optionKey || ''} onChange={handleOptionChange} />
      }
      {['GREATER OR =', 'LESS OR ='].includes(condition) ?
        <NumberInputField placeholder={'Enter Answer'} disabled={disabled} value={answerKey || ''} onChange={handleValueChange} />
        :
        <SelectInputField options={segmentAnswers} placeholder={'Select Answer'} disabled={disabled} multiple={false} invalid={!answerKey} value={answerKey || ''} onChange={handleValueChange} />
      }
      <IconButton style={{ visibility: i ? 'initial' : 'hidden' }} disabled={disabled} onClick={(e) => handleRemoveCondition(e, i)}>
        <DeleteIcon />
      </IconButton>
    </div>
  </Root>)
})
// , (prev, next) => prev.answer === next.answer && prev.display === next.display && prev.goal === next.goal && prev.forGroup === next.forGroup && prev.i === next.i && prev.invalid === next.invalid && prev.showGoals === next.showGoals && prev.showTerminate === next.showTerminate && prev.terminate === next.terminate && prev.terminateOptions === next.terminateOptions && prev.matrix === next.matrix && prev.matrixKeys === next.matrixKeys)

ChartSegmentField.defaultProps = {
  label: 'Segment'
}

export default ChartSegmentField;
