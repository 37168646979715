import React from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

const PREFIX = 'LoadingPage';

const classes = {
    root: `${PREFIX}-root`
};

const Root = styled('div')({
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
});

const LoadingPage = React.memo(() => {

    return (
        <Root>
            <CircularProgress />
        </Root>
    );
})

export default LoadingPage