import React from 'react';
import { styled } from '@mui/material/styles';
import { Route, Routes, useLocation } from 'react-router-dom';
// import { TransitionGroup, CSSTransition } from "react-transition-group";
import AppNavBar from './components/AppNavBar';
import SessionsPage from './pages/SessionsPage';
import SessionManagementPage from './pages/SessionManagementPage';
// import SettingsPage from './pages/SettingsPage';
import { ErrorPage } from './pages/ErrorPage';
import LoadingPage from './pages/LoadingPage'
import './App.css'
import ProjectsPage from './pages/ProjectsPage';
import BannedPage from './pages/BannedPage';
import EmailsPage from './pages/EmailsPage';

const PREFIX = 'AppMain';

const classes = {
  app: `${PREFIX}-app`,
  transition: `${PREFIX}-transition`,
  body: `${PREFIX}-body`,
  footer: `${PREFIX}-footer`
};

const Root = styled('div')({
  // style for <Root> component
  height: '100%',
  width: '100%',
  minHeight: '100vh',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  // overflow: 'hidden'
  [`& .${classes.transition}`]: {
    position: 'relative',
    width: 'auto',
    height: '100vh',
    overflow: 'hidden'
  },
  [`& .${classes.body}`]: {
    position: 'relative',
    width: 'auto',
    height: '100%',
    // padding: '1rem',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  [`& .${classes.footer}`]: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 'calc(2rem + 56px)',
  },
});

const AppMain = React.memo((props) => {
  console.log('render app main', props)
  const location = useLocation();

  // console.log('user is: ', user)

  return (
    <Root>
      <AppNavBar />
      <div className={classes.body}>
        {/* <Zoom
          in={match != null}
          timeout={300}
          unmountOnExit> */}
        {/* <TransitionGroup>
          <CSSTransition
            key={props.location.pathname}
            timeout={{ enter: 300, exit: 0 }}
            // timeout={300}
            classNames="page"
            mountOnEnter
            unmountOnExit
          >
            <div className={classes.body}> */}
        <Routes location={props.location}>
          <Route exact path={'/sessions/upcoming'} element={<SessionsPage filter="upcoming" />} />
          <Route exact path={'/sessions/upcoming/:action'} element={<SessionsPage filter="upcoming" />} />
          <Route exact path={'/'} element={<SessionsPage filter="upcoming" />} />
          <Route exact path='/sessions/today' element={<SessionsPage filter="today's" />} />
          <Route exact path='/sessions/past' element={<SessionsPage filter="past" />} />
          <Route exact path='/projects' element={<ProjectsPage />} />
          <Route exact path='/banned' element={<BannedPage />} />
          <Route exact path='/emails' element={<EmailsPage />} />
          <Route exact path={'/session/:sessionId'} Component={SessionManagementPage} />
          <Route exact path={'/session/:sessionId/:selectedView'} Component={SessionManagementPage} />
          {/* <Route exact path='/session/:sessionId/guests' component={GuestsPage} /> */}
          {/* <Route exact path='/settings' element={() => <SettingsPage />} /> */}
          {/* <Route exact path='/authcallback' element={() => <LoadingPage />} /> */}
          <Route element={() => <ErrorPage error="Not Found" message="Page Not Found" />} />
        </Routes>
        {/* </div>
          </CSSTransition>
        </TransitionGroup> */}
        {/* </Zoom> */}
        {/* <div className={classes.footer} /> */}
      </div>
    </Root>
  );
})

export default AppMain;
