import React, { useEffect, useCallback, useState, useRef, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Badge, Tooltip, Typography, Button } from '@mui/material'
import { useClipboard } from 'use-clipboard-copy';
import StandardModal from './standard/StandardModal';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
// import TvIcon from '@mui/icons-material/TvTwoTone';
import MovieIcon from '@mui/icons-material/Movie';
import { RiMovie2Fill, RiMickeyFill, RiGameFill } from 'react-icons/ri'
import { FaFootballBall } from 'react-icons/fa'
import AssignmentIcon from '@mui/icons-material/Assignment';
import { IoMdFootball } from 'react-icons/io'
import { GiBasketballBall } from 'react-icons/gi'
// import { GiAmericanFootballHelmet, GiBasketballBasket } from 'react-icons/gi'
// import { ImVideoCamera } from 'react-icons/im'
// import { TiVideo } from 'react-icons/ti'
import { SiNetflix, SiWarnerbros, SiDcentertainment, SiAmazon, SiXbox, SiPlaystation, SiEpicgames, SiRiotgames, SiNintendo, SiNintendonetwork, SiNintendoswitch } from 'react-icons/si'
import seasiLogo from '../resources/logo.png'
import pfmLogo from '../resources/pfm-logo.png'
import vvtvLogo from '../resources/vv-logo.png'
import ewLogo from '../resources/ew-logo.png'
import moment from 'moment'
import countriesJSON from '../utils/iso-3166-1.alpha2'
import regionsJSON from '../utils/iso-3166-2'
import metroCodesJSON from '../utils/dma-metro-codes'
import languages from "../utils/languages.json";
import translations from "../utils/translations.json";

const PREFIX = 'SessionModal';

const classes = {
  centerBadge: `${PREFIX}-centerBadge`
};

const Root = styled('img')({
  [`& .${classes.centerBadge}`]: {
    transform: 'scale(1) translate(-100%, -50%)',
  }
});

const teamMap = {
  'seasi': 'SEA',
  'pfm': 'PFM',
  'vvtv': 'VTV',
  'cpt': 'CPT',
  'ew': 'EWX'
}

const brandMap = {
  'SEA': 'Screen Engine/ASI',
  'PFM': 'Preview Free Movies',
  'VTV': 'ViewerVoice TV',
  'CPT': 'Screen Engine/ASI',
  'EWX': 'EngineWorks'
}

const titleFn = ({ id }) => id ? 'Edit Session' : 'Add New Session'

const onTeamUpdate = (newValue, newParams) => {
  newParams.sessionBranding = newValue ? brandMap[newValue.code] : null
  return newParams
}

const onStartTimeUpdate = (newValue, newParams) => {
  const hours = moment(newValue).hours()
  const mins = moment(newValue).minutes()
  newParams.staffArrivalTime = moment(newValue).hours(hours - 2)
  newParams.guestCheckInTime = moment(newValue).hours(hours - 1)
  newParams.clientArrivalTime = moment(newValue).minutes(mins - 15);
  return newParams
}

// const onTBVideoUpdate = (newValue, newParams) => {
//   newParams.videoLength = (newValue && newValue.videoLength) || null
// }

const onSurveyTypeUpdate = (newValue, newParams) => {
  newParams.surveyId = null
  return newParams
}

// const fieldConditions = {
//   pfmScreeningId: ({ isPfmScreening }) => isPfmScreening === true
// }

const onSubGeoFilterUpdate = (newValue, newParams) => {
  newParams.subGeoRestriction = null
  return newParams
}

const defaultSymbolsList = [
  { emoji: '😊', tooltip: 'Happy' },
  { emoji: '🙁', tooltip: 'Sad' },
  { emoji: '🤨', tooltip: 'Confused' },
  { emoji: '😠', tooltip: 'Angry' },
  { emoji: '😢', tooltip: 'Crying' }
];

const SessionModal = React.memo((props) => {
  const { data, team, getProjects, getVideos, getTBVideos, getYoutubeVideo, getSurveys, getPFMScreenings, getAppointmentTypes, getCalendars, getZoomUsers, getZoomUserMeetings, allowFullEdit, allowPostEdit, allowVideoEdit } = props

  const clipboard = useClipboard({
    copiedTimeout: 1500
  });
  // const [hasExternalRecruit, setHasExternalRecruit] = useState(data && data.externalRecruit)
  // const [zoomHost, setZoomHost] = useState(data && data.zoomHostId)
  // const [appointmentTypeId, setAppointmentTypeId] = useState(data && data.acuityAppointmentTypeId)
  const [focusGroup, setFocusGroup] = useState(data?.type === 'Focus Group')
  const [multiSection, setMultiSection] = useState(data?.multiSection)
  const [playlistLength, setPlaylistLength] = useState(data?.multiSection && data?.contentLength?.length)
  const [youtubeData, setYoutubeData] = useState()
  const [youtubeLoading, setYoutubeLoading] = useState(false)
  const [allowedRecruitSceeners, setAllowedRecruitScreeners] = useState([])
  // const [allowedCountries, setAllowedCountries] = useState((data && data.allowedCountries) || ['US'])
  // const [audienceSegments, setAudienceSegments] = useState(data && data.audienceSegments)
  const projectsRef = useRef()
  const videosRef = useRef()
  const playlistsRef = useRef()
  const tcVideosRef = useRef()
  const tbVideosRef = useRef()
  const pfmScreeningsRef = useRef()
  const qualtricsRegRef = useRef()
  const qualtricsRef = useRef()
  const decipherRef = useRef()
  const acuityAppointmentTypesRef = useRef()
  const acuityCalendarsRef = useRef({})
  const zoomUsersRef = useRef()
  const zoomUserMeetingsRef = useRef({})
  const youtubeLoadedRef = useRef(false)

  const currentGoals = useMemo(() => {
    return data?.segmentGoals || {}
  }, [data])



  const disabledFn = useCallback(({ id, checkInStarted }) => allowFullEdit ? (!!id && !!checkInStarted) : true, [allowFullEdit])

  const disabledPostFn = useCallback(({ id, checkInStarted }) => allowFullEdit ? (!!id && !!checkInStarted) && !allowPostEdit : true, [allowFullEdit, allowPostEdit])

  const disabledTCFn = useCallback(({ id, checkInStarted, autoTechCheck }) => !!autoTechCheck || (allowFullEdit ? (!!id && !!checkInStarted) : true), [allowFullEdit])

  // const disabledFGFn = useCallback(({ type }) => type !== 'Content', []);

  const disabledRTFeedback = useCallback(({id, checkInStarted, type, hasProject, projectId }) => (type !== 'Content') || ((hasProject && !!projectId && projectId !== "NEW")) || (allowFullEdit ? (!!id && !!checkInStarted) : true), [allowFullEdit]);

  const disabledProjectFn = useCallback(({ id, checkInStarted, hasProject, projectId }) => (hasProject && !!projectId && projectId !== "NEW") || (allowFullEdit ? (!!id && !!checkInStarted) : true), [allowFullEdit])

  const disabledQualtricsProjectFn = useCallback(({ id, checkInStarted, hasProject, projectId, externalRecruit, recruitPlatform }) => (hasProject && !!projectId && projectId !== "NEW" && externalRecruit && recruitPlatform === "QTC") || (allowFullEdit ? (!!id && !!checkInStarted) : true), [allowFullEdit])

  const disabledVideoFn = useCallback(({ id, checkInStarted }) => youtubeLoading && ((allowFullEdit || allowVideoEdit) ? (!!id && !!checkInStarted) : true), [allowFullEdit, allowVideoEdit, youtubeLoading])

  const disabledProjectVideoFn = useCallback(({ id, checkInStarted, hasProject, projectId }) => (hasProject && !!projectId && projectId !== "NEW") || (youtubeLoading && ((allowFullEdit || allowVideoEdit) ? (!!id && !!checkInStarted) : true)), [allowFullEdit, allowVideoEdit, youtubeLoading])

  const disabledSubGeoFilterFn = useCallback(({ allowedCountries }, option) => allowedCountries?.toString() !== 'US' && option?.type === 'metroCode', [])

  const disabledScreeningsFn = useCallback((px, option) => !!option.externalSessionId || (allowedRecruitSceeners.length && !allowedRecruitSceeners.includes(option.id)), [allowedRecruitSceeners])

  const disabledSurveysFn = useCallback((px, option) => !!(option?.assigned && !option.assigned.includes(data?.id)), [data])

  const disabledInternalNameFn = useCallback(({ videoId }) => (!videoId), [])

  const disabledClientDialViewerFn = useCallback(({ dialMode }) => (dialMode === 'buttonOnly'), [])

  const disabledVideoSurveysFn = useCallback((px, option) => ((!multiSection || !!focusGroup) && ['inter', 'all'].includes(option?.code)), [focusGroup, multiSection])

  const disabledDialOptions = useCallback(({ dialMode }, option) => (dialMode === 'score' ? option === 'Emoji' : option !== 'Emoji'), [])

  const projects = useCallback(() => projectsRef.current || (projectsRef.current = getProjects().then(res => (projectsRef.current = (res || []), res)), projectsRef.current), [getProjects])

  useEffect(() => {
    if (data) {
      Promise.resolve(projects()).then(projx => {
        const current = projx.find(p => p.id === data.projectId)
        // console.log('PROJECTS & DATA', projx, data, current)
        if (current) setAllowedRecruitScreeners(current.externalRecruitIds)
      })
    }
  }, [projects, data])

  const onTypeUpdate = useCallback((newValue, newParams) => {
    if(newValue?.code === 'Focus Group'){
      newParams.isDialSession = false;
      setFocusGroup(true)
    }
    return newParams
  }, [])

  const onVideoPlatformUpdate = useCallback((newValue, newParams) => {
    newParams.videoId = null
    newParams.isLiveSession = false
    newParams.multiSection = false
    newParams.internalVideoName = []
    if (newValue === 'YouTube') {
      newParams.videoLength = null
      newParams.contentLength = []
    }
    setPlaylistLength(0)
    return newParams
  }, [])

  const onMultiSectionUpdate = useCallback((newValue, newParams) => {
    newParams.videoId = null
    newParams.internalVideoName = []
    setPlaylistLength(0)
    setMultiSection(!!newValue)
    return newParams
  }, [])

  const onBCVideoUpdate = useCallback((newValue, newParams) => {
    const duration = (newValue && newValue.duration) || null
    newParams.videoLength = duration
    newParams.contentLength = duration ? [duration] : null
    setPlaylistLength(0)
    return newParams
  }, [])

  const onBCPlaylistUpdate = useCallback((newValue, newParams) => {
    newParams.contentLength = (newValue.video_ids || []).map(x => newValue.videos.find(v => v.id === x)?.duration || 0)
    console.log('PLAYLIST UPDATE!!!', newValue.videos, newParams.contentLength)
    newParams.videoLength = newParams.contentLength.reduce((p, c) => (p += c + (10 * 1000)), 0)
    setPlaylistLength(newParams.contentLength.length)
    return newParams
  }, [])

  const onLanguageChanged = useCallback((newVal, newParams) => {
    // newParams.viewerEmailTemplate = translations.linkToVirtualEvent[newVal.code];
    if (newParams.dialMode) {
      newParams.inactiveMessage = newParams.dialMode === 'score' ? translations.continueToMoveDial[newVal.code] : translations.continueToClick[newVal.code];
    }
    return newParams
  }, []);

  const onDialModeChanged = useCallback((newVal, newParams) => {
    if (newVal.code === 'symbol') {
      newParams.dialStyle = "Emoji";
      newParams.symbolOptions = defaultSymbolsList;
      newParams.inactiveMessage = translations.continueToFeel[newParams.viewerLanguage];
      newParams.inactiveReminder = true;
      newParams.clientDialViewer = true;
      newParams.includeTuneOut = false;

      // set default for chartConfig
      newParams.chartConfig = {
        defaultChartType: "line",
        defaultEmoji: defaultSymbolsList[0].emoji,
        defaultEmojiSegmentGroup: "all",
        defaultSegment: "all",
        segmentationMode: "byEmoji",
        defaultAggregationInterval: 30
      }
    }
    else if (newVal.code === 'score') {
      newParams.inactiveReminder = true;
      newParams.dialStyle = "Embedded Slider";
      newParams.scoreBase = 100;
      newParams.sliderKnob = 'Clapperboard';
      newParams.sliderKnobColor = '#F44E3B';
      newParams.dialMotion = 'Stepwise';
      newParams.numberOfMeters = 19;
      newParams.inactiveMessage = translations.continueToMoveDial[newParams.viewerLanguage];
      newParams.chartConfig = null;
      newParams.clientDialViewer = true;
    }else {
      newParams.chartConfig = null;
      newParams.inactiveReminder = false;
      newParams.clientDialViewer = false;
    }
    newParams.inactiveMessage = newVal.code === 'score' ? translations.continueToMoveDial[newParams.viewerLanguage] : translations.continueToClick[newParams.viewerLanguage];
    return newParams
  }, []);

  const onHasProjectUpdate = useCallback(async (newValue, newParams) => {
    if (!newValue) {
      newParams.projectId = null
      newParams.hasQualifyingSegment = false
      newParams.qualifyingSegment = {}
      if (newParams.externalRecruit && newParams.recruitPlatform === 'QTC') newParams.externalRecruitId = "NEW"
    } else if (newValue && newParams.externalRecruit && newParams.recruitPlatform === 'PFM' && newParams.externalRecruitId) {
      const screenings = await Promise.resolve(pfmScreeningsRef.current)
      const screening = screenings.find(x => x.id === newParams.externalRecruitId)
      if (screening.linkedScreeningIds) newParams.linkedScreenings = [newParams.externalRecruitId, ...screening.linkedScreeningIds].sort()
    }
    return newParams
  }, [])

  const onProjectUpdate = useCallback((newValue, newParams) => {
    const selectedProject = newValue?.id !== "NEW" && newValue
    let projectRecruitIds = []
    let contentLength = 1
    if (selectedProject) {
      newParams.team = selectedProject.team
      newParams.client = selectedProject.client
      newParams.seaJobCode = selectedProject.seaJobCode
      newParams.videoPlatform = selectedProject.videoPlatform
      newParams.videoLength = selectedProject.videoLength
      newParams.contentLength = selectedProject.contentLength || []
      newParams.autoStop = selectedProject.autoStop
      newParams.autoStopTime = selectedProject.autoStopTime
      // newParams.videoUploaded = selectedProject.videoUploaded
      newParams.videoId = selectedProject.videoId
      newParams.playerId = selectedProject.playerId
      newParams.audienceSegments = selectedProject.audienceSegments || []
      newParams.dialChartSegments = selectedProject.dialChartSegments || []
      newParams.virtualSegments = selectedProject.virtualSegments || []
      newParams.videoMarkers = selectedProject.videoMarkers || []
      newParams.isDialSession = selectedProject.isDialProject
      newParams.scoreBase = selectedProject.scoreBase
      newParams.externalRecruit = selectedProject.externalRecruit
      newParams.recruitPlatform = selectedProject.recruitPlatform
      newParams.externalRecruitId = selectedProject.externalRecruitId
      if (selectedProject.externalRecruit) {
        newParams.bypassTechCheck = false
        newParams.acuityTechCheck = true
      }
      newParams.clientMagicLink = selectedProject.clientMagicLink
      newParams.magicLinkUrl = selectedProject.magicLinkUrl
      newParams.requirePasscode = selectedProject.requirePasscode
      newParams.magicLinkPasscode = selectedProject.magicLinkPasscode
      newParams.requireEmailValidation = selectedProject.requireEmailValidation
      newParams.dialMode = selectedProject.dialMode
      newParams.inactiveMessage = selectedProject.dialMode === 'score' ? translations.continueToMoveDial[newParams.viewerLanguage] : translations.continueToClick[newParams.viewerLanguage];
      newParams.symbolOptions = selectedProject.symbolOptions
      // newParams.requireApproval = selectedProject.requireApproval
      contentLength = selectedProject.contentLength?.length || 0
      projectRecruitIds = selectedProject.externalRecruitIds || []
      // setHasExternalRecruit(!!selectedProject.externalRecruit)
    }
    setPlaylistLength(contentLength)
    setAllowedRecruitScreeners(projectRecruitIds)
    return newParams
  }, [])

  const bcVideos = useCallback(() => videosRef.current || (videosRef.current = getVideos('brightcove').then(res => (videosRef.current = (res || []), res)), videosRef.current), [getVideos])

  // const bcPlaylists = useCallback(() => playlistsRef.current || (playlistsRef.current = getVideos('brightcove', null, true).then(res => (playlistsRef.current = (res || []), res)), playlistsRef.current), [getVideos])

  const bcPlaylists = useMemo(() => Promise.resolve(bcVideos()).then(vids => {
    return playlistsRef.current || (playlistsRef.current = getVideos('brightcove', null, true).then(res => {
      playlistsRef.current = (res || []).map(x => (x.videos = vids.filter(v => (x.video_ids || []).includes(v.id)), x))
      return playlistsRef.current
    }), playlistsRef.current)
  }), [getVideos, bcVideos])

  const onNewPlaylistUpdate = useCallback((newValue, newParams) => {
    return Promise.resolve(bcVideos()).then(vids => {
      newParams.contentLength = (newValue || []).map(x => vids.find(v => v.id === x)?.duration || 0)
      const [first, ...rest] = newParams.contentLength
      newParams.videoLength = rest.reduce((p, c) => (p += c + (15 * 60 * 1000)), first) // 15 MINUTES BETWEEN
      setPlaylistLength(newParams.contentLength.length)
      return newParams
    })
  }, [bcVideos])

  const tbVideos = useCallback((tc) => tbVideosRef.current || (tbVideosRef.current = getVideos('ticktbox').then(res => (tbVideosRef.current = (res || []), res)), tbVideosRef.current), [getVideos])

  // const tbVideosTC = useCallback(() => tbVideosRef.current?.filter(x => x.techCheck) || getVideos('ticktbox').then(res => (tbVideosRef.current = (res || []), res.filter(x => x.techCheck))), [getVideos])
  const tbVideosTC = useCallback(() => Promise.resolve(tbVideos()).then(vids => {
    // console.log('got vids', vids)
    return vids.filter(x => x.techCheck)
  }), [tbVideos])

  const ytVideo = useCallback((vid) => getYoutubeVideo(vid), [getYoutubeVideo])

  const bcVideosTC = useCallback(() => tcVideosRef.current || (tcVideosRef.current = getVideos('brightcove', 'tc').then(res => (tcVideosRef.current = (res || []), res)), tcVideosRef.current), [getVideos])

  const pfmOnlineScreenings = useCallback(() => pfmScreeningsRef.current || (pfmScreeningsRef.current = getPFMScreenings().then(res => (pfmScreeningsRef.current = (res || []).map(x => (x.id = `${x.id}`, x.linkedScreeningIds = x.linkedScreeningIds && x.linkedScreeningIds.map(x => x && x.toString()), x)), pfmScreeningsRef.current)), pfmScreeningsRef.current), [getPFMScreenings])

  const qualtricsRegistrationSurveys = useCallback(() => qualtricsRegRef.current || (qualtricsRegRef.current = getSurveys('qualtrics', true).then(res => (qualtricsRegRef.current = (res || []), res)), qualtricsRegRef.current), [getSurveys])

  const qualtricsSurveys = useCallback(() => qualtricsRef.current || (qualtricsRef.current = getSurveys('qualtrics').then(res => (qualtricsRef.current = (res || []), res)), qualtricsRef.current), [getSurveys])

  const decipherSurveys = useCallback(() => decipherRef.current || (decipherRef.current = getSurveys('decipher').then(res => (decipherRef.current = (res || []), res)), decipherRef.current), [getSurveys])

  const acuityAppointmentTypes = useCallback(() => acuityAppointmentTypesRef.current || (acuityAppointmentTypesRef.current = getAppointmentTypes().then(res => (acuityAppointmentTypesRef.current = (res || []), res)), acuityAppointmentTypesRef.current), [getAppointmentTypes])

  const onAppointmentTypeUpdate = useCallback((newValue, newParams) => {
    // setAppointmentTypeId((newValue && newValue.id) || [])
    newParams.acuityCalendarId = null
    return newParams
  }, [])

  const acuityCalendars = useCallback((params) => params.acuityAppointmentTypeId ? acuityCalendarsRef.current[params.acuityAppointmentTypeId] || getCalendars(params.acuityAppointmentTypeId).then(res => (acuityCalendarsRef.current[params.acuityAppointmentTypeId] = res, res)) : [], [getCalendars])

  const zoomUsers = useCallback(() => zoomUsersRef.current || (zoomUsersRef.current = getZoomUsers().then(res => (zoomUsersRef.current = (res || []), res)), zoomUsersRef.current), [getZoomUsers])

  const onZoomHostUpdate = useCallback((newValue, newParams) => {
    // setZoomHost((newValue && newValue.id) || null)
    newParams.zoomMeetingId = 'NEW'
    return newParams
  }, [])

  const zoomUserMeetings = useCallback((params) => !params.zoomHostId ? [] : zoomUserMeetingsRef.current[params.zoomHostId] || getZoomUserMeetings(params.zoomHostId).then(res => (zoomUserMeetingsRef.current[params.zoomHostId] = res, res)), [getZoomUserMeetings])

  const onAllowedCountriesUpdate = useCallback((newValue, newParams) => {
    if (!(newValue && newValue.length)) {
      newParams.enhancedGeoRestriction = false
      newParams.subGeoRestrictionType = null
      newParams.subGeoRestriction = null
    } else if (newParams.enhancedGeoRestriction && newParams.subGeoRestrictionType === 'metroCode' && newValue?.toString() !== 'US') {
      newParams.subGeoRestrictionType = null
      newParams.subGeoRestriction = null
    }
    // setAllowedCountries((newValue && newValue.map(x => x['alpha-2'])) || [])
    return newParams
  }, [])

  const regionOptions = useCallback((params) => {
    return (params.allowedCountries || []).reduce((p, c) => (p.push(...(regionsJSON[c] || [])), p), [])
  }, [])

  const onYoutubeVideoChange = useCallback((newValue, newParams) => {
    if (/^[\w\-]{11}$/.test(newValue)) {
      setYoutubeLoading(true)
      const returnParams = {}
      return ytVideo(newValue).then(vidData => {
        returnParams.isLiveSession = !!vidData.liveStreamDetails
        setYoutubeData(vidData)
        setYoutubeLoading(false)
        return returnParams
      }).catch(err => {
        returnParams.isLiveSession = false
        setYoutubeData()
        setYoutubeLoading(false)
        return returnParams
      })
    } else {
      newParams.isLiveSession = false
      setYoutubeData()
      return newParams
    }
  }, [ytVideo])

  const youtubeCustomValidator = useCallback((value) => {
    if (/^[\w\-]{11}$/.test(value)) {
      if (youtubeLoadedRef.current) {
        return !!youtubeData
      } else {
        setYoutubeLoading(true)
        return ytVideo(value).then(vidData => {
          setYoutubeData(vidData)
          youtubeLoadedRef.current = true
          setYoutubeLoading(false)
          return true
        }).catch(err => {
          setYoutubeData()
          setYoutubeLoading(false)
          return false
        })
      }
    } else {
      return false
    }
  }, [youtubeData, ytVideo])

  const youtubeSublabel = useCallback(() => {
    return youtubeLoading ? 'Loading...' : youtubeData ? youtubeData.videoTitle : 'NO VIDEO SELECTED'
  }, [youtubeData, youtubeLoading])

  const YoutubeLiveBadge = useMemo(() => {
    return <Badge badgeContent="LIVE" color="secondary" anchorOrigin={{ vertical: 'top', horizontal: 'left' }} classes={{
      badge: classes.centerBadge
    }} />
  }, [classes])

  const youtubeEndComponent = useCallback(() => {
    return youtubeData && !!youtubeData.liveStreamDetails ? YoutubeLiveBadge : <></>
  }, [youtubeData, YoutubeLiveBadge])

  const clipboardEndComponent = useCallback(() => {
    const copyToClipboard = () => {
      // let text = `
      // Use this link to access the virtuworks™ Live session:
      // ${data.magicLinkUrl}

      // This session requires the following additional security measures:
      // Passcode: ${data.magicLinkPasscode}
      // Email Verification: ${data.requireEmailValidation ? 'Yes' : 'No'}
      // Waiting Room: ${data.requireApproval ? 'Enabled' : 'Disabled'}

      // If you have any trouble, please contact your client service representative for support.
      // `
      let text = `
      Use this link to access the virtuworks™ Live session:
      ${data.magicLinkUrl}
      
      This session requires the following additional security measures:
      Passcode: ${data.magicLinkPasscode}
      Email Verification: ${data.requireEmailValidation ? 'Yes' : 'No'}
      
      If you have any trouble, please contact your client service representative for support.
      `
      clipboard.copy(text);
    }
    return <span style={{ display: 'flex', alignItems: 'center' }}><Tooltip title="Copy"><Button style={{ minWidth: 0, padding: '1px 2px' }}><AssignmentIcon onClick={copyToClipboard} /></Button></Tooltip></span>;
  }, [data, clipboard])

  const onHasExternalRecruitUpdate = useCallback((newValue, newParams) => {
    if (!!newValue) {
      newParams.bypassTechCheck = false
      newParams.acuityTechCheck = true
    }
    // setHasExternalRecruit(!!newValue)
    return newParams
  }, [])

  const onRecruitScreenerUpdate = useCallback((newValue, newParams) => {
    const registrationSurvey = newValue.id !== "NEW" && newValue
    if (registrationSurvey) {
      if (registrationSurvey.segments) { //Qualtrics
        newParams.audienceSegments = registrationSurvey.segments
      } else if (registrationSurvey.Questions) { //PFM
        newParams.linkedScreenings = registrationSurvey.linkedScreeningIds
        newParams.videoTitle = registrationSurvey.title
        newParams.client = registrationSurvey.Client.displayName
        const hours = moment(registrationSurvey.screeningStartDate).hours()
        const mins = moment(registrationSurvey.screeningStartDate).minutes()
        newParams.scheduledStartTime = moment(registrationSurvey.screeningStartDate)
        newParams.staffArrivalTime = moment(registrationSurvey.screeningStartDate).hours(hours - 2)
        newParams.guestCheckInTime = moment(registrationSurvey.screeningStartDate).hours(hours - 1)
        newParams.clientArrivalTime = moment(registrationSurvey.screeningStartDate).minutes(mins - 15);
        const goals = registrationSurvey.RuleSets.reduce((p, c) => {
          if (c.questionId && c.acceptanceType === "MAKES UP" && Number.isInteger(c.conditionPercentage)) {
            const goal = p[c.questionId] || {}
            p[c.questionId] = { ...goal, [c.Rules[0].configJSON.values[0]]: c.Rules[0].configJSON.option ? { ...(goal[c.Rules[0].configJSON.values[0]] || {}), [c.Rules[0].configJSON.option]: c.conditionPercentage } : c.conditionPercentage }
          }
          return p
        }, {})
        const segs = registrationSurvey.Questions.filter(q => q.fieldCode !== "Fields of Employment").map((question) => {
          const goal = goals[question.id] || {}
          return {
            questionKey: question.label,
            question: question.displayQuestion,
            multiple: question.configJSON.dataType === 408,
            matrix: question.configJSON.dataType === 808,
            hasCrosstab: false,
            crosstabKeys: [],
            freeNumeric: ['Age', 'Child Age'].includes(question.fieldCode),
            answers: question.configJSON.answers.map(answer => ({ answer, goal: goal[answer] })),
            matrixKeys: question.configJSON.dataType === 808 ? question.configJSON.options.map(option => ({ option })) : null
          }
        })
        newParams.audienceSegments = segs
        if (registrationSurvey.rsvpMax) newParams.confirmGoal = registrationSurvey.rsvpMax
        if (registrationSurvey.seatMax) newParams.seatGoal = registrationSurvey.seatMax
        if (registrationSurvey.focusGroupGoal) newParams.focusGroupGoal = registrationSurvey.focusGroupGoal
        if (registrationSurvey.seasiJobCode) newParams.seaJobCode = registrationSurvey.seasiJobCode
      }
    }
    return newParams
  }, [])

  const onBypassTechCheck = useCallback((newValue, newParams) => {
    if (!!newValue) {
      newParams.autoTechCheck = false
      newParams.limitAuto = false
      newParams.acuityTechCheck = false
      newParams.acuityAppointmentTypeId = null
      newParams.acuityCalendarId = null
    }
    return newParams
  }, [])

  const onAutoTechCheck = useCallback((newValue, newParams) => {
    if (!!newValue) {
      newParams.bypassTechCheck = false
      newParams.acuityTechCheck = true
    } else {
      newParams.limitAuto = false
    }
    return newParams
  }, [])

  const onHasQualifyingSegmentUpdate = useCallback((newValue, newParams) => {
    if (!newValue) newParams.qualifyingSegment = {}
    return newParams
  }, [])

  const onAudienceSegmentsUpdate = useCallback((newValue, newParams) => {
    if (newParams.qualifyingSegment) {
      const segment = newValue.find(x => x.questionKey === newParams.qualifyingSegment.segmentKey)
      if (!segment) {
        newParams.qualifyingSegment.segmentKey = null
        newParams.qualifyingSegment.segmentOption = null
        newParams.qualifyingSegment.segmentValue = null
      } else {
        if (!((segment.matrixKeys || []).map(x => x.option).includes(newParams.qualifyingSegment.segmentOption))) {
          newParams.qualifyingSegment.segmentOption = null
        }
        if (!((segment.answers || []).map(x => x.answer).includes(newParams.qualifyingSegment.segmentValue))) {
          newParams.qualifyingSegment.segmentValue = null
        }
      }
    }
    // setAudienceSegments((newValue || []).filter(x => !x.multiple))
    return newParams
  }, [])

  const audienceSegments = useCallback((params) => params.audienceSegments || [], [])

  const showTerminate = useCallback((params) => params.externalRecruit === true && params.recruitPlatform === "QTC", [])

  const videoNameLabel = useCallback((pos) => playlistLength ? `Video ${pos}` : null, [playlistLength])

  return (
    <StandardModal
      modalKey='sessionModal'
      open
      {...props}
      title={titleFn}
      buttonLabel='Submit'
      tabs={[
        {
          key: 'detailsTab',
          label: 'Details',
          fields: [
            [
              {
                key: 'test',
                label: 'Test Session',
                type: 'boolean',
                defaultValue: false,
                disabled: disabledFn
              },
              {
                key: 'hasProject',
                label: 'Project Grouped Session',
                type: 'boolean',
                defaultValue: false,
                onUpdate: onHasProjectUpdate,
                // disabled: disabledFn
              },
              {
                key: 'projectId',
                label: 'Project',
                placeholder: 'Select Project',
                type: 'select',
                options: projects,
                optionLabel: 'projectName',
                optionValueKey: 'id',
                conditions: [
                  {
                    condition: ({ hasProject }) => hasProject === true,
                    required: true
                  }
                ],
                onUpdate: onProjectUpdate,
                // disabled: disabledFn
              },
              {
                key: 'projectName',
                label: 'Project Name',
                placeholder: 'Enter Project Name',
                type: 'text',
                conditions: [
                  {
                    condition: ({ hasProject }) => hasProject === true,
                  },
                  {
                    condition: ({ projectId }) => projectId === 'NEW',
                    required: true
                  }
                ],
              },
              {
                key: 'team',
                label: 'Team',
                type: 'select',
                placeholder: 'Select Team',
                options: [
                  // { code: 'SEA', label: "Screen Engine" },
                  { code: 'PFM', label: "Preview Free Movies" },
                  { code: 'VTV', label: "Viewer Voice TV" },
                  { code: 'CPT', label: "Cross Platform Team" },
                  { code: 'EWX', label: "EngineWorks" }
                ],
                optionLabel: 'label',
                optionValueKey: 'code',
                multiple: false,
                required: true,
                onUpdate: onTeamUpdate,
                defaultValue: teamMap[team] || 'PFM',
                disabled: disabledProjectFn
              },
              {
                key: 'sessionBranding',
                label: 'Branding',
                placeholder: 'Select Branding',
                type: 'select',
                options: [
                  'Screen Engine/ASI',
                  'Preview Free Movies',
                  'ViewerVoice TV',
                  'EngineWorks'
                ],
                multiple: false,
                required: true,
                defaultValue: brandMap[teamMap[team] || 'PFM'],
                displayComponent: {
                  'Screen Engine/ASI': () => <Root alt='Screen Engine/ASI' height='45px' src={seasiLogo} />,
                  'EngineWorks': () => <img alt='EngineWorks' height='45px' src={ewLogo} />,
                  'Preview Free Movies': () => <img alt='Preview Free Movies' height='45px' src={pfmLogo} />,
                  'ViewerVoice TV': () => <img alt='ViewerVoice TV' height='45px' src={vvtvLogo} />
                },
                disabled: disabledFn
              },
              {
                key: 'sessionName',
                label: 'Session Name',
                placeholder: 'Enter Internal Session Name',
                type: 'text',
                maxLength: 50,
                required: true,
                disabled: disabledFn
              },
              {
                key: 'type',
                label: 'Session Type',
                type: 'select',
                placeholder: 'Select Session Type',
                options: [
                  { code: 'Content', label: "Content" },
                  { code: 'Focus Group', label: "Focus Group Only" }
                ],
                optionLabel: 'label',
                optionValueKey: 'code',
                multiple: false,
                required: true,
                onUpdate: onTypeUpdate,
                defaultValue: 'Content',
                disabled: disabledProjectFn
              },
              {
                key: 'seaJobCode',
                label: 'SE/ASI Job Code',
                type: 'text',
                // regexp: /[0-9]{4}/,
                placeholder: 'Enter Job Code (NOCODE if none)',
                maxLength: 25,
                required: true,
                disabled: disabledPostFn
              },
              {
                key: 'client',
                label: 'Client',
                type: 'select',
                placeholder: 'Select Client (or type custom + ENTER)',
                options: [
                  'Netflix',
                  'Disney',
                  'ABC TV',
                  'NBC/Universal',
                  'MGM',
                  'Lionsgate',
                  'Blumhouse'
                ],
                freeType: true,
                // maxLength: 25,
                multiple: false,
                required: true,
                disabled: disabledProjectFn
              },
              {
                key: 'scheduledStartTime',
                label: 'Scheduled Start Time',
                type: 'datetime',
                allowPast: false,
                showTimezone: true,
                required: true,
                onUpdate: onStartTimeUpdate,
                disabled: disabledFn
              },
              {
                key: 'staffArrivalTime',
                label: 'Staff Arrival Time',
                type: 'datetime',
                allowPast: false,
                showTimezone: true,
                required: true,
                disabled: disabledFn
              },
              {
                key: 'guestCheckInTime',
                label: 'Guest Check-In Time',
                type: 'datetime',
                allowPast: false,
                showTimezone: true,
                required: true,
                disabled: disabledFn
              },
              {
                key: 'clientArrivalTime',
                label: 'Client Show Time',
                type: 'datetime',
                allowPast: false,
                showTimezone: true,
                required: true,
                disabled: disabledFn
              },
              // {
              //   key: 'isPfmScreening',
              //   label: 'Is PFM Screening?',
              //   type: 'boolean',
              //   defaultValue: false
              // },
              // {
              //   key: 'pfmScreeningId',
              //   label: 'PFM Screening ID',
              //   type: 'text',
              //   regexp: /^[0-9]{4}$/,
              //   conditions: [
              //     {
              //       condition: ({ isPfmScreening }) => isPfmScreening === true,
              //       required: true
              //     }
              //   ],
              // },
              {
                key: 'externalRecruit',
                label: 'Use External Recruit',
                type: 'boolean',
                defaultValue: false,
                onUpdate: onHasExternalRecruitUpdate,
                disabled: disabledProjectFn
              },
              {
                key: 'recruitPlatform',
                label: 'Recruit Platform',
                placeholder: 'Select Recruit Platform',
                type: 'select',
                options: [
                  { code: 'PFM', label: "Preview Free Movies" },
                  { code: 'QTC', label: "Qualtrics" }
                ],
                optionLabel: 'label',
                optionValueKey: 'code',
                multiple: false,
                defaultValue: 'PFM',
                conditions: [
                  {
                    condition: ({ externalRecruit }) => externalRecruit === true,
                    required: true
                  }
                ],
                // onUpdate: onRecruitPlatformUpdate,
                disabled: disabledProjectFn
              },
              {
                key: 'externalRecruitId',
                label: 'PFM Recruit Screening',
                placeholder: 'Select PFM Recruit Screening',
                type: 'select',
                options: pfmOnlineScreenings,
                disabledOptions: disabledScreeningsFn,
                optionLabel: (opt) => opt && `${opt.title} (#${opt.id})`,
                optionValueKey: 'id',
                conditions: [
                  {
                    condition: ({ externalRecruit, recruitPlatform }) => externalRecruit === true && recruitPlatform === "PFM",
                    required: true
                  }
                ],
                onUpdate: onRecruitScreenerUpdate,
                disabled: disabledFn
              },
              {
                key: 'externalRecruitId',
                label: 'Qualtrics Recruit Screener',
                placeholder: 'Select Qualtrics Recruit Screener',
                type: 'select',
                options: qualtricsRegistrationSurveys,
                disabledOptions: disabledSurveysFn,
                optionLabel: 'name',
                optionValueKey: 'id',
                conditions: [
                  {
                    condition: ({ externalRecruit, recruitPlatform }) => externalRecruit === true && recruitPlatform === "QTC",
                    required: true
                  }
                ],
                onUpdate: onRecruitScreenerUpdate,
                disabled: disabledQualtricsProjectFn
              },
              {
                key: 'qualtricsRegistrationName',
                label: 'Qualtrics Recruit Screener Name',
                placeholder: 'Enter Qualtrics Recruit Screener Name',
                type: 'text',
                conditions: [
                  {
                    condition: ({ externalRecruit, recruitPlatform, externalRecruitId }) => externalRecruit === true && recruitPlatform === "QTC" && externalRecruitId === 'NEW',
                    required: true
                  }
                ],
                disabled: disabledFn
              },
              {
                key: 'pauseExternalRecruit',
                label: 'Pause External Registration',
                type: 'boolean',
                defaultValue: false,
                conditions: [
                  {
                    condition: ({ externalRecruit, externalRecruitId }) => externalRecruit === true && externalRecruitId !== 'NEW'
                  }
                ],
                disabled: disabledFn
              },
              {
                key: 'bypassTechCheck',
                label: 'Bypass Tech Check',
                type: 'boolean',
                defaultValue: false,
                onUpdate: onBypassTechCheck,
                disabled: disabledTCFn
              },
              {
                key: 'autoTechCheck',
                label: 'Self-Guided Tech Check',
                type: 'boolean',
                defaultValue: false,
                conditions: [
                  {
                    condition: ({ bypassTechCheck }) => !bypassTechCheck
                  }
                ],
                onUpdate: onAutoTechCheck,
                // disabled: disabledTCFn
              },
              {
                key: 'limitAuto',
                label: 'Limit Self-Guided Tech Check to Past Participants',
                type: 'boolean',
                defaultValue: true,
                conditions: [
                  {
                    condition: ({ autoTechCheck }) => !!autoTechCheck
                  }
                ],
                // disabled: disabledTCFn
              },
              {
                key: 'acuityTechCheck',
                label: 'Use Acuity Scheduling for Tech Check',
                type: 'boolean',
                defaultValue: false,
                conditions: [
                  {
                    condition: ({ bypassTechCheck }) => !bypassTechCheck
                  }
                ],
                disabled: disabledTCFn
              },
              {
                key: 'acuityAppointmentTypeId',
                label: 'Acuity Appointment Type',
                placeholder: 'Select Acuity Appointment Type',
                type: 'select',
                options: acuityAppointmentTypes,
                optionLabel: 'name',
                optionValueKey: 'id',
                conditions: [
                  {
                    condition: ({ bypassTechCheck, acuityTechCheck }) => !bypassTechCheck && acuityTechCheck === true,
                    required: true
                  }
                ],
                onUpdate: onAppointmentTypeUpdate,
                disabled: disabledFn
              },
              {
                key: 'acuityCalendarId',
                label: 'Acuity Calendar',
                placeholder: 'Select Acuity Calendar',
                type: 'select',
                options: acuityCalendars,
                optionLabel: 'name',
                optionValueKey: 'id',
                conditions: [
                  {
                    condition: ({ acuityTechCheck, acuityAppointmentTypeId }) => acuityTechCheck === true && !!acuityAppointmentTypeId,
                    required: true
                  }
                ],
                // defaultValue: 'NEW',
                disabled: disabledFn
              },
              // {
              //   key: 'acuityCalendarName',
              //   label: 'Acuity Calendar Name',
              //   placeholder: 'Enter Calendar Name',
              //   type: 'text',
              //   conditions: [
              //     {
              //       condition: ({ acuityTechCheck }) => acuityTechCheck === true,
              //     },
              //     {
              //       condition: ({ acuityAppointmentTypeId }) => !!acuityAppointmentTypeId,
              //     },
              //     {
              //       condition: ({ acuityCalendarId }) => acuityCalendarId === 'NEW',
              //       required: true
              //     }
              //   ],
              // },
              {
                key: 'hasZoomMeeting',
                label: 'Has Zoom Meeting?',
                type: 'boolean',
                defaultValue: false,
                disabled: disabledFn
              },
              {
                key: 'overrideZoomName',
                label: 'Override Zoom Last Name?',
                type: 'boolean',
                conditions: [
                  {
                    condition: ({ type }) => type === 'Focus Group',
                    required: true
                  }
                ],
                defaultValue: false,
                disabled: disabledFn
              },
              {
                key: 'zoomHostId',
                label: 'Zoom Host',
                type: 'select',
                options: zoomUsers,
                optionLabel: 'email',
                optionValueKey: 'id',
                conditions: [
                  {
                    condition: ({ hasZoomMeeting }) => hasZoomMeeting === true,
                    required: true
                  }
                ],
                onUpdate: onZoomHostUpdate,
                disabled: disabledFn
              },
              {
                key: 'zoomMeetingId',
                label: 'Zoom Meeting',
                type: 'select',
                options: zoomUserMeetings,
                optionLabel: (opt) => opt.topic + (opt.start_time ? ` (${new Date(opt.start_time).toLocaleString()})` : ''),
                optionValueKey: 'id',
                conditions: [
                  {
                    condition: ({ hasZoomMeeting }) => hasZoomMeeting === true,
                    required: true
                  },
                  {
                    condition: ({ zoomHostId }) => !!zoomHostId,
                    required: true
                  }
                ],
                defaultValue: 'NEW',
                disabled: disabledFn
              },
            ],
            [
              {
                key: 'videoTitle',
                label: 'Event Title',
                placeholder: 'Enter Event Title (Displayed to Viewers)',
                type: 'text',
                maxLength: 50,
                required: true,
                disabled: disabledFn
              },
              {
                key: 'videoPlatform',
                label: 'Video Platform',
                placeholder: 'Select Video Platform',
                type: 'select',
                options: [
                  'Brightcove',
                  'ticktBox',
                  'YouTube',
                  // 'Twitch'
                ],
                multiple: false,
                conditions: [
                  {
                    condition: ({ type }) => type === 'Content',
                    required: true
                  }
                ],
                defaultValue: 'Brightcove',
                onUpdate: onVideoPlatformUpdate,
                disabled: disabledProjectFn
              },
              {
                key: 'videoId',
                subkey: 'youtube',
                label: 'YouTube Video',
                placeholder: 'Select Video',
                sublabel: youtubeSublabel,
                endComponent: youtubeEndComponent,
                type: 'text',
                maxLength: 11,
                // regexp: /^[\w\-]{11}$/,
                // required: true,
                disabled: disabledProjectVideoFn,
                onUpdate: onYoutubeVideoChange,
                customValidator: youtubeCustomValidator,
                conditions: [
                  {
                    condition: ({ type, videoPlatform, isLiveSession }) => type === 'Content' && videoPlatform === 'YouTube',
                    required: true
                  }
                ],
                // defaultValue: 'VBdQt9xaK'
              },
              {
                key: 'isLiveSession',
                label: 'Is YouTube Live?',
                type: 'boolean',
                conditions: [
                  {
                    condition: ({ type, videoPlatform }) => type === 'Content' && videoPlatform === 'YouTube'
                  }
                ],
                defaultValue: false,
                disabled: true
              },
              {
                key: 'multiSection',
                label: 'Multi-Section Content',
                type: 'boolean',
                defaultValue: false,
                conditions: [
                  {
                    condition: ({ type, videoPlatform }) => type === 'Content' && videoPlatform === 'Brightcove'
                  }
                ],
                onUpdate: onMultiSectionUpdate,
                disabled: disabledProjectVideoFn
              },
              {
                key: 'videoId',
                subkey: 'brightcove',
                label: 'Brightcove Video',
                placeholder: 'Select Video',
                type: 'select',
                options: bcVideos,
                optionLabel: 'name',
                optionValueKey: 'id',
                // required: true,
                conditions: [
                  {
                    condition: ({ type, videoPlatform, multiSection }) => type === 'Content' && videoPlatform === 'Brightcove' && !multiSection,
                    required: true
                  }
                ],
                onUpdate: onBCVideoUpdate,
                disabled: disabledProjectVideoFn
              },
              {
                key: 'brightcoveVideoTitle',
                label: 'Brightcove Video Title',
                placeholder: 'Enter Video Title',
                type: 'text',
                conditions: [
                  {
                    condition: ({ type, videoPlatform, multiSection, videoId }) => type === 'Content' && videoPlatform === 'Brightcove' && !multiSection && videoId === 'NEW',
                    required: true
                  }
                ],
              },
              {
                key: 'videoId',
                subkey: 'bc-playlist',
                label: 'Brightcove Playlist',
                placeholder: 'Select Playlist',
                type: 'select',
                options: bcPlaylists,
                optionLabel: 'name',
                optionValueKey: 'id',
                // required: true,
                displayComponent: (option) => () => (
                  <div style={{ display: "flex", flexDirection: "column", flexBasis: "100%", paddingLeft: '1rem' }}>
                    {option.videos.map(v => <Typography key={v.name} variant="caption">{v.name}</Typography>)}
                  </div>
                ),
                conditions: [
                  {
                    condition: ({ type, videoPlatform, multiSection }) => type === 'Content' && videoPlatform === 'Brightcove' && !!multiSection,
                    required: true
                  }
                ],
                onUpdate: onBCPlaylistUpdate,
                disabled: disabledProjectVideoFn
              },
              {
                key: 'brightcovePlaylistTitle',
                label: 'Brightcove Playlist Title',
                placeholder: 'Enter Playlist Title',
                type: 'text',
                conditions: [
                  {
                    condition: ({ type, videoPlatform, multiSection }) => type === 'Content' && videoPlatform === 'Brightcove' && !!multiSection,
                  },
                  {
                    condition: ({ videoId }) => videoId === 'NEW',
                    required: true
                  }
                ],
              },
              {
                key: 'brightcovePlaylistVideos',
                label: 'Brightcove Playlist Videos',
                placeholder: 'Select Playlist Videos',
                buttonLabel: 'Add Video',
                type: 'select-builder',
                options: bcVideos,
                optionLabel: 'name',
                optionValueKey: 'id',
                conditions: [
                  {
                    condition: ({ type, videoPlatform, multiSection, videoId }) => type === 'Content' && videoPlatform === 'Brightcove' && !!multiSection && videoId === 'NEW',
                    required: true
                  },
                ],
                onUpdate: onNewPlaylistUpdate
              },
              {
                key: 'videoId',
                subkey: 'ticktbox',
                label: 'ticktBox Video',
                placeholder: 'Select Video',
                type: 'select',
                options: tbVideos,
                optionLabel: 'videoTitle',
                optionValueKey: 'videoId',
                // required: true,
                conditions: [
                  {
                    condition: ({ type, videoPlatform }) => type === 'Content' && videoPlatform === 'ticktBox',
                    required: true
                  }
                ],
                // onUpdate: onTBVideoUpdate,
                disabled: disabledProjectVideoFn
              },
              {
                key: 'ticktboxVideoTitle',
                label: 'ticktBox Video Title',
                placeholder: 'Enter Video Title',
                type: 'text',
                conditions: [
                  {
                    condition: ({ type, videoPlatform, videoId }) => type === 'Content' && videoPlatform === 'ticktBox' && videoId === 'NEW',
                    required: true
                  },
                ],
              },
              {
                key: 'internalVideoName',
                label: 'Internal Video Name',
                placeholder: 'Enter Internal Name',
                sublabel: videoNameLabel,
                type: 'text-builder',
                staticLength: playlistLength || 1,
                conditions: [
                  {
                    condition: ({ type }) => type === 'Content'
                  }
                ],
                // options: bcVideos,
                // onUpdate: onNewPlaylistUpdate,
                disabled: disabledInternalNameFn
              },
              {
                key: 'techCheckVideoId',
                label: 'Tech Check Video',
                placeholder: 'Select Tech Check Video',
                type: 'select',
                options: bcVideosTC,
                optionLabel: 'name',
                optionValueKey: 'id',
                // required: true,
                conditions: [
                  {
                    condition: ({ type, videoPlatform, bypassTechCheck }) => type === 'Content' && videoPlatform === 'Brightcove' && !bypassTechCheck,
                    required: true
                  }
                ],
                defaultValue: '6302442846001',
                disabled: disabledVideoFn
              },
              {
                key: 'techCheckVideoId',
                label: 'Tech Check Video',
                placeholder: 'Select Tech Check Video',
                type: 'select',
                options: tbVideosTC,
                optionLabel: 'videoTitle',
                optionValueKey: 'videoId',
                // required: true,
                conditions: [
                  {
                    condition: ({ type, videoPlatform }) => type === 'Content' && videoPlatform === 'ticktBox',
                    required: true
                  }
                ],
                // defaultValue: '6217864765001',
                disabled: disabledVideoFn
              },
              {
                key: 'playerId',
                label: 'Player ID',
                type: 'text',
                regexp: /^\w{9}$/,
                // required: true,
                conditions: [
                  {
                    condition: ({ type, videoPlatform }) => type === 'Content' && videoPlatform === 'Brightcove',
                    required: true
                  }
                ],
                disabled: true,
                defaultValue: 'VBdQt9xaK'
              },
              {
                key: 'viewerLanguage',
                label: 'Viewer Language',
                type: 'select',
                placeholder: 'Select Viewer Language',
                options: languages,
                optionLabel: 'name',
                optionValueKey: 'code',
                onUpdate: onLanguageChanged,
                multiple: false,
                required: true,
                defaultValue: 'enUS', // set default to english
              },
              {
                key: 'allowFullscreen',
                label: 'Allow Viewer Fullscreen Option',
                type: 'boolean',
                conditions: [
                  {
                    condition: ({ type }) => type === 'Content'
                  }
                ],
                defaultValue: false,
                disabled: disabledFn
              },
              {
                key: 'autoStop',
                label: 'Automatic Stop?',
                type: 'boolean',
                conditions: [
                  {
                    condition: ({ type, multiSection }) => type === 'Content' && multiSection !== true
                  }
                ],
                defaultValue: false,
                disabled: disabledProjectVideoFn
              },
              {
                key: 'autoStopTime',
                label: 'Automatic Stop Time',
                type: 'hms-time',
                conditions: [
                  {
                    condition: ({ type, autoStop }) => type === 'Content' && autoStop === true,
                    required: true
                  }
                ],
                disabled: disabledProjectVideoFn
              },
              {
                key: 'hasSurvey',
                label: 'Has Survey?',
                type: 'boolean',
                defaultValue: false,
                disabled: disabledFn
              },
              {
                key: 'surveyPlacement',
                label: 'Survey Placement',
                type: 'select',
                options: [
                  { code: 'pre', label: "Pre-Video Survey" },
                  { code: 'post', label: "Post-Video Survey" },
                  { code: 'both', label: "Pre- & Post-Video Survey" },
                  { code: 'inter', label: "Inter- & Post-Video Survey" },
                  { code: 'all', label: "Pre-, Inter- & Post-Video Survey" }
                ],
                optionLabel: 'label',
                optionValueKey: 'code',
                multiple: false,
                conditions: [
                  {
                    condition: ({ hasSurvey }) => hasSurvey === true,
                    required: true
                  }
                ],
                disabledOptions: disabledVideoSurveysFn,
                disabled: disabledFn
              },
              {
                key: 'surveyType',
                label: 'Survey Type',
                type: 'select',
                options: [
                  'Qualtrics',
                  'Decipher'
                ],
                multiple: false,
                conditions: [
                  {
                    condition: ({ hasSurvey }) => hasSurvey === true,
                    required: true
                  }
                ],
                onUpdate: onSurveyTypeUpdate,
                disabled: disabledFn
              },
              {
                key: 'surveyId',
                label: 'Qualtrics Survey',
                type: 'select',
                options: qualtricsSurveys,
                optionLabel: 'name',
                optionValueKey: 'id',
                conditions: [
                  {
                    condition: ({ hasSurvey }) => hasSurvey === true,
                    required: true
                  },
                  {
                    condition: ({ surveyType }) => surveyType === 'Qualtrics',
                    required: true
                  }
                ],
                disabled: disabledFn
              },
              {
                key: 'surveyId',
                label: 'Decipher Survey',
                type: 'select',
                options: decipherSurveys,
                optionLabel: 'title',
                optionValueKey: 'path',
                conditions: [
                  {
                    condition: ({ hasSurvey }) => hasSurvey === true,
                    required: true
                  },
                  {
                    condition: ({ surveyType }) => surveyType === 'Decipher',
                    required: true
                  }
                ],
                disabled: disabledFn
              },
              {
                key: 'showCountdown',
                label: 'Show Start Time Coundown?',
                type: 'boolean',
                defaultValue: true,
                conditions: [
                  {
                    condition: ({ type }) => type === 'Content'
                  }
                ],
                disabled: disabledFn
              },
              {
                key: 'showQuiz',
                label: 'Show Movie Quiz before start?',
                type: 'boolean',
                defaultValue: true,
                conditions: [
                  {
                    condition: ({ type }) => type === 'Content'
                  }
                ],
                disabled: disabledFn
              },
              {
                key: 'techCheckNote',
                label: 'Tech Check Note',
                placeholder: '',
                type: 'text',
                maxLength: 500,
                required: false,
                disabled: disabledFn
              }
            ],
            // {
            //   key: 'videoMarkers',
            //   label: 'Video Markers',
            //   type: 'marker-builder',
            //   defaultValue: []
            // },
          ]
        },
        // {
        //   key: 'emailTemplatesTab',
        //   label: 'Email',
        //   disabled: true,
        //   fields: [
        //     {
        //       key: 'viewerEmailTemplate',
        //       label: 'Viewers Email',
        //       type: 'text',
        //       // large: true,
        //       required: true,
        //       defaultValue: 'Below is your private link to our virtual event:'
        //     },
        //     {
        //       key: 'clientEmailTemplate',
        //       label: 'Client Email',
        //       type: 'text',
        //       // large: true,
        //       required: true,
        //       defaultValue: 'Below is your private link for live dial access:'
        //     }
        //   ]
        // },
        {
          key: 'securityTab',
          label: 'Security',
          fields: [
            {
              key: 'include2fa',
              label: 'Require 2-Factor Authentication',
              type: 'boolean',
              defaultValue: false,
              disabled: disabledFn
            },
            {
              key: 'allowMobileDevices',
              label: 'Allow Mobile Devices',
              type: 'boolean',
              defaultValue: false,
              disabled: disabledFn
            },
            {
              key: 'allowedBrowsers',
              label: 'Allowed Browsers',
              placeholder: 'Select Allowed Browsers',
              type: 'select',
              options: [
                'Chrome',
                'Edge',
                'Safari',
                // 'Firefox'
              ],
              multiple: true,
              filterSelected: true,
              required: true,
              defaultValue: ['Chrome'],
              disabled: disabledFn
            },
            {
              key: 'enableGeoRestriction',
              label: 'Enable Geo-Restriction',
              type: 'boolean',
              defaultValue: false,
              disabled: disabledFn
            },
            {
              key: 'allowedCountries',
              label: 'Allowed Countries',
              type: 'select',
              placeholder: 'Select Allowed Countries',
              options: countriesJSON,
              optionLabel: 'name',
              optionValueKey: 'alpha-2',
              // valueKey: 'alpha-2',
              multiple: true,
              // required: true,
              conditions: [
                {
                  condition: ({ enableGeoRestriction }) => enableGeoRestriction === true,
                  required: true
                }
              ],
              defaultValue: ['US'],
              onUpdate: onAllowedCountriesUpdate,
              disabled: disabledFn
            },
            {
              key: 'enhancedGeoRestriction',
              label: 'Enhanced Geo-Restriction',
              type: 'boolean',
              conditions: [
                {
                  condition: ({ enableGeoRestriction, allowedCountries }) => enableGeoRestriction === true && allowedCountries?.length
                }
              ],
              defaultValue: false,
              disabled: disabledFn
            },
            {
              key: 'subGeoRestrictionType',
              label: 'Geo-Restriction Type',
              type: 'select',
              placeholder: 'Select Enhanced Geo-Restriction Type',
              options: [
                {
                  type: 'region',
                  label: 'Region/State'
                },
                {
                  type: 'metroCode',
                  label: 'Metro Area (US Only)'
                },
                {
                  type: 'postalCode',
                  label: 'Zip/Postal Code'
                },
                {
                  type: 'ip',
                  label: 'IP Address'
                }
              ],
              optionLabel: 'label',
              optionValueKey: 'type',
              multiple: false,
              conditions: [
                {
                  condition: ({ enableGeoRestriction, enhancedGeoRestriction, allowedCountries }) => enableGeoRestriction === true && allowedCountries?.length && enhancedGeoRestriction === true,
                  required: true
                }
              ],
              onUpdate: onSubGeoFilterUpdate,
              disabledOptions: disabledSubGeoFilterFn,
              disabled: disabledFn
            },
            {
              key: 'subGeoRestriction',
              subkey: 'regions',
              label: 'Allowed Regions',
              type: 'select',
              placeholder: 'Select Allowed Regions',
              options: regionOptions,
              optionLabel: 'name',
              optionValueKey: 'id',
              // valueKey: 'alpha-2',
              groupBy: 'countryName',
              multiple: true,
              // required: true,
              conditions: [
                {
                  condition: ({ enableGeoRestriction, enhancedGeoRestriction, subGeoRestrictionType, allowedCountries }) => enableGeoRestriction === true && allowedCountries?.length && enhancedGeoRestriction === true && subGeoRestrictionType === 'region',
                  required: true
                }
              ],
              disabled: disabledFn
            },
            {
              key: 'subGeoRestriction',
              subkey: 'metroCodes',
              label: 'Allowed Metro Areas',
              type: 'select',
              placeholder: 'Select Allowed Metro Areas',
              options: metroCodesJSON,
              optionLabel: 'name',
              optionValueKey: 'id',
              // valueKey: 'alpha-2',
              multiple: true,
              // required: true,
              conditions: [
                {
                  condition: ({ enableGeoRestriction, enhancedGeoRestriction, subGeoRestrictionType, allowedCountries }) => enableGeoRestriction === true && allowedCountries?.length && enhancedGeoRestriction === true && subGeoRestrictionType === 'metroCode',
                  required: true
                }
              ],
              disabled: disabledFn
            },
            {
              key: 'subGeoRestriction',
              subkey: 'postalCodes',
              label: 'Allowed Postal Codes',
              type: 'chip',
              placeholder: 'Enter Allowed Postal/Zip Codes',
              regexp: /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/i,
              conditions: [
                {
                  condition: ({ enableGeoRestriction, enhancedGeoRestriction, subGeoRestrictionType, allowedCountries }) => enableGeoRestriction === true && allowedCountries?.length && enhancedGeoRestriction === true && subGeoRestrictionType === 'postalCode',
                  required: true
                }
              ],
              disabled: disabledFn
            },
            {
              key: 'subGeoRestriction',
              subkey: 'IPs',
              label: 'Allowed IP Addresses',
              type: 'chip',
              placeholder: 'Enter Allowed IP Addresses',
              regexp: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
              conditions: [
                {
                  condition: ({ enableGeoRestriction, enhancedGeoRestriction, subGeoRestrictionType, allowedCountries }) => enableGeoRestriction === true && allowedCountries?.length && enhancedGeoRestriction === true && subGeoRestrictionType === 'ip',
                  required: true
                }
              ],
              disabled: disabledFn
            },
            {
              key: 'watermarkOpacity',
              label: 'Watermark Opacity',
              placeholder: '##',
              type: 'percent',
              defaultValue: '15',
              // required: true
              conditions: [
                {
                  condition: ({ type, videoPlatform, isLiveSession }) => type === 'Content' && videoPlatform !== 'YouTube',
                  required: true
                }
              ],
              disabled: disabledFn
            },
            {
              key: 'watermarkStyle',
              label: 'Watermark Style',
              placeholder: 'Select Watermark Style',
              type: 'select',
              options: [
                'Full',
                'Tiled'
              ],
              multiple: false,
              conditions: [
                {
                  condition: ({ type, videoPlatform, isLiveSession }) => type === 'Content' && videoPlatform !== 'YouTube',
                  required: true
                }
              ],
              defaultValue: 'Full',
              disabled: disabledFn
            },
            {
              key: 'preventLateEntry',
              label: 'Prevent Late Entry?',
              type: 'boolean',
              defaultValue: true,
              disabled: disabledFn
            },
            {
              key: 'lateEntryTimeLimit',
              label: 'Late Entry Limit',
              placeholder: '##',
              type: 'minutes',
              // regexp: /[0-9]{4}/,
              defaultValue: 15,
              conditions: [
                {
                  condition: ({ preventLateEntry }) => preventLateEntry === true,
                  required: true
                }
              ],
              disabled: disabledFn
            },
            {
              key: 'limitDisconnect',
              label: 'Limit Disconnects?',
              type: 'boolean',
              defaultValue: true,
              disabled: disabledFn
            },
            {
              key: 'disconnectTimeLimit',
              label: 'Disconnect Time Limit',
              placeholder: '##',
              type: 'minutes',
              defaultValue: 5,
              conditions: [
                {
                  condition: ({ limitDisconnect }) => limitDisconnect === true,
                  required: true
                }
              ],
              disabled: disabledFn
            },
            {
              key: 'disconnectCountLimit',
              label: 'Disconnect Count Limit',
              placeholder: '##',
              type: 'number',
              defaultValue: 5,
              minValue: 0,
              maxValue: 100,
              conditions: [
                {
                  condition: ({ limitDisconnect }) => limitDisconnect === true,
                  required: true
                }
              ],
              disabled: disabledFn
            },
            {
              key: 'clientLinkExpiration',
              label: 'Client Link Expiration',
              placeholder: '##',
              type: 'number',
              suffix: 'days',
              defaultValue: 7,
              minValue: 2,
              maxValue: 90,
              conditions: [
                {
                  condition: ({ clientDialViewer }) => clientDialViewer === true,
                  required: true
                }
              ],
              disabled: disabledFn
            },
            {
              key: 'clientMagicLink',
              label: 'Allow Client Shared Link Access',
              type: 'boolean',
              defaultValue: false,
              disabled: disabledProjectFn || disabledFn
            },
            {
              key: 'magicLinkUrl',
              label: ' ',
              type: 'display',
              endComponent: clipboardEndComponent,
              conditions: [
                {
                  condition: ({ clientMagicLink, magicLinkUrl }) => !!(clientMagicLink && magicLinkUrl)
                }
              ],
              disabled: disabledProjectFn || disabledFn,
            },
            {
              key: 'requirePasscode',
              label: 'Require Passcode',
              type: 'boolean',
              defaultValue: false,
              conditions: [
                {
                  condition: ({ clientMagicLink }) => !!clientMagicLink
                }
              ],
              disabled: disabledProjectFn || disabledFn
            },
            {
              key: 'magicLinkPasscode',
              label: 'Magic Link Passcode',
              type: 'display',
              conditions: [
                {
                  condition: ({ requirePasscode, magicLinkPasscode }) => !!(requirePasscode && magicLinkPasscode)
                }
              ],
              disabled: disabledProjectFn || disabledFn
            },
            // {
            //   key: 'requireApproval',
            //   label: 'Enable Waiting Room',
            //   type: 'boolean',
            //   defaultValue: false,
            //   conditions: [
            //     {
            //       condition: ({ clientMagicLink }) => !!clientMagicLink
            //     }
            //   ]
            // },
            {
              key: 'requireEmailValidation',
              label: 'Require Email Validation',
              type: 'boolean',
              defaultValue: false,
              conditions: [
                {
                  condition: ({ clientMagicLink }) => !!clientMagicLink
                }
              ],
              disabled: disabledProjectFn || disabledFn
            }
          ]
        },
        {
          key: 'chatSettingsTab',
          label: 'Chat',
          disabled: !allowFullEdit,
          fields: [
            {
              key: 'chimeChatChannels',
              label: 'Chat Channels',
              type: 'chat-channel-builder',
              keyLabel: '',
              defaultValue: [
                {
                  name: 'Event Staff',
                  internal: true,
                  default: true,
                  type: 'group',
                  userAccess: [],
                  staffAccess: [
                    "session",
                    "audience",
                    "tc",
                    "balance",
                    "client",
                    "dial",
                    "video"
                  ]
                },
                {
                  name: 'Tech Check',
                  internal: true,
                  type: 'group',
                  userAccess: [],
                  staffAccess: [
                    "session",
                    "tc"
                  ]
                },
                {
                  name: 'VIP Lounge',
                  internal: false,
                  type: 'group',
                  userAccess: [
                    'Client'
                  ],
                  staffAccess: [
                    "session",
                    "client"
                  ]
                },
                {
                  name: 'Tech Support (VIP)',
                  internal: false,
                  type: 'private',
                  userAccess: [
                    'Client'
                  ],
                  staffAccess: [
                    "session",
                    "client"
                  ]
                }
              ],
            }
          ]
        },
        {
          key: 'segmentsTab',
          label: 'Segments',
          disabled: !allowFullEdit,
          fields: [
            [
              {
                key: 'audienceSegments',
                label: 'Audience Segments',
                type: 'question-builder',
                keyLabel: '',
                disabled: disabledProjectFn,
                goals: currentGoals,
                showGoals: true,
                showTerminate: showTerminate,
                onUpdate: onAudienceSegmentsUpdate,
                defaultValue: [
                  {
                    questionKey: 'Gender',
                    question: 'What is your identifying gender?',
                    multiple: false,
                    hasCrosstab: false,
                    crosstabKeys: [],
                    freeNumeric: false,
                    answers: [
                      {
                        answer: 'Male',
                        goal: 50
                      },
                      {
                        answer: 'Female',
                        goal: 50
                      }
                    ],
                  },
                  {
                    questionKey: 'Age',
                    question: 'What is your current age?',
                    multiple: false,
                    hasCrosstab: true,
                    crosstabKeys: ['Gender'],
                    freeNumeric: true,
                    answers: [
                      {
                        answer: '16-24',
                        goal: 25
                      },
                      {
                        answer: '25-34',
                        goal: 25
                      },
                      {
                        answer: '35-44',
                        goal: 15
                      },
                      {
                        answer: '45-54',
                        goal: 15
                      },
                      {
                        answer: '55-64',
                        goal: 10
                      },
                      {
                        answer: '65-99',
                        goal: 10
                      }
                    ],
                  },
                  {
                    questionKey: 'Ethnicity',
                    question: 'Which best represents your ethnic or racial background?',
                    multiple: false,
                    hasCrosstab: false,
                    crosstabKeys: [],
                    freeNumeric: false,
                    answers: [
                      {
                        answer: 'African-American or Black',
                        goal: 20
                      },
                      {
                        answer: 'Asian or Pacific Islander',
                        goal: 20
                      },
                      {
                        answer: 'Caucasian or White',
                        goal: 20
                      },
                      {
                        answer: 'Hispanic or Latino',
                        goal: 20
                      },
                      {
                        answer: 'Native American',
                        goal: 20
                      },
                      {
                        answer: 'Other',
                        goal: 0
                      }
                    ],
                  },
                  {
                    questionKey: 'Time Zone',
                    question: 'What time zone do you currently live in?',
                    multiple: false,
                    hasCrosstab: false,
                    crosstabKeys: [],
                    freeNumeric: false,
                    answers: [
                      {
                        answer: 'Pacific Time',
                        goal: 25
                      },
                      {
                        answer: 'Mountain Time',
                        goal: 25
                      },
                      {
                        answer: 'Central Time',
                        goal: 25
                      },
                      {
                        answer: 'Eastern Time',
                        goal: 25
                      },
                      {
                        answer: 'I am not in the United States',
                        goal: 0,
                        terminate: true
                      }
                    ],
                  },
                ],
              },
              {
                key: 'virtualSegments',
                label: 'Virtual Segments',
                type: 'chart-segment-builder',
                options: audienceSegments,
                disabled: disabledProjectFn || disabledFn,
                showGoals: true,
                virtualSegments: [
                  {
                    questionKey: 'Session ID',
                    dataKey: 'sessionId',
                    condition: 'split'
                  },
                  {
                    questionKey: 'Tune Out',
                    dataKey: 'button',
                    dataValue: 'R',
                    condition: '==='
                  },
                  {
                    questionKey: 'No Tune Out',
                    dataKey: 'button',
                    dataValue: 'R',
                    condition: '!=='
                  },
                  {
                    questionKey: 'Caucasian',
                    dataKey: 'Ethnicity',
                    dataValue: 'Caucasian/White',
                    condition: '==='
                  },
                  {
                    questionKey: 'Non Caucasian',
                    dataKey: 'Ethnicity',
                    dataValue: 'Caucasian/White',
                    condition: '!=='
                  },
                  {
                    questionKey: 'African-American',
                    dataKey: 'Ethnicity',
                    dataValue: 'African-American or Black',
                    condition: '==='
                  },
                  {
                    questionKey: 'Non African-American',
                    dataKey: 'Ethnicity',
                    dataValue: 'African-American or Black',
                    condition: '!=='
                  },
                  {
                    questionKey: 'AA & Hisp',
                    dataKey: 'Ethnicity',
                    dataValue: ['African-American or Black', 'Hispanic or Latino'],
                    condition: '==='
                  },
                  {
                    questionKey: 'Asian',
                    dataKey: 'Ethnicity',
                    dataValue: 'Asian or Pacific Islander',
                    condition: '==='
                  },
                  {
                    questionKey: 'Scored >= 850',
                    dataKey: 'score',
                    dataValue: 850,
                    condition: '>='
                  },
                  {
                    questionKey: 'Scored <= 150',
                    dataKey: 'score',
                    dataValue: 150,
                    condition: '<='
                  }
                ],
                keyLabel: ''
              },
              {
                key: 'hasQualifyingSegment',
                label: 'Has Qualifying Segment?',
                type: 'boolean',
                defaultValue: false,
                conditions: [
                  {
                    condition: ({ externalRecruit, recruitPlatform, hasProject }) => externalRecruit === true && recruitPlatform === "QTC" && hasProject === true
                  }
                ],
                onUpdate: onHasQualifyingSegmentUpdate,
                // disabled: disabledProjectFn
              },
              {
                key: 'qualifyingSegment',
                label: 'Session Qualifying Segment',
                type: 'segment-selector',
                options: audienceSegments,
                conditions: [
                  {
                    condition: ({ externalRecruit, recruitPlatform, hasProject, hasQualifyingSegment }) => externalRecruit === true && recruitPlatform === "QTC" && hasProject === true && hasQualifyingSegment === true,
                    required: true
                  }
                ],
                defaultValue: {},
                disabled: disabledFn
              },
            ],
            [
              {
                key: 'confirmGoal',
                label: 'Confirm Goal',
                placeholder: '###',
                type: 'number',
                defaultValue: 120,
                minValue: 0,
                maxValue: 1000,
                disabled: ({ id, started }) => allowFullEdit ? (!!id && !!started) : true
              },
              {
                key: 'seatGoal',
                label: 'Seat Goal',
                placeholder: '###',
                type: 'number',
                defaultValue: 120,
                minValue: 0,
                maxValue: 1000,
                disabled: ({ id, started }) => allowFullEdit ? (!!id && !!started) : true
              },
              {
                key: 'focusGroupGoal',
                label: 'Focus Group Goal',
                placeholder: '###',
                type: 'number',
                defaultValue: 120,
                minValue: 0,
                maxValue: 1000,
                disabled: ({ id, started }) => allowFullEdit ? (!!id && !!started) : true
              }
            ]
          ]
        },
        {
          key: 'dialTab',
          label: 'Audience Feedback',
          // disabled: disabledFGFn,
          fields: [
            {
              key: 'isDialSession',
              label: 'Real-Time Audience Feedback',
              type: 'boolean',
              defaultValue: false,
              disabled: disabledRTFeedback
            },
            {
              key: 'dialMode',
              label: 'Activity Type',
              type: 'select',
              placeholder: 'Select Activity Type',
              options: [
                { code: 'score', label: "Dial" },
                { code: 'symbol', label: "Emoji" },
                { code: 'buttonOnly', label: "Buttons Only"}
              ],
              optionLabel: 'label',
              optionValueKey: 'code',
              multiple: false,
              onUpdate: onDialModeChanged,
              conditions: [
                {
                  condition: ({ isDialSession }) => isDialSession === true,
                  required: true
                }
              ],
              defaultValue: 'score',
              disabled: disabledProjectFn || disabledFn
            },
            {
              key: 'scoreBase',
              label: 'Max Score',
              type: 'number',
              minValue: 10,
              maxValue: 1000,
              conditions: [
                {
                  condition: ({ isDialSession, dialMode }) => isDialSession === true && dialMode === 'score',
                  required: true
                }
              ],
              defaultValue: 100,
              disabled: disabledProjectFn || disabledFn
            },
            {
              key: 'dialStyle',
              label: 'Knob Style',
              type: 'select',
              options: [
                'Chicken Head (ASI)',
                'Flat Faced',
                'Embedded Slider',
                'Emoji'
              ],
              multiple: false,
              disabled: disabledFn,
              disabledOptions: disabledDialOptions,
              conditions: [
                {
                  condition: ({ isDialSession, dialMode }) => isDialSession === true && dialMode === 'score',
                  required: true
                }
              ],
              defaultValue: 'Embedded Slider'
            },
            // {
            //   key: 'dialBranding',
            //   label: 'Branding',
            //   type: 'select',
            //   options: [
            //     'Preview Free Movies',
            //     'ViewerVoice TV',
            //     'EngineWorks'
            //   ],
            //   multiple: false,
            //   conditions: [
            //     {
            //       key: 'isDialSession',
            //       value: true,
            //       required: true
            //     },
            //     {
            //       key: 'dialStyle',
            //       value: (value) => value !== 'Embedded Slider',
            //       required: true
            //     }
            //   ],
            //   // required: true,
            //   defaultValue: 'Preview Free Movies',
            //   displayComponent: {
            //     'EngineWorks': () => <img height='45px' src={ewLogo} />,
            //     'Preview Free Movies': () => <img height='45px' src={pfmLogo} />,
            //     'ViewerVoice TV': () => <img height='45px' src={vvtvLogo} />
            //   }
            // },
            {
              key: 'symbolOptions',
              label: 'Emoji Options',
              type: 'emoji-builder',
              conditions: [
                {
                  condition: ({ isDialSession, dialMode }) => isDialSession === true && dialMode === 'symbol',
                  required: true
                }
              ],
              disabled: disabledProjectFn || disabledFn,
              defaultValue: defaultSymbolsList,
              required: true
            },
            // {
            //   key: 'inputTime',
            //   label: 'Input Time (Seconds)',
            //   type: 'number',
            //   minValue: 0,
            //   maxValue: 10,
            //   conditions: [
            //     {
            //       condition: ({ isDialSession, dialMode }) => isDialSession === true && dialMode === 'symbol',
            //       required: true
            //     }
            //   ],
            //   defaultValue: 1
            // },
            {
              key: 'sliderKnob',
              label: 'Slider Knob',
              type: 'select',
              options: [
                'Clapperboard',
                'Film Reel',
                'Netflix',
                'Hulu',
                'Warner Bros',
                'Amazon',
                'DC',
                'Mickey Ears',
                'Game Controller',
                'Pacman',
                'Xbox',
                'Playstation',
                'Nintendo',
                'Nintendo Switch',
                'Nintendo Network',
                'Epic Games',
                'Riot Games',
                'Football',
                'Basketball',
                'Soccer Ball'
              ],
              multiple: false,
              conditions: [
                {
                  condition: ({ isDialSession, dialMode }) => isDialSession === true && dialMode === 'score',
                  required: true
                },
                {
                  condition: ({ dialStyle }) => dialStyle === 'Embedded Slider',
                  required: true
                }
              ],
              defaultValue: 'Clapperboard',
              disabled: disabledFn,
              displayComponent: {
                'Clapperboard': MovieIcon,
                'Film Reel': RiMovie2Fill,
                'Netflix': SiNetflix,
                // 'Hulu': SiHulu,
                'Warner Bros': SiWarnerbros,
                'Amazon': SiAmazon,
                'DC': SiDcentertainment,
                'Mickey Ears': RiMickeyFill,
                'Game Controller': SportsEsportsIcon,
                'Pacman': RiGameFill,
                'Xbox': SiXbox,
                'Playstation': SiPlaystation,
                'Nintendo': SiNintendo,
                'Nintendo Switch': SiNintendoswitch,
                'Nintendo Network': SiNintendonetwork,
                'Epic Games': SiEpicgames,
                'Riot Games': SiRiotgames,
                'Football': FaFootballBall,
                'Basketball': GiBasketballBall,
                'Soccer Ball': IoMdFootball
              }
            },
            {
              key: 'sliderKnobColor',
              label: 'Knob Color',
              type: 'color',
              // disableAlpha: true,
              // hideTextfield: true,
              conditions: [
                {
                  condition: ({ isDialSession, dialMode }) => isDialSession === true && dialMode === 'score',
                  required: true
                },
                {
                  condition: ({ dialStyle }) => dialStyle === 'Embedded Slider',
                  required: true
                }
              ],
              disabled: disabledFn,
              defaultValue: '#F44E3B'
            },
            {
              key: 'gaugeStyle',
              label: 'Gauge Style',
              type: 'select',
              options: [
                'With Shapes',
                'Without Shapes'
              ],
              multiple: false,
              conditions: [
                {
                  condition: ({ isDialSession, dialMode }) => isDialSession === true && dialMode === 'score',
                  required: true
                },
                {
                  condition: ({ dialStyle }) => dialStyle !== 'Embedded Slider',
                  required: true
                }
              ],
              defaultValue: 'With Shapes',
              disabled: disabledFn
            },
            {
              key: 'dialSnap',
              label: 'Stop at Shapes',
              type: 'boolean',
              conditions: [
                {
                  condition: ({ isDialSession, dialMode }) => isDialSession === true && dialMode === 'score'
                },
                {
                  condition: ({ dialStyle }) => dialStyle !== 'Embedded Slider',
                },
                {
                  condition: ({ gaugeStyle }) => gaugeStyle === 'With Shapes',
                }
              ],
              defaultValue: false,
              disabled: disabledFn
            },
            {
              key: 'dialMotion',
              label: 'Motion Response',
              type: 'select',
              options: [
                'Stepwise',
                'Fluid'
              ],
              multiple: false,
              conditions: [
                {
                  condition: ({ isDialSession, dialMode }) => isDialSession === true && dialMode === 'score',
                  required: true
                }
              ],
              defaultValue: 'Stepwise',
              disabled: disabledFn
            },
            {
              key: 'numberOfMeters',
              label: 'Number of Steps',
              type: 'number',
              minValue: 0,
              maxValue: 36,
              conditions: [
                {
                  condition: ({ isDialSession, dialMode }) => isDialSession === true && dialMode === 'score',
                  required: true
                }
              ],
              defaultValue: 19,
              disabled: disabledFn
            },
            {
              key: 'noDialArrowKeys',
              label: 'Disable Dial Keyboard Control (No Arrow Keys)',
              type: 'boolean',
              conditions: [
                {
                  condition: ({ isDialSession, dialStyle, dialMode }) => isDialSession === true && dialStyle === 'Embedded Slider' && dialMode === 'score'
                }
              ],
              defaultValue: false,
              disabled: disabledFn
            },
            {
              key: 'includeBreaks',
              label: 'Include Break Button',
              type: 'boolean',
              conditions: [
                {
                  condition: ({ isDialSession }) => isDialSession === true
                }
              ],
              defaultValue: false,
              disabled: disabledFn
            },
            {
              key: 'maxBreaks',
              label: 'Break Limit',
              type: 'number',
              minValue: 1,
              maxValue: 100,
              defaultValue: 4,
              conditions: [
                {
                  condition: ({ isDialSession, includeBreaks }) => isDialSession === true && includeBreaks === true,
                  required: true
                }
              ],

            },
            {
              key: 'includeShoutOuts',
              label: 'Include Comment Button',
              type: 'boolean',
              conditions: [
                {
                  condition: ({ isDialSession }) => isDialSession === true
                }
              ],
              defaultValue: false,
              disabled: disabledFn
            },
            {
              key: 'includeTuneOut',
              label: 'Include Tune Out Button',
              type: 'boolean',
              conditions: [
                {
                  condition: ({ isDialSession }) => isDialSession === true
                }
              ],
              defaultValue: true,
              disabled: disabledFn
            },
            {
              key: 'limitTuneOut',
              label: 'Limit Tune Out Display',
              type: 'boolean',
              conditions: [
                {
                  condition: ({ isDialSession, includeTuneOut }) => isDialSession === true && includeTuneOut === true
                }
              ],
              defaultValue: false,
              disabled: disabledFn
            },
            {
              key: 'startTuneOutTime',
              label: 'Show Tune Out',
              type: 'number',
              suffix: 'minutes after start',
              minValue: 0,
              maxValue: 10,
              conditions: [
                {
                  condition: ({ isDialSession, includeTuneOut, limitTuneOut }) => isDialSession === true && includeTuneOut === true && limitTuneOut === true,
                  required: true
                }
              ],
              defaultValue: 2,
              disabled: disabledFn
            },
            {
              key: 'stopTuneOutTime',
              label: 'Hide Tune Out',
              type: 'number',
              suffix: 'minutes before end',
              minValue: 0,
              maxValue: 10,
              conditions: [
                {
                  condition: ({ isDialSession, includeTuneOut, limitTuneOut }) => isDialSession === true && includeTuneOut === true && limitTuneOut === true,
                  required: true
                }
              ],
              defaultValue: 2,
              disabled: disabledFn
            },
            {
              key: 'askTuneOutReason',
              label: 'Prompt for Tune Out Reason',
              type: 'boolean',
              conditions: [
                {
                  condition: ({ isDialSession, includeTuneOut }) => isDialSession === true && includeTuneOut === true
                }
              ],
              defaultValue: false,
              disabled: disabledFn
            },
            {
              key: 'maxReasonLength',
              label: 'Button Reason Max Length',
              placeholder: '###',
              type: 'number',
              suffix: 'characters',
              defaultValue: 140,
              minValue: 50,
              maxValue: 256,
              conditions: [
                {
                  condition: ({ isDialSession, includeTuneOut, askTuneOutReason, includeBreaks, includeShoutOuts }) => isDialSession === true && ((includeTuneOut === true && askTuneOutReason === true) || includeBreaks || includeShoutOuts),
                  required: true
                }
              ],
              disabled: disabledFn
            },
            {
              key: 'maxReasonTime',
              label: 'Button Reason Max Time',
              placeholder: '##',
              type: 'number',
              suffix: 'seconds',
              defaultValue: 45,
              minValue: 30,
              maxValue: 60,
              conditions: [
                {
                  condition: ({ isDialSession, includeTuneOut, askTuneOutReason, includeBreaks, includeShoutOuts }) => isDialSession === true && ((includeTuneOut === true && askTuneOutReason === true) || includeBreaks || includeShoutOuts),
                  required: true
                }
              ],
              disabled: disabledFn
            },
            {
              key: 'inactiveReminder',
              label: 'Include Inactivity Reminder',
              type: 'boolean',
              conditions: [
                {
                  condition: ({ isDialSession, dialMode }) => isDialSession === true && dialMode !== 'buttonOnly'
                }
              ],
              defaultValue: true,
              disabled: disabledFn
            },
            {
              key: 'inactiveInterval',
              label: 'Remind After',
              type: 'number',
              suffix: 'seconds of Inactivity',
              minValue: 30,
              maxValue: 999,
              conditions: [
                {
                  condition: ({ isDialSession }) => isDialSession === true,
                  required: true
                },
                {
                  condition: ({ inactiveReminder }) => inactiveReminder === true,
                  required: true
                }
              ],
              defaultValue: 120,
              disabled: disabledFn
            },
            {
              key: 'inactiveMessage',
              label: 'Reminder Message',
              placeholder: 'Enter Inactivity Reminder Message',
              type: 'text',
              maxLength: 140,
              conditions: [
                {
                  condition: ({ isDialSession }) => isDialSession === true,
                  required: true
                },
                {
                  condition: ({ inactiveReminder }) => inactiveReminder === true,
                  required: true
                }
              ],
              defaultValue: 'Please continue to move your dial',
              disabled: disabledFn
            },
            {
              key: 'feedbackWindowBottomRight',
              label: 'Show Feedback Window Bottom Right',
              type: 'boolean',
              conditions: [
                {
                  condition: ({ isDialSession }) => isDialSession === true
                }
              ],
              defaultValue: false,
              disabled: disabledFn
            },
            {
              key: 'clientDialViewer',
              label: 'Allow Client access to Live Graph',
              type: 'boolean',
              conditions: [
                {
                  condition: ({ isDialSession, dialMode }) => isDialSession === true
                }
              ],
              defaultValue: true,
              disabled: disabledFn && disabledClientDialViewerFn
            },
          ]
        },
      ]}
    // fields={[
    // ]}
    />
  );
})

export default SessionModal;