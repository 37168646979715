import React, { useCallback } from 'react';
import TextInputField from './TextInputField'
import MultiTextInputField from './MultiTextInputField';
import RichTextInputField from './RichTextInputField'
import SelectInputField from './SelectInputField';
import MultiSelectInputField from './MultiSelectInputField';
import TimeField from './TimeField';
import DateField from './DateField';
// import DateRangeField from './DateRangeField';
import DateTimeField from './DateTimeField';
import CheckboxField from './CheckboxField';
import QuestionField from './QuestionField';
import ChartSegmentField from './ChartSegmentField';
import TimeMarkerField from './TimeMarkerField';
import MultiQuestionField from './MultiQuestionField';
import MultiChartSegmentField from './MultiChartSegmentField';
import MultiTimeMarkerField from './MultiTimeMarkerField';
import HMSTimeField from './HMSTimeField';
import NumberInputField from './NumberInputField';
import { useFieldValidator } from '../hooks/validators';
import PercentInputField from './PercentInputField';
import MinuteInputField from './MinuteInputField';
import ColorInputField from './ColorInputField';
import ChipInputField from './ChipInputField';
import ChatChannelField from './ChatChannelField';
import MultiChatChannelField from './MultiChatChannelField';
import TransferListField from './TransferListField';
import SegmentSelectorField from './SegmentSelectorField';
import PaymentTypeSelectorField from './PaymentTypeSelectorField';
import MultiRewardsField from './MultiRewardsField';
import RewardsField from './RewardsField';
import PhotoUploadField from './PhotoUploadField';
import DisplayField from './DisplayField'
import EmojiInputField from './EmojiInputField';
import MultiEmojiInputField from './MultiEmojiInputField';
import RadioInputField from './RadioInputField';

const isEqual = require('lodash/isEqual');

const StandardField = React.memo(({ type, value, onChangeHandler, onButtonClickHandler, ...restProps }) => {
  // console.log('render field', type, value, restProps)

  useFieldValidator(type, value, { ...restProps })

  const onChange = useCallback((e, v, k) => onChangeHandler(e, v, restProps.fieldKey, restProps.onUpdate, k), [onChangeHandler, restProps.fieldKey, restProps.onUpdate])
  const onButtonClick = useCallback(() => restProps.buttonAction && onButtonClickHandler(restProps.buttonAction), [onButtonClickHandler, restProps.buttonAction])

  switch (type) {
    case 'date':
      return <DateField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    // case 'date-range':
    //   return <DateRangeField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'time':
      return <TimeField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'datetime':
      return <DateTimeField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'select':
      return <SelectInputField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'boolean':
      return <CheckboxField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'question':
      return <QuestionField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'chart-segment':
      return <ChartSegmentField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'marker':
      return <TimeMarkerField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'segment-selector':
      return <SegmentSelectorField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'chat-channel':
      return <ChatChannelField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'payment-types':
      return <PaymentTypeSelectorField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'rewards':
      return <RewardsField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'emoji':
      return <EmojiInputField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'text-builder':
      return <MultiTextInputField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'select-builder':
      return <MultiSelectInputField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'question-builder':
      return <MultiQuestionField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'chart-segment-builder':
      return <MultiChartSegmentField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'marker-builder':
      return <MultiTimeMarkerField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'chat-channel-builder':
      return <MultiChatChannelField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'rewards-builder':
      return <MultiRewardsField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'emoji-builder':
      return <MultiEmojiInputField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'transfer-list':
      return <TransferListField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'hms-time':
      return <HMSTimeField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'number':
      return <NumberInputField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'percent':
      return <PercentInputField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'minutes':
      return <MinuteInputField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'color':
      return <ColorInputField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'chip':
      return <ChipInputField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'photo':
      return <PhotoUploadField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'radio':
      return <RadioInputField value={value} onChange={onChange} {...restProps}/>
    case 'rich-text':
      return <RichTextInputField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
    case 'display':
      return <DisplayField value={value} {...restProps} />
    case 'text':
    default:
      return <TextInputField value={value} onChange={onChange} onButtonClick={onButtonClick} {...restProps} />
  }

  // }, (prev, next) => {
  //   // !isEqual(prev.value,next.value) && console.log(prev.value, next.value, isEqual(prev.value,next.value))
  //   /**
  //    * NOTE: When adding a new field type, make sure you add a conditional check if it's needed!
  //    */
  //   return isEqual(prev.value, next.value) && prev.conditionMet === next.conditionMet && prev.invalid === next.invalid && isEqual(prev.disabled, next.disabled) && isEqual(prev.showGoals, next.showGoals) && isEqual(prev.showTerminate, next.showTerminate) && ((prev.goals && next.goals) ? isEqual(prev.goals, next.goals) : true) && ((prev.disabledOptions && next.disabledOptions) ? isEqual(prev.disabledOptions, next.disabledOptions) : true) && ((prev.fixedOptions && next.fixedOptions) ? isEqual(prev.fixedOptions, next.fixedOptions) : true) && isEqual(prev.options, next.options) && isEqual(prev.denominations, next.denominations) && isEqual(prev.groups, next.groups) && isEqual(prev.split, next.split) && isEqual(prev.splitGroup, next.splitGroup) && isEqual(prev.splitKeys, next.splitKeys) && ((prev.questionKeys && next.questionKeys) ? isEqual(prev.questionKeys, next.questionKeys) : true) && ((prev.segmentKeys && next.segmentKeys) ? isEqual(prev.segmentKeys, next.segmentKeys) : true) && ((prev.segmentOptions && next.segmentOptions) ? isEqual(prev.segmentOptions, next.segmentOptions) : true) && ((prev.sublabel && next.sublabel) ? isEqual(prev.sublabel, next.sublabel) : true) && ((prev.endComponent && next.endComponent) ? isEqual(prev.endComponent, next.endComponent) : true && ((prev.customValidator && next.customValidator) ? isEqual(prev.customValidator, next.customValidator) : true))
})

export default StandardField