import React, { useEffect, useRef } from 'react';
import playerLoader from '@brightcove/player-loader';

const UPDATEABLE_PROPS = [
  'catalogSearch',
  'catalogSequence',
  'playlistId',
  'playlistVideoId',
  'videoId'
];

const logError = (err) => {
  /* eslint-disable no-console */
  if (err && console && console.error) {
    console.error(err);
  }
  /* eslint-enable no-console */
};

const ReactPlayerLoader = React.memo((props) => {

  const loading_ = useRef(false)
  const isMounted_ = useRef(false)
  const refNode = useRef()
  const player = useRef()
  const changeRef = useRef({})

  const loadPlayer = () => {
    // Guard against loading the player twice, which would be caused by React's
    // strict mode unmounting and immediately re-mounting the component
    if (!loading_.current) {
      loading_.current = true;
    } else {
      // eslint-disable-next-line no-console
      console.log('Brightcove React Player Loader aborted a subsequent player load while a load was pending');
      return;
    }

    // If there is any player currently loaded, dispose it before fetching a
    // new one.
    disposePlayer();

    // We need to provide our own callbacks below, so we cache these
    // user-provided callbacks for use later.
    const userSuccess = props.onSuccess;
    const userFailure = props.onFailure;

    const options = Object.assign({}, props, {
      refNode: refNode.current,
      refNodeInsert: 'append',
      onSuccess: ({ ref, type }) => {
        // The player load process has completed. A subsequent load,
        // e.g. because of a props change, should be allowed
        loading_.current = false;

        // If the component is not mounted when the callback fires, dispose
        // the player and bail out.
        if (!isMounted_.current) {
          disposePlayer(ref);
          return;
        }

        // Store a player reference on the component.
        player.current = ref;

        // Null out the player reference when the player is disposed from
        // outside the component.
        if (type === 'in-page') {
          ref.one('dispose', () => {
            player.current = null;
          });
        }

        // Add a REACT_PLAYER_LOADER property to bcinfo to indicate this player
        // was loaded via that mechanism.
        if (ref.bcinfo) {
          ref.bcinfo.REACT_PLAYER_LOADER = true;
        }

        // Call a user-provided onSuccess callback.
        if (typeof userSuccess === 'function') {
          userSuccess({ ref, type });
        }
      },
      onFailure: (error) => {
        // The player load process has completed. A subsequent load,
        // e.g. because of a props change, should be allowed
        loading_.current = false;

        // Ignore errors when not mounted.
        if (!isMounted_.current) {
          return;
        }

        // Call a user-provided onFailure callback.
        if (typeof userFailure === 'function') {
          userFailure(error);
          return;
        }

        // Fall back to throwing an error;
        throw new Error(error);
      }
    });

    // Delete props that are not meant to be passed to player-loader.
    delete options.attrs;
    delete options.baseUrl;
    delete options.manualReloadFromPropChanges;

    // If a base URL is provided, it should only apply to this player load.
    // This means we need to back up the original base URL and restore it
    // _after_ we call player loader.
    const originalBaseUrl = playerLoader.getBaseUrl();

    if (props.baseUrl) {
      playerLoader.setBaseUrl(props.baseUrl);
    }

    playerLoader(options);
    playerLoader.setBaseUrl(originalBaseUrl);
  }

  const disposePlayer = () => {
    // Nothing to dispose.
    if (!player.current) {
      return;
    }

    // Dispose an in-page player.
    if (player.current.dispose) {
      player.current.dispose();

      // Dispose an iframe player.
    } else if (player.current.parentNode) {
      player.current.parentNode.removeChild(player.current);
    }

    // Null out the player reference.
    player.current = null;
  }

  const findPlaylistVideoIdIndex_ = (playlist) => {
    const { playlistVideoId } = props;

    if (Array.isArray(playlist) && playlistVideoId) {
      for (let i = 0; i < playlist.length; i++) {
        const { id, referenceId } = playlist[i];

        if (id === playlistVideoId || `ref:${referenceId}` === playlistVideoId) {
          return i;
        }
      }
    }

    return -1;
  }

  const createPlaybackAPICallback_ = (requestType, changes) => {
    return (err, data) => {
      if (err) {
        logError(err);
        return;
      }

      // If the playlistVideoId changed and this is a playlist request, we
      // need to search through the playlist items to find the correct
      // starting index.
      if (requestType === 'playlist' && changes.playlistVideoId) {
        const i = findPlaylistVideoIdIndex_(data);

        if (i > -1) {
          data.startingIndex = i;
        }
      }

      player.current.catalog.load(data);
    };
  }

  const updatePlayer = (changes) => {
    // No player exists, player is disposed, or not using the catalog
    if (!player.current || !player.current.el || !player.current.el()) {
      return;
    }

    // If the player is using the catalog plugin, we _may_ populate this
    // variable with an object.
    let catalogParams;

    if (player.current.usingPlugin('catalog')) {

      // There is a new catalog sequence request. This takes precedence over
      // other catalog updates because it is a different call.
      if (changes.catalogSequence && props.catalogSequence) {
        const callback = createPlaybackAPICallback_('sequence', changes);

        player.current.catalog.getLazySequence(props.catalogSequence, callback, props.adConfigId);
        return;
      }

      if (changes.videoId && props.videoId) {
        catalogParams = {
          type: 'video',
          id: props.videoId
        };
      } else if (changes.playlistId && props.playlistId) {
        catalogParams = {
          type: 'playlist',
          id: props.playlistId
        };
      } else if (changes.catalogSearch && props.catalogSearch) {
        catalogParams = {
          type: 'search',
          q: props.catalogSearch
        };
      }
    }

    // If `catalogParams` is `undefined` here, that means the player either
    // does not have the catalog plugin or no valid catalog request can be made.
    if (catalogParams) {
      if (props.adConfigId) {
        catalogParams.adConfigId = props.adConfigId;
      }

      if (props.deliveryConfigId) {
        catalogParams.deliveryConfigId = props.deliveryConfigId;
      }

      // We use the callback style here to make tests simpler in IE11 (no need
      // for a Promise polyfill).
      const callback = createPlaybackAPICallback_(catalogParams.type, changes);

      player.current.catalog.get(catalogParams, callback);

      // If no catalog request is being made, we may still need to update the
      // playlist selected video.
    } else if (
      changes.playlistVideoId &&
      props.playlistVideoId &&
      player.current.usingPlugin('playlist')
    ) {
      const i = findPlaylistVideoIdIndex_(player.current.playlist());

      if (i > -1) {
        player.current.playlist.currentItem(i);
      }
    }
  }

  const setRefNode = (ref) => {
    refNode.current = ref;
  }

  useEffect(() => {
    if (isMounted_.current) {
      const changes = Object.keys(changeRef.current).reduce((acc, key) => {
        const previous = changeRef.current[key];
        const current = props[key];
        acc[key] = (typeof current === 'object' && current !== null) ? JSON.stringify(current) !== JSON.stringify(previous) : current !== previous
        return acc;
      }, {});
      // TODO: REORGANIZE "OTHER" PROPS TO RELOAD PLAYER ON CHANGE
      // if (props.manualReloadFromPropChanges) loadPlayer()
      updatePlayer(changes);
    }
    changeRef.current = {
      catalogSearch: props.catalogSearch,
      catalogSequence: props.catalogSequence,
      playlistId: props.playlistId,
      playlistVideoId: props.playlistVideoId,
      videoId: props.videoId
    }
  }, [props.manualReloadFromPropChanges, props.catalogSearch, props.catalogSequence, props.playlistId, props.playlistVideoId, props.videoId])

  useEffect(() => {
    isMounted_.current = true;
    loadPlayer();
    return () => {
      isMounted_.current = false;
      disposePlayer();
    }
  }, [])

  const opts = Object.assign(
    { className: 'brightcove-react-player-loader' },
    props.attrs,
    { ref: setRefNode }
  );

  return React.createElement('div', opts);
})

export default ReactPlayerLoader;
