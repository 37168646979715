import React, { useCallback, useRef, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import StandardField from './StandardField';
import DeleteIcon from '@mui/icons-material/Delete';

const PREFIX = 'MultiEmojiInputField';

const classes = {
  root: `${PREFIX}-root`,
  label: `${PREFIX}-label`,
  builder: `${PREFIX}-builder`,
  option: `${PREFIX}-option`,
  addnew: `${PREFIX}-addnew`
};

const Root = styled('div')({
  //border: 'solid 2px green',
  display: 'flex',
  padding: '5px',
  alignItems: 'center',
  width: '100%',
  [`& .${classes.label}`]: {
    flexBasis: '20%',
    // minWidth: '25%',
    textAlign: 'right',
    marginRight: '10px'
  },
  [`& .${classes.builder}`]: {
    display: 'flex',
    flexDirection: 'column'
  },
  [`& .${classes.option}`]: {
    display: 'flex',
    alignItems: 'center'
  },
  [`& .${classes.addnew}`]: {
    margin: '8px 0 8px 0'
  },
});

const cloneDeep = require('lodash/cloneDeep');

const EMOJI_LIMIT_MAX = 7;

const MultiEmojiInputField = React.memo(({ value = [], label, sublabel, staticLength, buttonLabel, fieldKey, required, conditional, conditionMet, onChange, invalid, invalidate, ...restProps }) => {

  const valueRef = useRef();

  valueRef.current = value || []

  const handleAdd = useCallback(() => {
    if (valueRef.current.length < EMOJI_LIMIT_MAX) {
      const newValue = [...valueRef.current, null]
      onChange(null, newValue)
    }
  }, [onChange])

  const handleInputChange = useCallback((changedValue, index, key) => {
    const newValue = cloneDeep(valueRef.current)
    newValue[index] = {
      ...(newValue[index] || {}),
      [key]: changedValue
    }
    onChange(null, newValue)
  }, [onChange]);

  const handleRemove = useCallback((index, key) => {
    if (valueRef.current.length > 1) {
      const newValue = cloneDeep(valueRef.current)
      newValue.splice(index, 1)
      for (let i = index; i < newValue.length; i++) {
        if (newValue[i]?.tooltip && newValue[i]?.emoji) {
          invalidate(`${key}-emoji-${index}`, false);
          invalidate(`${key}-tooltip-${index}`, false);
        }
      }
      invalidate(`${key}-emoji-${newValue.length}`, false);
      invalidate(`${key}-tooltip-${newValue.length}`, false);
      // invalidate && invalidate(`${fieldKey}-button${index}`, false)
      onChange(null, newValue)
    }
  }, [onChange]);

  return (
    <Root>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <div style={{ width: '100%' }}>
        <div className={classes.builder}>
          {(value || []).map((val, i) => (<div className={classes.option}>
            <StandardField
              type='emoji'
              label="Emoji"
              // sublabel={typeof sublabel === 'function' ? sublabel(i + 1) : sublabel}
              fieldKey={`${fieldKey}-emoji-${i}`}
              key={`${fieldKey}-emoji-${i}`}
              value={val?.emoji}
              required={required}
              onChange={(e, v) => handleInputChange(v, i, 'emoji')}
              invalidate={invalidate}
              {...restProps}
            />
            <StandardField
              type='text'
              label="Tooltip Label"
              // sublabel={typeof sublabel === 'function' ? sublabel(i + 1) : sublabel}
              fieldKey={`${fieldKey}-tooltip-${i}`}
              key={`${fieldKey}-tooltip-${i}`}
              value={val?.tooltip}
              required={required}
              // onRemove={() => handleRemove(i)}
              onChange={(e, v) => handleInputChange(v, i, 'tooltip')}
              invalidate={invalidate}
              {...restProps}
            />
            <span><Button disabled={restProps.disabled} onClick={() => handleRemove(i, fieldKey)} style={{ fontWeight: 800, minWidth: 0 }}><DeleteIcon /></Button></span>
          </div>))}
        </div>
        {valueRef.current.length < EMOJI_LIMIT_MAX && <div className={classes.addnew}>
          <Button disabled={restProps.disabled} variant="outlined" onClick={handleAdd}>
            <AddIcon /> {buttonLabel || 'Add'}
          </Button>
        </div>}
      </div>
    </Root>
  );
})

// MultiEmojiInputField.defaultProps = {

// }

export default MultiEmojiInputField;
