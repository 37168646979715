import React, { useEffect, useState, useRef, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Button, CircularProgress } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ClearIcon from '@mui/icons-material/Clear';
import Files from 'react-butterfiles'

const PREFIX = 'PhotoUploadField';

const classes = {
  root: `${PREFIX}-root`,
  photoBox: `${PREFIX}-photoBox`,
  label: `${PREFIX}-label`,
  image: `${PREFIX}-image`,
  buttonBox: `${PREFIX}-buttonBox`,
  button: `${PREFIX}-button`
};

const Root = styled('div')({
  //border: 'solid 2px green',
  display: 'flex',
  padding: '5px',
  alignItems: 'center',
  // width: '100%'
  [`& .${classes.photoBox}`]: {
    border: ({ url }) => url ? 'none' : 'solid 1px black',
    minWidth: '120px',
    minHeight: '70px',
    maxWidth: '300px',
    maxHeight: '175px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  [`& .${classes.label}`]: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  [`& .${classes.image}`]: {
    display: 'block',
    width: '100%',
    height: '100%',
    transform: 'none',
    minWidth: '120px',
    minHeight: '70px',
    maxWidth: '300px',
    maxHeight: '175px',
  },
  [`& .${classes.buttonBox}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '8px'
  },
  [`& .${classes.button}`]: {
    margin: '4px 0px 4px 0px',
    width: '100%'
  }
});

let intg = /[0-9]+/

// const timeStringFromMS = (time) => {
//   const hours = time / (3600000)
//   const remainder = time % (3600000)
//   return `${Math.floor(hours)}`.padStart(2, '0') + ":" + `${Math.floor((remainder) / (6000))}`.padStart(2, '0') + ":" + `${Math.ceil((remainder) % (6000) / (1000))}`.padStart(2, '0')
// }

const PhotoUploadField = React.memo(({ value, resolver, label, disabled, onChange, invalid, onButtonClick }) => {
  const [url, setUrl] = useState()
  const [loading, setLoading] = useState(false)
  // const inputRef = useRef({})


  useEffect(() => {
    if (value && typeof value === 'string' && resolver && typeof resolver === 'function') {
      setLoading(true)
      Promise.resolve(resolver(value)).then(setUrl).then(() => setLoading(false))
    } else {
      if (value instanceof File) {
        const reader = new FileReader();
        reader.onload = () => setUrl(reader.result);
        reader.readAsDataURL(value);
      } else {
        setUrl(value)
      }
    }
  }, [value, resolver])

  const onPhotoUpload = useCallback(([file]) => {
    onChange(null, file.src.file)
  }, [onChange])

  const handleClearPhoto = useCallback(() => {
    onChange(null, null)
  }, [onChange])


  return (
    <Root>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <div className={classes.photoBox}>
        {loading && <CircularProgress />}
        {url && <img className={classes.image} src={url} />}
      </div>
      <div className={classes.buttonBox}>
        <Files
          multiple={false}
          maxSize="2mb"
          multipleMaxSize="10mb"
          accept={['image/png']}
          onSuccess={onPhotoUpload}
        // onError={onError}
        >
          {({ browseFiles }) => (
            <Button variant="outlined" size="small" className={classes.button} startIcon={<AddPhotoAlternateIcon />} onClick={browseFiles}>
              {value ? 'Replace' : 'Add'} Photo
            </Button>
          )}
        </Files>
        <Button variant="outlined" size="small" className={classes.button} startIcon={<ClearIcon />} onClick={handleClearPhoto}>
          Remove Photo
        </Button>
      </div>
    </Root>
  );
})

export default PhotoUploadField;