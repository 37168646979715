import React, { useEffect, useState, useRef, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, TextField, Button, MenuItem } from '@mui/material';
const PREFIX = 'HMSTimeField';

const classes = {
  root: `${PREFIX}-root`,
  marker: `${PREFIX}-marker`,
  label: `${PREFIX}-label`,
  textField: `${PREFIX}-textField`,
  timeField: `${PREFIX}-timeField`,
  colon: `${PREFIX}-colon`,
  datePicker: `${PREFIX}-datePicker`,
  signInput: `${PREFIX}-signInput`
};

const Root = styled('div')({
  //border: 'solid 2px green',
  display: 'flex',
  padding: '5px',
  alignItems: 'center',
  // width: '100%'
  [`& .${classes.marker}`]: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
  },
  [`& .${classes.label}`]: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  [`& .${classes.textField}`]: {
    width: '100%'
  },
  [`& .${classes.timeField}`]: {
    width: '3.5em'
  },
  [`& .${classes.colon}`]: {
    padding: '3px'
  },
  [`& .${classes.datePicker}`]: {
    flexBasis: '35%',
    // minWidth: '35%',
    //width: '100%'
  },
  [`& .${classes.signInput}`]: {
    marginRight: '6px'
  }
});

let intg = /[0-9]+/

// const timeStringFromMS = (time) => {
//   const hours = time / (3600000)
//   const remainder = time % (3600000)
//   return `${Math.floor(hours)}`.padStart(2, '0') + ":" + `${Math.floor((remainder) / (6000))}`.padStart(2, '0') + ":" + `${Math.ceil((remainder) % (6000) / (1000))}`.padStart(2, '0')
// }

const HMSTimeField = React.memo(({ value, label, disabled, allowNegative, onChange, invalid, buttonLabel, buttonIcon: ButtonIcon, onButtonClick }) => {
  // const [, setUpdate] = useState({})
  // const inputRef = useRef({})

  // const valueRef = useRef();
  const updateRef = useRef(true);

  // const [hours, setHours] = useState()
  // const [minutes, setMinutes] = useState()
  // const [seconds, useSeconds] = useState();

  const [inputValue, setInputValue] = useState({
    H: null,
    M: null,
    S: null,
    negative: false
  })

  // valueRef.current = Math.round(value)

  // console.log('hms time render', value)

  useEffect(() => {
    if (updateRef.current && Number.isInteger(Math.round(value))) {
      const negative = value < 0
      const abs = Math.abs(value)
      const hours = Math.floor(Math.round(abs) / 3600)
      const remainder = Math.round(abs) % (3600)
      const minutes = Math.floor(remainder / 60)
      const seconds = Math.floor(remainder % 60)
      // setHours(h.padStart(2, '0'))
      // setMinutes(m.padStart(2, '0'))
      // setSeconds(s.padStart(2, '0'))
      setInputValue({
        H: `${hours}`.padStart(2, '0'),
        M: `${minutes}`.padStart(2, '0'),
        S: `${seconds}`.padStart(2, '0'),
        negative
      })
      updateRef.current = false
    }
    // if (Number.isInteger(valueRef.current.H) && Number.isInteger(valueRef.current.M) && Number.isInteger(valueRef.current.H)) {
    //   inputRef.current = { H: 2, M: 2, S: 2 }
    //   setUpdate({})
    // }
    // console.log('time marker', valueRef.current, inputRef.current)
  }, [value])


  // useEffect(() => {
  //   if (inputValue) {
  //     console.log('HMS', inputValue)
  //   }
  // }, [inputValue])

  // const handleTimeChange = useCallback((event, key) => {
  //   if (intg.test(event.target.value) || event.target.value === '') {
  //     inputRef.current[key] = event.target.value.length
  //     const marker = { ...valueRef.current }
  //     const newValue = event.target.value !== '' ? parseInt(event.target.value) : null
  //     if (key !== 'H' && newValue >= 60) {
  //       marker[key === 'M' ? 'H' : 'M']++
  //       marker[key] = newValue - 60
  //     } else {
  //       marker[key] = newValue
  //     }
  //     onChange(null, marker)
  //   }
  // }, [onChange]);

  const handleSignChange = useCallback((event) => {
    console.log(event)
    const ev = event.target.value
    setInputValue(prev => {
      const marker = { ...prev }
      marker.negative = ev
      onChange(null, (marker.H && marker.M && marker.S) ? (marker.negative ? -1 : 1) * (parseInt(marker.H || 0) * 3600 + parseInt(marker.M || 0) * 60 + parseInt(marker.S || 0)) : null)
      return {
        ...marker
      }
    })
  }, [onChange])

  const handleTimeChange = useCallback((event, key) => {
    const ev = event.target.value
    if (intg.test(ev) || ev === '') {
      // inputRef.current[key] = event.target.value.length
      // const marker = { ...valueRef.current }
      // const newValue = event.target.value !== '' ? parseInt(event.target.value) : null
      // if (key !== 'H' && newValue >= 60) {
      //   marker[key === 'M' ? 'H' : 'M']++
      //   marker[key] = newValue - 60
      // } else {
      //   marker[key] = newValue
      // }

      setInputValue(prev => {
        const marker = { ...prev }
        const newValue = ev !== '' ? parseInt(ev || 0) : null
        if (key !== 'H' && newValue >= 60) {
          marker[key === 'M' ? 'H' : 'M'] = `${parseInt(marker[key === 'M' ? 'H' : 'M']) + 1}`.padStart(2, '0')
          marker[key] = `${newValue - 60}`.padStart(2, '0')
        } else {
          marker[key] = isNaN(parseInt(newValue)) ? '' : `${newValue}`
        }
        onChange(null, (marker.H && marker.M && marker.S) ? (marker.negative ? -1 : 1) * (parseInt(marker.H || 0) * 3600 + parseInt(marker.M || 0) * 60 + parseInt(marker.S || 0)) : null)
        return {
          ...marker
        }
      })

    }
  }, [onChange]);

  const handleBlur = useCallback((event, key) => {
    if (event.target.value.length) {
      const newValue = event.target.value
      setInputValue(prev => {
        return { ...prev, [key]: newValue.padStart(2, '0') }
      })
    }
  }, [])

  const handleButtonClick = useCallback(() => {
    updateRef.current = true
    onButtonClick()
  }, [onButtonClick])

  return (
    <Root>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <div className={classes.marker}>
        {/* <Typography className={classes.label}>{label}</Typography> */}
        {allowNegative && <div className={classes.signInput}>
          <TextField
            value={!!inputValue.negative}
            onChange={handleSignChange}
            variant="outlined"
            size="small"
            select
          // displayEmpty
          // className={classes.selectField}
          >
            <MenuItem value={false}>+</MenuItem>
            <MenuItem value={true}>{'\u2212'}</MenuItem>
          </TextField>
        </div>}
        <TextField className={classes.timeField} error={invalid || isNaN(parseInt(inputValue.H))} inputProps={{ maxLength: 2, inputMode: "numeric" }} variant="outlined" size="small" placeholder="HH" disabled={disabled} value={inputValue.H} onChange={(e) => handleTimeChange(e, 'H')} onBlur={(e) => handleBlur(e, 'H')} />
        <Typography className={classes.colon}>:</Typography>
        <TextField className={classes.timeField} error={invalid || isNaN(parseInt(inputValue.M))} inputProps={{ maxLength: 2, inputMode: "numeric" }} variant="outlined" size="small" placeholder="MM" disabled={disabled} value={inputValue.M} onChange={(e) => handleTimeChange(e, 'M')} onBlur={(e) => handleBlur(e, 'M')} />
        <Typography className={classes.colon}>:</Typography>
        <TextField className={classes.timeField} error={invalid || isNaN(parseInt(inputValue.S))} inputProps={{ maxLength: 2, inputMode: "numeric" }} variant="outlined" size="small" placeholder="SS" disabled={disabled} value={inputValue.S} onChange={(e) => handleTimeChange(e, 'S')} onBlur={(e) => handleBlur(e, 'S')} />
      </div>
      {(onButtonClick && (ButtonIcon || buttonLabel)) && <Button onClick={handleButtonClick}>
        {ButtonIcon && <ButtonIcon />}
        {buttonLabel}
      </Button>}
    </Root>
  );
})

export default HMSTimeField;