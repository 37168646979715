import config from '../config.json'

export const getGuestRewardsPromise = (rewardsId, offset, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/rewards/${rewardsId}/guests?offset=${offset || 0}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data //data.records || data.Items
    })

export const getSessionGuestRewards = (rewardsId, token, rewards = []) => getGuestRewardsPromise(rewardsId, rewards.length, token).then((resp) => {
    rewards.push(...resp.records)
    const more = rewards.length !== resp.count;
    if (more) {
        return getSessionGuestRewards(rewardsId, token, rewards)
    } else {
        return rewards
    }
})

export const updateGuestRewardsPromise = ({ id, ...rewards }, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/rewards/guest/${id}`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(rewards)

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const getRewardsPromise = ({ id, sessionId }, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/rewards?${id ? `id=${id}` : `sessionId=${sessionId}`}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return (data.records && (data.records[0] || {})) || data.Item
    })


// export const deleteRewardsPromise = (id, sessionId, token) =>
//     fetch(`https://${config.rest.sessionManagementAPI}/rewards`, {
//         method: 'DELETE',
//         mode: 'cors',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//             'Authorization': 'Bearer ' + token
//         },
//         body: JSON.stringify({ id, sessionId })

//     }).then(response => {
//         if (response.status >= 200 && response.status <= 299) {
//             return response.json();
//         } else {
//             return response.json().then(res => {
//                 throw res
//             })
//         }
//     }).then(data => {
//         return data;
//     })

export const insertRewardsPromise = (rewards, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/rewards`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(rewards)

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const updateRewardsPromise = (rewards, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/rewards`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(rewards)

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const submitRewardOrderPromise = (id, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/rewards/${id}/order`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })

export const resendGuestRewardPromise = (id, token) =>
    fetch(`https://${config.rest.sessionManagementAPI}/rewards/guest/${id}/resend`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    })