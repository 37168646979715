import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { CircularProgress, Fab, Zoom } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const PREFIX = 'AddFab';

const classes = {
  addFab: `${PREFIX}-addFab`
};

const Root = styled('div')({
  [`& .${classes.addFab}`]: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    zIndex: '10',
    padding: '1rem',
    boxSizing: 'content-box'
  }
});

const AddFab = React.memo(({ show, loading, onClick }) => {

  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <Root>
      <div className={`${classes.addFab} mui-fixed`}>
        <Zoom
          key={'add-session-fab'}
          in={show}
          timeout={transitionDuration}
          // style={{
          //   transitionDelay: `${transitionDuration.exit}ms`,
          // }}
          unmountOnExit
        >
          <Fab color="primary" onClick={onClick} disabled={loading}>
            {loading ? <CircularProgress size="1.5rem" /> : <AddIcon />}
          </Fab>
        </Zoom>
      </div>
    </Root>
  );
})

export default (AddFab)