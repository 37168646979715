import { useEffect, useCallback } from 'react'
import { getAllSessions } from '../services/sessionsService'
import { useSetRecoilState } from 'recoil';
import { sessionState } from './atoms'
import { useOktaAuth } from '@okta/okta-react'
import * as workerTimers from 'worker-timers';

// const isEqual = require('lodash/isEqual');
// const differenceWith = require('lodash/differenceWith');

export default function SessionsSubscription({ refresh, onLoaded, filter }) {
    const setSessions = useSetRecoilState(sessionState)
    const { oktaAuth } = useOktaAuth();
    const loaded = useCallback(onLoaded ? onLoaded : () => { }, [onLoaded])

    useEffect(() => {
        let syncInterval;
        const sync = () => getAllSessions({ includeDeleted: (filter === "past") }, oktaAuth.getAccessToken()).then(setSessions).then(loaded).catch((error) => {
            if (error.message === 'Unauthorized') {
                oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
            } else {
                throw error
            }
        })

        sync()

        return function cleanup() {
            if (syncInterval) workerTimers.clearInterval(syncInterval)
            // subscription.unsubscribe()
        };
    }, [oktaAuth, refresh, filter, loaded, setSessions]);


    return null;
}