import React, { useCallback, useState, useRef, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Badge, Tooltip, Button, Typography } from '@mui/material'
import StandardModal from './standard/StandardModal';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useClipboard } from 'use-clipboard-copy';
import moment from 'moment'

const PREFIX = 'ProjectModal';

const classes = {
  centerBadge: `${PREFIX}-centerBadge`
};

const Root = styled(Badge)({
  [`& .${classes.centerBadge}`]: {
    transform: 'scale(1) translate(-100%, -50%)',
  }
});

const teamMap = {
  'seasi': 'SEA',
  'pfm': 'PFM',
  'vvtv': 'VTV',
  'cpt': 'CPT',
  'ew': 'EWX'
}

const defaultSymbolsList = [
  { emoji: '😊', tooltip: 'Happy' },
  { emoji: '🙁', tooltip: 'Sad' },
  { emoji: '🤨', tooltip: 'Confused' },
  { emoji: '😠', tooltip: 'Angry' },
  { emoji: '😢', tooltip: 'Crying' }
];

const titleFn = ({ id }) => id ? 'Edit Project' : 'Add New Project'

const ProjectModal = React.memo((props) => {
  const { data, team, getVideos, getYoutubeVideo, getSurveys, getPFMScreenings, sessions, allowFullEdit, allowPostEdit, allowVideoEdit } = props

  const clipboard = useClipboard({
    copiedTimeout: 1500
  });
  // const [hasExternalRecruit, setHasExternalRecruit] = useState(data && data.externalRecruit)
  const [videoPlatform, setVideoPlatform] = useState(data?.videoPlatform || 'Brightcove')
  const [videoId, setVideoId] = useState(data?.videoId)
  const [playlistLength, setPlaylistLength] = useState(data?.multiSection && data?.contentLength?.length)
  const [youtubeData, setYoutubeData] = useState()
  const [youtubeLoading, setYoutubeLoading] = useState(false)
  const [allowedScreenings, setAllowedScreenings] = useState([])
  const idRef = useRef(data?.id)
  const videosRef = useRef()
  const playlistsRef = useRef()
  const tbVideosRef = useRef()
  const youtubeLoadedRef = useRef(false)
  const qualtricsRegRef = useRef()
  const pfmScreeningsRef = useRef()



  const disabledFn = useCallback(({ id, started }) => allowFullEdit ? (!!id && !!started) : true, [allowFullEdit])

  const disabledPostFn = useCallback(({ id, started }) => allowFullEdit ? (!!id && !!started) && !allowPostEdit : true, [allowFullEdit, allowPostEdit])

  const disabledFGFn = useCallback(({ id, started, type }) => type !== 'Content' || (allowFullEdit ? (!!id && !!started) : true), [allowFullEdit])

  const disabledVideoFn = useCallback(({ id, started }) => youtubeLoading && ((allowFullEdit || allowVideoEdit) ? (!!id && !!started) : true), [allowFullEdit, allowVideoEdit, youtubeLoading])

  const bcVideos = useCallback(() => videosRef.current || getVideos('brightcove').then(res => (videosRef.current = res, res)), [getVideos])

  const bcPlaylists = useMemo(() => Promise.resolve(bcVideos()).then(vids => {
    return playlistsRef.current || (playlistsRef.current = getVideos('brightcove', null, true).then(res => {
      playlistsRef.current = (res || []).map(x => (x.videos = vids.filter(v => (x.video_ids || []).includes(v.id)), x))
      return playlistsRef.current
    }), playlistsRef.current)
  }), [getVideos, bcVideos])

  const onNewPlaylistUpdate = useCallback((newValue, newParams) => {
    return Promise.resolve(bcVideos()).then(vids => {
      newParams.contentLength = (newValue || []).map(x => vids.find(v => v.id === x)?.duration || 0)
      const [first, ...rest] = newParams.contentLength
      newParams.videoLength = rest.reduce((p, c) => (p += c + (15 * 60 * 1000)), first) // 15 MINUTES BETWEEN
      setPlaylistLength(newParams.contentLength.length)
      return newParams
    })
  }, [bcVideos])

  const tbVideos = useCallback((tc) => tbVideosRef.current || getVideos('ticktbox').then(res => (tbVideosRef.current = res, res)), [getVideos])

  const ytVideo = useCallback((vid) => getYoutubeVideo(vid), [getYoutubeVideo])

  const qualtricsRegistrationSurveys = useCallback(() => qualtricsRegRef.current || getSurveys('qualtrics', true, true).then(res => (qualtricsRegRef.current = res, res)), [getSurveys])

  const pfmOnlineScreenings = useCallback(() => pfmScreeningsRef.current || (pfmScreeningsRef.current = getPFMScreenings(true).then(res => (pfmScreeningsRef.current = (res || []).map(x => (x.id = `${x.id}`, x.linkedScreeningIds = x.linkedScreeningIds && x.linkedScreeningIds.map(x => x && x.toString()), x)), pfmScreeningsRef.current)), pfmScreeningsRef.current), [getPFMScreenings])

  const projectSessions = useCallback(() => {
    return sessions.filter(session => videoPlatform ? (session.videoPlatform === videoPlatform && (videoId ? session.videoId === videoId : false)) : false)
  }, [sessions, videoPlatform, videoId])

  const disabledSessionsFn = useCallback((px, option) => !!(option.projectId && option.projectId !== idRef.current) || (option.externalRecruitId && allowedScreenings.length && !allowedScreenings.includes(option.externalRecruitId)), [allowedScreenings])

  // const onHasExternalRecruitUpdate = useCallback((newValue, newParams) => {
  //   setHasExternalRecruit(!!newValue)
  //   return newParams
  // }, [])

  const onQualtricsScreenerUpdate = useCallback((newValue, newParams) => {
    const recruitScreener = newValue?.id !== "NEW" && newValue
    if (recruitScreener?.segments) {
      newParams.audienceSegments = recruitScreener.segments
    }
    return newParams
  }, [])

  const onPFMScreenerUpdate = useCallback((newValue, newParams) => {
    // console.log('SELECTED SCREENER', newValue)
    let newAllowed = [];
    if (newValue?.screenings?.[0].Questions) {
      const registrationSurvey = newValue.screenings[0]
      // newParams.videoTitle = registrationSurvey.title
      newParams.client = registrationSurvey.Client.displayName
      const segs = registrationSurvey.Questions.filter(q => q.fieldCode !== "Fields of Employment").map((question) => {
        return {
          questionKey: question.label,
          question: question.displayQuestion,
          multiple: question.configJSON.dataType === 408,
          matrix: question.configJSON.dataType === 808,
          hasCrosstab: false,
          crosstabKeys: [],
          freeNumeric: ['Age', 'Child Age'].includes(question.fieldCode),
          answers: question.configJSON.answers.map(answer => ({ answer })),
          matrixKeys: question.configJSON.dataType === 808 ? question.configJSON.options.map(option => ({ option })) : null
        }
      })
      newParams.audienceSegments = segs
      if (registrationSurvey.seasiJobCode) newParams.seaJobCode = registrationSurvey.seasiJobCode
      newAllowed = (newValue.ids || []).map(x => x && x.toString())
    }
    setAllowedScreenings(newAllowed)
    return newParams
  }, [])

  const onDialModeChanged = useCallback((newVal, newParams) => {
    if (newVal.code === 'symbol') {
      newParams.symbolOptions = defaultSymbolsList;

      // set default for chartConfig
      newParams.chartConfig = {
        defaultChartType: "line",
        defaultEmoji: defaultSymbolsList[0].emoji,
        defaultEmojiSegmentGroup: "all",
        defaultSegment: "all",
        segmentationMode: "byEmoji",
        defaultAggregationInterval: 30
      }
    }
    else if (newVal.code === 'score') {
      newParams.scoreBase = 100;
      newParams.chartConfig = null;
    }
    return newParams
  }, []);

  const onVideoPlatformUpdate = useCallback((newValue, newParams) => {
    newParams.videoId = null
    // newParams.isLiveProject = false
    newParams.multiSection = false
    newParams.internalVideoName = []
    if (newValue === 'YouTube') {
      newParams.videoLength = null
      newParams.contentLength = []
    }
    setVideoId(null)
    setPlaylistLength(0)
    setVideoPlatform(newValue)
    return newParams
  }, [])

  const onBCVideoUpdate = useCallback((newValue, newParams) => {
    const duration = (newValue && newValue.duration) || null
    newParams.videoLength = duration
    newParams.contentLength = duration ? [duration] : null
    setVideoId(newValue.id)
    setPlaylistLength(0)
    return newParams
  }, [])

  const onMultiSectionUpdate = useCallback((newValue, newParams) => {
    newParams.videoId = null
    newParams.internalVideoName = []
    setPlaylistLength(0)
    return newParams
  }, [])

  const onBCPlaylistUpdate = useCallback((newValue, newParams) => {
    newParams.contentLength = (newValue.videos || []).map(x => x.duration || 0)
    newParams.videoLength = newParams.contentLength.reduce((p, c) => (p += c + (30 * 1000)), 0)
    setVideoId(newValue.id)
    setPlaylistLength(newParams.contentLength.length)
    return newParams
  }, [])

  const onTBVideoUpdate = useCallback((newValue, newParams) => {
    setVideoId(newValue.videoId)
    return newParams
  }, [])

  const onYoutubeVideoChange = useCallback((newValue, newParams) => {
    if (/^[\w\-]{11}$/.test(newValue)) {
      setYoutubeLoading(true)
      return ytVideo(newValue).then(vidData => {
        // newParams.isLiveProject = !!vidData.liveStreamDetails
        setYoutubeData(vidData)
        setYoutubeLoading(false)
        return {}
      }).catch(err => {
        // newParams.isLiveProject = false
        setYoutubeData()
        setYoutubeLoading(false)
        return {}
      })
    } else {
      // newParams.isLiveProject = false
      setYoutubeData()
      return newParams
    }
    setVideoId(newValue)
  }, [ytVideo])

  // INVALIDATE LIVE FOR PROJECTS!!
  const youtubeCustomValidator = useCallback((value) => {
    if (/^[\w\-]{11}$/.test(value)) {
      if (youtubeLoadedRef.current) {
        return !!(youtubeData && !youtubeData.liveStreamDetails)
      } else {
        setYoutubeLoading(true)
        return ytVideo(value).then(vidData => {
          setYoutubeData(vidData)
          youtubeLoadedRef.current = true
          setYoutubeLoading(false)
          return !!(vidData && !vidData.liveStreamDetails)
        }).catch(err => {
          setYoutubeData()
          setYoutubeLoading(false)
          return false
        })
      }
    } else {
      return false
    }
  }, [youtubeData, ytVideo])

  const youtubeSublabel = useCallback(() => {
    return youtubeLoading ? 'Loading...' : youtubeData ? youtubeData.videoTitle : 'NO VIDEO SELECTED'
  }, [youtubeData, youtubeLoading])

  const YoutubeLiveBadge = useMemo(() => {
    return <Root badgeContent="LIVE" color="secondary" anchorOrigin={{ vertical: 'top', horizontal: 'left' }} classes={{
      badge: classes.centerBadge
    }} />
  }, [classes])

  const youtubeEndComponent = useCallback(() => {
    return youtubeData && !!youtubeData.liveStreamDetails ? YoutubeLiveBadge : <></>
  }, [youtubeData, YoutubeLiveBadge])

  const clipboardEndComponent = useCallback(() => {
    const copyToClipboard = () => {
      // let text = `
      // Use this link to access the virtuworks™ Live session:
      // ${data.magicLinkUrl}

      // This session requires the following additional security measures:
      // Passcode: ${data.magicLinkPasscode}
      // Email Verification: ${data.requireEmailValidation ? 'Yes' : 'No'}
      // Waiting Room: ${data.requireApproval ? 'Enabled' : 'Disabled'}

      // If you have any trouble, please contact your client service representative for support.
      // `
      let text = `
      Use this link to access the virtuworks™ Live session:
      ${data.magicLinkUrl}
      
      This session requires the following additional security measures:
      Passcode: ${data.magicLinkPasscode}
      Email Verification: ${data.requireEmailValidation ? 'Yes' : 'No'}
      
      If you have any trouble, please contact your client service representative for support.
      `
      clipboard.copy(text);
    }
    return <span style={{ display: 'flex', alignItems: 'center' }}><Tooltip title="Copy"><Button style={{ minWidth: 0, padding: '1px 2px' }}><AssignmentIcon onClick={copyToClipboard} /></Button></Tooltip></span>;
  }, [data, clipboard])

  const disabledLinkedScreeningsFn = useCallback((px, option) => !!option.assigned, [])

  const disabledInternalNameFn = useCallback(({ videoId }) => (!videoId), [])

  const audienceSegments = useCallback((params) => params.audienceSegments || [], [])

  const showTerminate = useCallback((params) => params.externalRecruit === true && params.recruitPlatform === "QTC", [])

  const videoNameLabel = useCallback((pos) => playlistLength ? `Video ${pos}` : null, [playlistLength])

  return (
    <StandardModal
      modalKey='sessionModal'
      open
      {...props}
      title={titleFn}
      buttonLabel='Submit'
      tabs={[
        {
          key: 'detailsTab',
          label: 'Details',
          fields: [
            [
              {
                key: 'team',
                label: 'Team',
                type: 'select',
                placeholder: 'Select Team',
                options: [
                  // { code: 'SEA', label: "Screen Engine" },
                  { code: 'PFM', label: "Preview Free Movies" },
                  { code: 'VTV', label: "Viewer Voice TV" },
                  { code: 'CPT', label: "Cross Platform Team" },
                  { code: 'EWX', label: "EngineWorks" }
                ],
                optionLabel: 'label',
                optionValueKey: 'code',
                multiple: false,
                required: true,
                defaultValue: teamMap[team] || 'PFM',
                disabled: disabledFn
              },
              {
                key: 'projectName',
                label: 'Project Name',
                placeholder: 'Enter Internal Project Name',
                type: 'text',
                maxLength: 50,
                required: true,
                disabled: disabledFn
              },
              {
                key: 'type',
                label: 'Session Type',
                type: 'select',
                placeholder: 'Select Session Type',
                options: [
                  { code: 'Content', label: "Content" },
                  { code: 'Focus Group', label: "Focus Group Only" }
                ],
                optionLabel: 'label',
                optionValueKey: 'code',
                multiple: false,
                required: true,
                defaultValue: 'Content',
                disabled: disabledFn
              },
              {
                key: 'seaJobCode',
                label: 'SE/ASI Job Code',
                type: 'text',
                // regexp: /[0-9]{4}/,
                placeholder: 'Enter Job Code (NOCODE if none)',
                maxLength: 25,
                required: true,
                disabled: disabledPostFn
              },
              {
                key: 'client',
                label: 'Client',
                type: 'select',
                placeholder: 'Select Client (or type custom + ENTER)',
                options: [
                  'Netflix',
                  'Disney',
                  'ABC TV',
                  'NBC/Universal',
                  'MGM',
                  'Lionsgate',
                  'Blumhouse'
                ],
                freeType: true,
                // maxLength: 25,
                multiple: false,
                required: true,
                disabled: disabledFn
              },
              {
                key: 'externalRecruit',
                label: 'Use External Recruit',
                type: 'boolean',
                defaultValue: false,
                // onUpdate: onHasExternalRecruitUpdate,
                disabled: disabledFn
              },
              {
                key: 'recruitPlatform',
                label: 'Recruit Platform',
                placeholder: 'Select Recruit Platform',
                type: 'select',
                options: [
                  { code: 'PFM', label: "Preview Free Movies" },
                  { code: 'QTC', label: "Qualtrics" }
                ],
                optionLabel: 'label',
                optionValueKey: 'code',
                multiple: false,
                defaultValue: 'PFM',
                conditions: [
                  {
                    condition: ({ externalRecruit }) => externalRecruit === true,
                    required: true
                  }
                ],
                disabled: disabledFn
              },
              {
                key: 'externalRecruitIds',
                label: 'PFM Recruit Linked Screenings',
                placeholder: 'Select PFM Recruit Linked Screenings',
                type: 'select',
                options: pfmOnlineScreenings,
                disabledOptions: disabledLinkedScreeningsFn,
                optionLabel: (opt) => {
                  return opt && `Screening IDs ${(opt.ids || opt).map(x => `#${x}`).join(', ')}`
                },
                displayComponent: (option) => () => (
                  <div style={{ display: "flex", flexDirection: "column", flexBasis: "100%", paddingLeft: '1rem' }}>
                    {option.screenings.map(s => <Typography key={s.id} variant="caption">#{s.id} - {s.title}</Typography>)}
                  </div>
                ),
                optionValueKey: 'ids',
                conditions: [
                  {
                    condition: ({ externalRecruit, recruitPlatform }) => externalRecruit === true && recruitPlatform === "PFM",
                    required: true
                  }
                ],
                onUpdate: onPFMScreenerUpdate,
                disabled: disabledFn
              },
              {
                key: 'externalRecruitId',
                label: 'Qualtrics Recruit Screener',
                placeholder: 'Select Qualtrics Recruit Screener',
                type: 'select',
                options: qualtricsRegistrationSurveys,
                optionLabel: 'name',
                optionValueKey: 'id',
                conditions: [
                  {
                    condition: ({ externalRecruit, recruitPlatform }) => externalRecruit === true && recruitPlatform === "QTC",
                    required: true
                  }
                ],
                onUpdate: onQualtricsScreenerUpdate,
                disabled: disabledFn
              },
              {
                key: 'qualtricsRegistrationName',
                label: 'Qualtrics Recruit Screener Name',
                placeholder: 'Enter Qualtrics Recruit Screener Name',
                type: 'text',
                conditions: [
                  {
                    condition: ({ externalRecruit }) => externalRecruit === true,
                  },
                  {
                    condition: ({ externalRecruitId }) => externalRecruitId === 'NEW',
                    required: true
                  }
                ],
                disabled: disabledFn
              },
            ],
            [
              {
                key: 'videoPlatform',
                label: 'Video Platform',
                type: 'select',
                options: [
                  'Brightcove',
                  'ticktBox',
                  'YouTube',
                  // 'Twitch'
                ],
                multiple: false,
                defaultValue: 'Brightcove',
                conditions: [
                  {
                    condition: ({ type }) => type === 'Content',
                    required: true
                  }
                ],
                onUpdate: onVideoPlatformUpdate,
                disabled: disabledFn
              },
              {
                key: 'videoId',
                subkey: 'youtube',
                label: 'YouTube Video ID',
                sublabel: youtubeSublabel,
                endComponent: youtubeEndComponent,
                type: 'text',
                maxLength: 11,
                // regexp: /^[\w\-]{11}$/,
                // required: true,
                disabled: disabledVideoFn,
                onUpdate: onYoutubeVideoChange,
                customValidator: youtubeCustomValidator,
                conditions: [
                  {
                    condition: ({ type, videoPlatform }) => type === 'Content' && videoPlatform === 'YouTube',
                    required: true
                  }
                ],
                // defaultValue: 'VBdQt9xaK'
              },
              // {
              //   key: 'isLiveProject',
              //   label: 'Is YouTube Live?',
              //   type: 'boolean',
              //   conditions: [
              //     {
              //       condition: ({ videoPlatform }) => videoPlatform === 'YouTube'
              //     }
              //   ],
              //   defaultValue: false,
              //   disabled: true
              // },
              {
                key: 'multiSection',
                label: 'Multi-Section Content',
                type: 'boolean',
                defaultValue: false,
                conditions: [
                  {
                    condition: ({ type, videoPlatform }) => type === 'Content' && videoPlatform === 'Brightcove'
                  }
                ],
                onUpdate: onMultiSectionUpdate,
                disabled: disabledVideoFn
              },
              {
                key: 'videoId',
                subkey: 'brightcove',
                label: 'Brightcove Video',
                placeholder: 'Select Video',
                type: 'select',
                options: bcVideos,
                optionLabel: 'name',
                optionValueKey: 'id',
                // required: true,
                conditions: [
                  {
                    condition: ({ type, videoPlatform, multiSection }) => type === 'Content' && videoPlatform === 'Brightcove' && !multiSection,
                    required: true
                  }
                ],
                onUpdate: onBCVideoUpdate,
                disabled: disabledVideoFn
              },
              {
                key: 'brightcoveVideoTitle',
                label: 'Brightcove Video Title',
                placeholder: 'Enter Video Title',
                type: 'text',
                conditions: [
                  {
                    condition: ({ type, videoPlatform, multiSection, videoId }) => type === 'Content' && videoPlatform === 'Brightcove' && !multiSection && videoId === 'NEW',
                    required: true
                  },
                ],
              },
              {
                key: 'videoId',
                subkey: 'bc-playlist',
                label: 'Brightcove Playlist',
                placeholder: 'Select Playlist',
                type: 'select',
                options: bcPlaylists,
                optionLabel: 'name',
                optionValueKey: 'id',
                // required: true,
                conditions: [
                  {
                    condition: ({ type, videoPlatform, multiSection }) => type === 'Content' && videoPlatform === 'Brightcove' && !!multiSection,
                    required: true
                  }
                ],
                onUpdate: onBCPlaylistUpdate,
                disabled: disabledVideoFn
              },
              {
                key: 'brightcovePlaylistTitle',
                label: 'Brightcove Playlist Title',
                placeholder: 'Enter Playlist Title',
                type: 'text',
                conditions: [
                  {
                    condition: ({ type, videoPlatform, multiSection, videoId }) => type === 'Content' && videoPlatform === 'Brightcove' && !!multiSection && videoId === 'NEW',
                    required: true
                  },
                ],
              },
              {
                key: 'brightcovePlaylistVideos',
                label: 'Brightcove Playlist Videos',
                placeholder: 'Select Playlist Videos',
                buttonLabel: 'Add Video',
                type: 'select-builder',
                options: bcVideos,
                optionLabel: 'name',
                optionValueKey: 'id',
                conditions: [
                  {
                    condition: ({ type, videoPlatform, multiSection, videoId }) => type === 'Content' && videoPlatform === 'Brightcove' && !!multiSection && videoId === 'NEW',
                    required: true
                  },
                ],
                onUpdate: onNewPlaylistUpdate
              },
              {
                key: 'videoId',
                subkey: 'ticktbox',
                label: 'ticktBox Video',
                placeholder: 'Select Video',
                type: 'select',
                options: tbVideos,
                optionLabel: 'videoTitle',
                optionValueKey: 'videoId',
                // required: true,
                conditions: [
                  {
                    condition: ({ type, videoPlatform }) => type === 'Content' && videoPlatform === 'ticktBox',
                    required: true
                  }
                ],
                onUpdate: onTBVideoUpdate,
                disabled: disabledVideoFn
              },
              {
                key: 'ticktboxVideoTitle',
                label: 'ticktBox Video Title',
                placeholder: 'Enter Video Title',
                type: 'text',
                conditions: [
                  {
                    condition: ({ type, videoPlatform, videoId }) => type === 'Content' && videoPlatform === 'ticktBox' && videoId === 'NEW',
                    required: true
                  },
                ],
              },
              {
                key: 'internalVideoName',
                label: 'Internal Video Name',
                placeholder: 'Enter Internal Name',
                sublabel: videoNameLabel,
                type: 'text-builder',
                staticLength: playlistLength || 1,
                conditions: [
                  {
                    condition: ({ type }) => type === 'Content',
                  }
                ],
                // options: bcVideos,
                // onUpdate: onNewPlaylistUpdate,
                disabled: disabledInternalNameFn
              },
              {
                key: 'playerId',
                label: 'Player ID',
                type: 'text',
                regexp: /^\w{9}$/,
                // required: true,
                conditions: [
                  {
                    condition: ({ type, videoPlatform }) => type === 'Content' && videoPlatform === 'Brightcove',
                    required: true
                  }
                ],
                disabled: true,
                defaultValue: 'VBdQt9xaK'
              },
              {
                key: 'autoStop',
                label: 'Automatic Stop?',
                type: 'boolean',
                conditions: [
                  {
                    condition: ({ type, multiSection }) => type === 'Content' && multiSection !== true
                  }
                ],
                defaultValue: false,
                disabled: disabledVideoFn
              },
              {
                key: 'autoStopTime',
                label: 'Automatic Stop Time',
                type: 'hms-time',
                conditions: [
                  {
                    condition: ({ type, autoStop }) => type === 'Content' && autoStop === true,
                    required: true
                  }
                ],
                disabled: disabledVideoFn
              },
              // {
              //   key: 'autoStop',
              //   label: 'Automatic Stop?',
              //   type: 'boolean',
              //   defaultValue: false,
              //   disabled: ({ id, videoLength, started }) => allowFullEdit ? (!!id && !!started) : true
              // },
              // {
              //   key: 'autoStopTime',
              //   label: 'Automatic Stop Time',
              //   type: 'hms-time',
              //   conditions: [
              //     {
              //       key: 'autoStop',
              //       value: true,
              //       required: true
              //     }
              //   ],
              //   disabled: ({ id, videoLength, started }) => allowFullEdit ? (!!id && !!started) : true
              // },
            ],
            [
              {
                key: 'sessions',
                label: 'Sessions',
                type: 'select',
                placeholder: 'Select Sessions',
                options: projectSessions,
                disabledOptions: disabledSessionsFn,
                optionLabel: 'sessionName',
                optionValueKey: 'id',
                fullObjects: true,
                multiple: true,
                // required: true,
                defaultValue: [],
                disabled: disabledFn
              },
            ],
            // {
            //   key: 'videoMarkers',
            //   label: 'Video Markers',
            //   type: 'marker-builder',
            //   defaultValue: []
            // },
          ]
        },
        {
          key: 'accessTab',
          label: 'Client Access',
          fields: [
            {
              key: 'clientMagicLink',
              label: 'Allow Client Shared Link Access',
              type: 'boolean',
              defaultValue: false
            },
            {
              key: 'magicLinkUrl',
              label: ' ',
              type: 'display',
              endComponent: clipboardEndComponent,
              conditions: [
                {
                  condition: ({ clientMagicLink, magicLinkUrl }) => !!(clientMagicLink && magicLinkUrl)
                }
              ]
            },
            {
              key: 'requirePasscode',
              label: 'Require Passcode',
              type: 'boolean',
              defaultValue: false,
              conditions: [
                {
                  condition: ({ clientMagicLink }) => !!clientMagicLink
                }
              ]
            },
            {
              key: 'magicLinkPasscode',
              label: 'Magic Link Passcode',
              type: 'display',
              conditions: [
                {
                  condition: ({ requirePasscode, magicLinkPasscode }) => !!(requirePasscode && magicLinkPasscode)
                }
              ]
            },
            // {
            //   key: 'requireApproval',
            //   label: 'Enable Waiting Room',
            //   type: 'boolean',
            //   defaultValue: false,
            //   conditions: [
            //     {
            //       condition: ({ clientMagicLink }) => !!clientMagicLink
            //     }
            //   ]
            // },
            {
              key: 'requireEmailValidation',
              label: 'Require Email Validation',
              type: 'boolean',
              defaultValue: false,
              conditions: [
                {
                  condition: ({ clientMagicLink }) => !!clientMagicLink
                }
              ]
            }
          ]
        },
        {
          key: 'segmentsTab',
          label: 'Segments',
          disabled: !allowFullEdit,
          fields: [
            {
              key: 'audienceSegments',
              label: 'Audience Segments',
              type: 'question-builder',
              keyLabel: '',
              showTerminate: showTerminate,
              defaultValue: [
                {
                  questionKey: 'Gender',
                  question: 'What is your identifying gender?',
                  multiple: false,
                  hasCrosstab: false,
                  crosstabKeys: [],
                  freeNumeric: false,
                  answers: [
                    {
                      answer: 'Male',
                      goal: 50
                    },
                    {
                      answer: 'Female',
                      goal: 50
                    }
                  ],
                },
                {
                  questionKey: 'Age',
                  question: 'What is your current age?',
                  multiple: false,
                  hasCrosstab: true,
                  crosstabKeys: ['Gender'],
                  freeNumeric: true,
                  answers: [
                    {
                      answer: '16-24',
                      goal: 25
                    },
                    {
                      answer: '25-34',
                      goal: 25
                    },
                    {
                      answer: '35-44',
                      goal: 15
                    },
                    {
                      answer: '45-54',
                      goal: 15
                    },
                    {
                      answer: '55-64',
                      goal: 10
                    },
                    {
                      answer: '65-99',
                      goal: 10
                    }
                  ],
                },
                {
                  questionKey: 'Ethnicity',
                  question: 'Which best represents your ethnic or racial background?',
                  multiple: false,
                  hasCrosstab: false,
                  crosstabKeys: [],
                  freeNumeric: false,
                  answers: [
                    {
                      answer: 'African-American or Black',
                      goal: 20
                    },
                    {
                      answer: 'Asian or Pacific Islander',
                      goal: 20
                    },
                    {
                      answer: 'Caucasian or White',
                      goal: 20
                    },
                    {
                      answer: 'Hispanic or Latino',
                      goal: 20
                    },
                    {
                      answer: 'Native American',
                      goal: 20
                    },
                    {
                      answer: 'Other',
                      goal: 0
                    }
                  ],
                },
                {
                  questionKey: 'Time Zone',
                  question: 'What time zone do you currently live in?',
                  multiple: false,
                  hasCrosstab: false,
                  crosstabKeys: [],
                  freeNumeric: false,
                  answers: [
                    {
                      answer: 'Pacific Time',
                      goal: 25
                    },
                    {
                      answer: 'Mountain Time',
                      goal: 25
                    },
                    {
                      answer: 'Central Time',
                      goal: 25
                    },
                    {
                      answer: 'Eastern Time',
                      goal: 25
                    },
                    {
                      answer: 'I am not in the United States',
                      goal: 0,
                      terminate: true
                    }
                  ],
                },
              ],
            },
            {
              key: 'virtualSegments',
              label: 'Virtual Segments',
              type: 'chart-segment-builder',
              options: audienceSegments,
              disabled: disabledFn,
              showGoals: false,
              virtualSegments: [
                {
                  questionKey: 'Session ID',
                  dataKey: 'sessionId',
                  condition: 'split'
                },
                {
                  questionKey: 'Tune Out',
                  dataKey: 'button',
                  dataValue: 'R',
                  condition: '==='
                },
                {
                  questionKey: 'No Tune Out',
                  dataKey: 'button',
                  dataValue: 'R',
                  condition: '!=='
                },
                {
                  questionKey: 'Caucasian',
                  dataKey: 'Ethnicity',
                  dataValue: 'Caucasian/White',
                  condition: '==='
                },
                {
                  questionKey: 'Non Caucasian',
                  dataKey: 'Ethnicity',
                  dataValue: 'Caucasian/White',
                  condition: '!=='
                },
                {
                  questionKey: 'African-American',
                  dataKey: 'Ethnicity',
                  dataValue: 'African-American or Black',
                  condition: '==='
                },
                {
                  questionKey: 'Non African-American',
                  dataKey: 'Ethnicity',
                  dataValue: 'African-American or Black',
                  condition: '!=='
                },
                {
                  questionKey: 'AA & Hisp',
                  dataKey: 'Ethnicity',
                  dataValue: ['African-American or Black', 'Hispanic or Latino'],
                  condition: '==='
                },
                {
                  questionKey: 'Asian',
                  dataKey: 'Ethnicity',
                  dataValue: 'Asian or Pacific Islander',
                  condition: '==='
                },
                {
                  questionKey: 'Scored >= 850',
                  dataKey: 'score',
                  dataValue: 850,
                  condition: '>='
                },
                {
                  questionKey: 'Scored <= 150',
                  dataKey: 'score',
                  dataValue: 150,
                  condition: '<='
                }
              ],
              keyLabel: ''
            },
          ]
        },
        {
          key: 'dialTab',
          label: 'Audience Feedback',
          disabled: disabledFGFn,
          fields: [
            {
              key: 'isDialProject',
              label: 'Real-Time Audience Feedback',
              type: 'boolean',
              defaultValue: false
            },
            {
              key: 'dialMode',
              label: 'Activity Type',
              type: 'select',
              placeholder: 'Select Activity Type',
              options: [
                { code: 'score', label: "Dial" },
                { code: 'symbol', label: "Emoji" },
                { code: 'buttonOnly', label: "Buttons Only"}
              ],
              optionLabel: 'label',
              optionValueKey: 'code',
              multiple: false,
              onUpdate: onDialModeChanged,
              conditions: [
                {
                  condition: ({ isDialProject }) => isDialProject === true,
                  required: true
                }
              ],
              defaultValue: 'score'
            },
            {
              key: 'symbolOptions',
              label: 'Emoji Options',
              type: 'emoji-builder',
              conditions: [
                {
                  condition: ({ isDialProject, dialMode }) => isDialProject === true && dialMode === 'symbol',
                  required: true
                }
              ],
              disabled: disabledFn,
              defaultValue: defaultSymbolsList,
              required: true
            },
            {
              key: 'scoreBase',
              label: 'Max Score',
              type: 'number',
              minValue: 10,
              maxValue: 1000,
              conditions: [
                {
                  condition: ({ isDialProject, dialMode }) => isDialProject === true && dialMode === 'score',
                  required: true
                }
              ],
              defaultValue: 100
            }
          ]
        },
      ]}
    // fields={[
    // ]}
    />
  );
})

export default ProjectModal;
