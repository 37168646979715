const cloneDeep = require('lodash/cloneDeep');
const isEqual = require('lodash/isEqual')

export const difference = (obj1, obj2, excluded = []) => {
  const keys = [...new Set([...Object.keys(obj1), ...Object.keys(obj2)])]
  const diff = keys.filter(k => !excluded.includes(k)).reduce((result, key) => {
    if (obj1.hasOwnProperty(key) && !obj2.hasOwnProperty(key)) {
      result[key] = cloneDeep(obj1[key])
    } else if (!obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      result[key] = cloneDeep(obj2[key])
    } else if (!isEqual(obj1[key], obj2[key])) {
      result[key] = cloneDeep(obj2[key])
    }
    return result;
  }, {});

  return diff;
}

export const toJSON = (stringObj) => {
  let obj = stringObj
  try {
    obj = JSON.parse(stringObj)
  } catch (e) {
    console.log('ERROR PARSING JSON', stringObj)
  }
  return obj
}