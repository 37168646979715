import React, { useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Backdrop, Modal, Fade, Paper, Button, Typography, Badge, CircularProgress, List, ListItem, ListItemText, ListItemIcon, Tooltip, TextField } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { updateGuestNotePromise } from '../services/guestsService';

const PREFIX = 'EditNotesModal';

const classes = {
    root: `${PREFIX}-root`,
    modal: `${PREFIX}-modal`,
    paper: `${PREFIX}-paper`,
    header: `${PREFIX}-header`,
    body: `${PREFIX}-body`,
    headerText: `${PREFIX}-headerText`,
    footer: `${PREFIX}-footer`,
    button: `${PREFIX}-button`,
    deleteIconButton: `${PREFIX}-deleteIconButton`,
    customBadge: `${PREFIX}-customBadge`,
    dropzone: `${PREFIX}-dropzone`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Modal)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '30px',
    paddingBottom: '30px',
    width: '100vw',
    justifyContent: 'center',
    [`& .${classes.root}`]: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0.5rem'
    },
    [`& .${classes.paper}`]: {
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
        width: '40%',
        minWidth: '300px',
        padding: '15px 0 0 0',
        maxHeight: '98%'
    },
    [`& .${classes.header}`]: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0 15px 0 15px',
        marginBottom: '6px'
    },
    [`& .${classes.body}`]: {
        padding: '0 15px 0 15px',
        overflowY: 'auto',
        marginTop: '1rem',
    },
    [`& .${classes.headerText}`]: {
        margin: 'auto 0 auto 0',
        whiteSpace: "nowrap"
    },
    [`& .${classes.footer}`]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '0 0 15px 0',
        marginTop: '2rem'
    },
    [`& .${classes.button}`]: {
        margin: '3px'
    }
});


const EditNotesModal = ({ notesEditOpen, setNotesEditOpen }) => {
    const { oktaAuth } = useOktaAuth();
    const [notes, setNotes] = useState('');


    const changeNotes = useCallback((e) => {
        setNotes(e.target.value)
    }, [])

    const closeModal = useCallback(() => {
        setNotesEditOpen(undefined)
        setNotes('')
    }, []);

    const submitNotes = useCallback(() => {
        const token = oktaAuth.getAccessToken();
        let data = notesEditOpen?.notes ? notes + '\n' + notesEditOpen.notes : notes;
        console.log('data to submit:', data);
        try {
            updateGuestNotePromise(notesEditOpen.id, data, notesEditOpen.sessionId, token);
            setNotes('')
        } catch (error) {
            if (error.message === 'Unauthorized') {
                oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/login` })
            }
            setNotes('')
        }
        setNotesEditOpen(undefined);
    }, [notesEditOpen, notes])

    return (
        <Root
            id="edit-notes-modal"
            className={classes.modal}
            open={notesEditOpen}
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 250,
                    sx: { zIndex: -1 }
                }
            }}
        >
            <Fade in={!!notesEditOpen}>
                <Paper className={classes.paper}>
                    <div className={classes.header}>
                        <Typography variant="h5" className={classes.headerText}>Append to Notes</Typography>
                    </div>
                    <div className={classes.body}>
                        <form>
                            <div className={classes.text}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label={'Append Notes'}
                                    type="text"
                                    fullWidth
                                    multiline={true}
                                    value={notes}
                                    rows={3}
                                    onChange={changeNotes}
                                />
                            </div>
                            <div className={classes.footer}>
                                <Button className={classes.button} variant="contained" color="primary" onClick={submitNotes}>Submit</Button>
                                <Button className={classes.button} variant="contained" onClick={closeModal}>Close</Button>
                            </div>
                        </form>
                    </div>
                </Paper>
            </Fade>
        </Root>
    );
}

export default (EditNotesModal)