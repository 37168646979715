import React, { useState, useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Zoom } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PublishIcon from '@mui/icons-material/Publish';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import CloseIcon from '@mui/icons-material/Close';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Files from 'react-butterfiles'

const PREFIX = 'GuestSpeedDial';

const classes = {
  addFab: `${PREFIX}-addFab`,
  actionClosed: `${PREFIX}-actionClosed`
};

const Root = styled('div')({
  [`& .${classes.addFab}`]: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    zIndex: '10',
    padding: '1rem',
    boxSizing: 'content-box'
  },
  [`& .${classes.actionClosed}`]: {
    height: 0
  }
});

const GuestSpeedDial = React.memo(({ show, allowUpload, onClickAdd, onUploadCSV, onError, test }) => {

  const [open, setOpen] = useState(false);
  const [mockUpload, setUploadType] = useState(false);
  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  return (
    <Root>
      <div className={`${classes.addFab} mui-fixed`}>
        <Zoom
          key={'add-session-fab'}
          in={show}
          timeout={transitionDuration}
          // style={{
          //   transitionDelay: `${transitionDuration.exit}ms`,
          // }}
          unmountOnExit
        >
          <div>
            <Files
              multiple={false} maxSize="2mb" multipleMaxSize="10mb" accept={['.csv', 'text/csv', 'text/x-csv', 'application/csv', 'application/x-csv', 'application/vnd.ms-excel', '']}
              onSuccess={(files) => {
                onUploadCSV(files, mockUpload);
              }}
              onError={onError}
            >
              {({ browseFiles }) => (
                <SpeedDial
                  ariaLabel="Guest Actions"
                  // className={classes.speedDial}
                  // hidden={hidden}
                  icon={<SpeedDialIcon />}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  open={open}
                  direction={'up'}
                  classes={{ actionsClosed: classes.actionClosed }}
                >
                  <SpeedDialAction
                    key='addguestaction'
                    icon={<PersonAddIcon />}
                    tooltipTitle="Add Guest"
                    onClick={onClickAdd}
                  />
                  {/* <Files
              multiple={false} maxSize="2mb" multipleMaxSize="10mb" accept={["application/pdf", "image/jpg", "image/jpeg"]}
              onSuccess={files => alert({ files })}
              onError={errors => alert({ errors })}
                >
                  {({ browseFiles }) => ( */}
                  {allowUpload && <SpeedDialAction
                    key='uploadcsvaction'
                    icon={<PublishIcon />}
                    tooltipTitle="Upload CSV"
                    onClick={() => {
                      setUploadType(false);
                      browseFiles();
                    }}
                  />}
                  {test && allowUpload && <SpeedDialAction
                    key='uploadmockcsvaction'
                    icon={<CloudUploadIcon />}
                    tooltipTitle="Upload Mock CSV"
                    onClick={() => {
                      setUploadType(true);
                      browseFiles();
                    }}
                  />}
                  {/* //   )}
            // </Files> */}
                </SpeedDial>
              )}
            </Files>
          </div>
        </Zoom>
      </div>
    </Root>
  );
})

export default (GuestSpeedDial)