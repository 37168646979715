import React, { useState, useRef, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import YouTube from 'react-youtube';
import { useEventListener } from '../hooks/hooks'
// import { Card, Button, CircularProgress } from '@mui/material';
import { IconButton } from '@mui/material';
// import * as workerTimers from 'worker-timers'
import VolumeOffIcon from '@mui/icons-material/VolumeOffOutlined';
import VolumeUpIcon from '@mui/icons-material/VolumeUpOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

const PREFIX = 'YoutubeVideoPlayer';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  continue: `${PREFIX}-continue`,
  continueBack: `${PREFIX}-continueBack`,
  margin: `${PREFIX}-margin`,
  resume: `${PREFIX}-resume`,
  youtube: `${PREFIX}-youtube`,
  player: `${PREFIX}-player`,
  playButton: `${PREFIX}-playButton`,
  muteButton: `${PREFIX}-muteButton`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  [`& .${classes.card}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 2rem 2rem'
  },
  [`& .${classes.continue}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 1rem 2rem',
    zIndex: 1002,
    width: '35%',
    minWidth: '160px',
    maxWidth: '260px'
  },
  [`& .${classes.continueBack}`]: {
    zIndex: 1001,
    position: 'absolute',
    height: '100%',
    width: '100%',
    backdropFilter: 'blur(12px)'
  },
  [`& .${classes.margin}`]: {
    margin: '1rem'
  },
  [`& .${classes.resume}`]: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    // backgroundColor: '#151515'
  },
  [`& .${classes.youtube}`]: {
    maxWidth: props => props.aspectRatio ? `${1 / props.aspectRatio * 100}vh` : 0,
    maxHeight: props => props.aspectRatio ? `${props.aspectRatio * 100}vw` : 0,
    width: props => props.aspectRatio ? '100%' : 0,
    height: props => props.aspectRatio ? '100%' : 0,
    flex: 1
  },
  [`& .${classes.player}`]: {
    width: '100%',
    height: '100%',
    pointerEvents: 'none'
  },
  [`& .${classes.playButton}`]: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    color: 'white',
    zIndex: 1004
  },
  [`& .${classes.muteButton}`]: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    color: 'white',
    zIndex: 1200
  },
});

// document.pictureInPictureEnabled = false

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

const youtubeOpts = {
  playerVars: {
    autoplay: 0,
    autohide: 1,
    mute: 1,
    showinfo: 0,
    modestbranding: 1,
    loop: 0,
    fs: 0,
    cc_load_policy: 0,
    iv_load_policy: 3,
    controls: 0
  },
};

const getVideoAspectRatio = async (videoId) => {
  const res = await fetch(`https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`)
  try {
    const { height, width } = await res.json()
    return height / width
  } catch (err) {
    console.log(err)
    return null
  }
}

const YoutubeVideoPlayer = React.memo(({ videoRef, style, fluid, fill, fullscreen, videoId, startVideo, alreadyStarted, onStarted, onMetadataLoaded, onVideoLoaded, onPlay, onVisiblityChange, onFinished, onVideoError, isLive, liveStreamStatus, sessionComplete }) => {
  const [aspectRatio, setAspectRatio] = useState()
  // const [resuming, setResuming] = useState(alreadyStarted)
  const [muted, setMuted] = useState(true)
  const [playing, setPlaying] = useState(false)



  const visibilityChangeFn = () => { }

  const playerRef = useRef()
  const iframeRef = useRef()
  // const fadeRef = useRef()
  // const volumeRef = useRef(100)

  useEffect(() => {
    if (startVideo && playerRef.current) playerRef.current.playVideo()
  }, [startVideo])

  useEffect(() => {
    if ((document[hidden] || !document.hasFocus()) && onVisiblityChange) onVisiblityChange()
  }, [onVisiblityChange])

  useEffect(() => {
    console.log('VIDEO - IS SESSION COMPLETE?', sessionComplete)
    if (sessionComplete && playerRef.current) playerRef.current.stopVideo()
  }, [sessionComplete])

  // if (onVisiblityChange) {
  useEventListener('blur', onVisiblityChange || visibilityChangeFn, true);
  useEventListener('focus', onVisiblityChange || visibilityChangeFn, true);
  useEventListener(visibilityChange, onVisiblityChange || visibilityChangeFn, true);
  // }

  const afterLoaded = useCallback((event) => {
    playerRef.current = event.target
    console.log(playerRef.current)
    if (videoRef) videoRef.current = event.target //to get time data
    iframeRef.current = playerRef.current.getIframe()

    getVideoAspectRatio(videoId).then((ratio) => {
      setAspectRatio(ratio)
      if (onMetadataLoaded) onMetadataLoaded(ratio)
    })

    // playerRef.current.tech_.el_.disablePictureInPicture = true
    // playerRef.current.tech_.el_.controls = false
    // playerRef.current.tech_.el_.blur();
    // playerRef.current.tech_.el_.addEventListener('focus', (e) => {
    //   e.currentTarget.blur()
    //   console.log('video focus')
    // })

    // const iframe = playerRef.current.getIframe()
    // console.log('youtube iframe...', iframe, iframe.document)

    // playerRef.current.getIframe().blur();
    // playerRef.current.getIframe().addEventListener('focus', (e) => {
    //   e.currentTarget.blur()
    //   console.log('youtube iframe focus')
    // })

    // playerRef.current.controls(false);
    // if (fullscreen) playerRef.current.enterFullWindow();
    // playerRef.current.autoplay(false)
    // playerRef.current.muted(false)

    // GUEST PLAYER
    // playerRef.current.playVideo()

    // ADMIN VIEWER
    if (!sessionComplete) {
      if (isLive) {
        liveStreamStatus(videoId).then((status) => {
          console.log('live event copmleted?', !!status.actualEndTime)
          if (!status.actualEndTime) {
            playerRef.current.playVideo()
          }
        })
      }
      else if (alreadyStarted) {
        playerRef.current.playVideo()
      }
    }

    playerRef.current.addEventListener('onStateChange', (event) => {
      switch (event.data) {
        case 0:
          console.log('end event')
          if (onFinished) onFinished()
          break;
        case 1:
          console.log('play event')
          // playerRef.current.unMute()
          if (onStarted) onStarted()
          break;
        case -1:
          console.log('unstarted...')
        case 2:
          console.log('pause event')
          // if (!playerRef.current.ended()) 
          // if (!sessionComplete) playerRef.current.playVideo()
          break;
        default:
          console.log('state change event', event)
          break;
      }
    })

    playerRef.current.addEventListener('onError', (event) => {
      console.log('error event', event)
      if (onVideoError) onVideoError()
    })


    // playerRef.current.on('seeked', () => {
    //   console.log('seeked event')
    // })

    // playerRef.current.on('seeking', () => {
    //   console.log('seeking event')
    // })

    // playerRef.current.on('loadedmetadata', () => {
    //   console.log('loadedmetadata event')
    //   if (onMetadataLoaded) onMetadataLoaded()
    //   // console.log('aspect ratio: ', playerRef.current.tech_.el_.videoHeight / playerRef.current.tech_.el_.videoWidth)
    // })

    // playerRef.current.on('loadeddata', () => {
    //   console.log('loadeddata event')
    //   if (onVideoLoaded) onVideoLoaded()
    //   setVideoLoaded(true)
    // })

    // playerRef.current.on('durationchange', (e) => {
    //   console.log('durationchange event', e)
    // })

    // playerRef.current.on('waiting', () => {
    //   console.log('waiting event')
    // })

  }, [videoId, sessionComplete, isLive, liveStreamStatus, videoRef, onFinished, onMetadataLoaded, onStarted, onVideoError, alreadyStarted])

  const toggleMute = useCallback(() => {
    if (playerRef.current) {
      if (muted) {
        playerRef.current.unMute()
      } else {
        playerRef.current.mute()
      }
      setMuted(prev => !prev)
    }
  }, [muted])

  const togglePlay = useCallback((event) => {
    event.currentTarget.blur()
    if (playerRef.current) {
      if (playing) {
        // allowPause.current = true
        // playerRef.current.currentTime(playerRef.current.duration())
        playerRef.current.pauseVideo()
      } else {
        playerRef.current.playVideo()
      }
      setPlaying(prev => !prev)
    }
  }, [playing])

  const toggleFullScreen = useCallback((event) => {
    event.currentTarget.blur()
    if (iframeRef.current) {
      const requestFullScreen = iframeRef.current.requestFullScreen || iframeRef.current.mozRequestFullScreen || iframeRef.current.webkitRequestFullScreen;
      if (requestFullScreen) {
        requestFullScreen.bind(iframeRef.current)();
      }
    }
  }, [])

  return (
    (<Root>
      {/* {resuming && <div className={classes.resume}>
        <div className={classes.continueBack}></div>
        <Card className={classes.continue} elevation={12}>
          {videoLoaded ? <>
            Please click below to {videoTime && unloadTime ? 'resume' : 'join'} session
            <Button className={classes.margin} disabled={!videoLoaded} color='primary' variant='contained' onClick={handleResume}>{videoTime && unloadTime ? 'Resume' : 'Join'}</Button>
          </> : <>
              Please wait...
            <CircularProgress className={classes.margin} />
            </>}
        </Card>
      </div>} */}
      <div style={{ position: 'relative' }}>
        <YouTube className={classes.player} containerClassName={classes.youtube} opts={youtubeOpts} videoId={videoId} onReady={afterLoaded} />
        {(!isLive && !startVideo && !alreadyStarted) ?
          <IconButton className={classes.playButton} color="inherit" onClick={togglePlay}>
            {playing ? <StopIcon /> : <PlayArrowIcon />}
          </IconButton> : <IconButton className={classes.playButton} color="inherit" onClick={toggleFullScreen}>
            {/* {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />} */}
            <FullscreenIcon />
          </IconButton>
        }
        <IconButton className={classes.muteButton} color="inherit" onClick={toggleMute}>
          {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton>
      </div>
    </Root>)
  );
})

export default YoutubeVideoPlayer;
